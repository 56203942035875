.main {
  display: flex;
  flex-direction: column;
  width: 392px;
}

.signature {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 10px;
}

.signatureNonNumbered {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 10px;
  gap: 5px;
}

.nonSignature {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 10px;
}

.nonSignatureNonNumbered {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 10px;
  gap: 5px;
}

.titleSign {
  font-size: 11px;
  line-height: 20px;
  letter-spacing: 2px;
  color: #fffff2;
}

.titleOther {
  font-size: 11px;
  line-height: 20px;
  letter-spacing: 2px;
  color: #fffff2;
  opacity: 0.5;
  margin-top: 10px;
}

@media only screen and (max-width: 768px) {
  .main {
    width: 335px;
  }
}
