.main {
  display: flex;
  flex-direction: column;

  gap: 35px;

  position: relative;
}

.top {
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 10px;

  padding-top: 20px;
}

.pollText {
  color: var(--3---pastel-brown, #928e8c);
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 1.21px;
}

.pollTitle {
  font-size: 19px;
  font-weight: 450;
  line-height: 120%;

  padding: 0 15px;
}

.commentInfoDiv {
  padding: 0 15px;
}

.interactionBarDiv {
  position: sticky;
  bottom: 0;
  left: 0;

  width: 100%;

  z-index: 3;
}
