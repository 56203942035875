.main {
  width: 100%;

  position: relative;
}

.barWrapper {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  gap: 3px;

  padding: 0 7px;

  position: absolute;
  top: 30px;
  left: 0;

  z-index: 2;
}

.bar {
  height: 2px;
  flex: 1 0 0;

  border-radius: 3px;
  background: var(--5---Cream-white, #fffff2);
}

.xmark {
  position: absolute;
  top: 54px;
  right: 30px;

  z-index: 2;
}

.storyMain {
  height: 100vh;
  width: 100%;

  display: flex;
  flex-direction: column;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  position: relative;
}

.opacityScreen {
  height: 100vh;
  width: 100%;

  opacity: 0.5;
  background: var(--0---carbon-brown, #181716);

  position: absolute;
  top: 0;
  left: 0;
}

.betaDiv {
  margin-top: 117px;

  align-self: center;
}

.name {
  margin-top: 15px;

  align-self: center;

  border-radius: 4px;
  background: var(--5---Cream-white, #fffff2);

  padding: 3px 5px;

  color: var(--0---carbon-brown, #181716);
  text-align: center;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 1.21px;
}

.gatingDiv {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.nudgeWrapper {
  width: 100%;

  padding: 0 5px;
}

.nudgeDiv {
  padding: 15px;

  border-radius: 15px;
  background: var(--1---Dark-brown, #2d2927);
}

.nudgeInnerDiv {
  border: 1px dashed rgba(255, 255, 242, 0.2);

  padding-top: 21px;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 27px;

  display: flex;
  flex-direction: column;

  gap: 26px;
}

.nudgeText {
  text-align: center;
  font-size: 14px;
  font-weight: 450;

  opacity: 0.8;
}

.nudgeButton {
  border: 0;

  padding: 11px 13px;

  border-radius: 9px;
  background: var(--5---Cream-white, #fffff2);

  color: var(--0---carbon-brown, #181716);
  text-align: center;
  font-size: 14px;
  font-weight: 700;

  width: fit-content;

  align-self: center;
}

.nudgeButtonSent {
  border: 0;

  padding: 11px 13px;

  border-radius: 9px;
  background: var(--3---pastel-brown, #928e8c);

  color: var(--2---Chalk-Brown, #55504f);
  text-align: center;
  font-size: 14px;
  font-weight: 700;

  width: fit-content;

  align-self: center;
}

.bodyWrapper {
  display: flex;
  flex-direction: column;

  gap: 40px;

  margin-top: 50px;
}

.statementDiv {
  display: flex;
  align-items: center;

  padding: 0 10px;

  gap: 33px;
}

.bodyDiv {
  padding: 5px 6px;

  border-radius: 5px;
  background: var(--5---Cream-white, #fffff2);

  width: fit-content;
}

.mappedBody {
  color: var(--0---carbon-brown, #181716);
  font-size: 16px;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.48px;

  padding: 5px 6px;
}

.span {
  border-radius: 5px;

  background: var(--5---Cream-white, #fffff2);

  -webkit-box-decoration-break: clone;
  -o-box-decoration-break: clone;
  box-decoration-break: clone;

  padding: 5px 6px;
}

.emojiDiv {
  display: flex;
  flex-direction: column;

  gap: 5px;

  border-radius: 22px;
  background: rgba(24, 23, 22, 0.5);

  padding: 5px;

  margin-left: auto;

  position: relative;
}

.emojiSelectorDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;

  gap: 10px;

  padding: 5px 10px;

  border-radius: 34px;

  background: var(--5---Cream-white, #fffff2);

  box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.7);

  position: absolute;
  right: 60px;
  top: -4px;

  overflow: hidden;
  width: 0px;
  opacity: 0;

  transition: width 0.5s ease;
}

.emojiTextDiv {
  border-radius: 100px;

  padding: 5px;

  width: 50px;
  height: 50px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.emojiTextDivSelected {
  border-radius: 100px;
  background: var(--4---Beige, #e6e1df);

  padding: 5px;

  width: 50px;
  height: 50px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.emojiSelectionText {
  color: #000;
  font-size: 30px;
  font-weight: 450;
  letter-spacing: -0.371px;
}

.selectedEmoji {
  color: #000;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  letter-spacing: -0.371px;
}

.feedbackDiv {
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  bottom: 90px;
  left: 0;

  width: 100%;
}

.bottom {
  position: absolute;
  bottom: 0;
  left: 0;

  width: 100%;
}
