.searchMain {
  width: 375px;
  position: absolute;
  right: 0;
  top: 27px;
}

.searchBar {
  width: 345px;
  height: 44px;
  border-radius: 7px;
  padding-left: 40px;
  background-color: #fffff2;
  font-size: 16px;
  border-width: 0;
}

.searchIcon {
  position: absolute;
  margin-top: 12px;
  margin-left: 0.6%;
}

.searchCancel {
  display: none;
}

.searchResultDivDesktop {
  color: #fffff2;
  background-color: #2d2927;
  padding: 20px 15px;
  border-radius: 5px;
  position: absolute;
  z-index: 10;
  width: 345px;
  overflow-y: scroll;
  max-height: 350px;
}

.searchResultDivDesktop h3 {
  font-size: 16px;
  margin-bottom: 15px;
  font-weight: 350;
}

.traitResultDivDesktop {
  display: flex;
  overflow-x: scroll;
  padding-bottom: 4px;
}

.traitResultDivDesktop::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 3px;
  height: 6px;
}

.traitResultDivDesktop::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #2d2927;
}

.searchResultDiv {
  display: none;
  color: #fffff2;
  background-color: #2d2927;
  padding: 20px 15px;
  border-radius: 5px;
  position: absolute;
  z-index: 10;
  width: 345px;
  overflow-y: scroll;
  max-height: 350px;
}

.searchResultDiv h3 {
  font-size: 16px;
  margin-bottom: 15px;
}

.personDiv {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  margin-left: 0 !important;
}

.imageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
}

.smallImage {
  border-radius: 50%;
}

.bioAndUsername {
  margin-left: 10px;
}

.bioAndUsername h5 {
  font-size: 16px;
  font-weight: 400;
}

.bioAndUsername p {
  font-size: 14px;
  color: grey;
}

.mobileSearchMain {
  width: 0;
}

.mobileSearchBar {
  display: none;
}

.reqButton {
  margin-left: auto;
  border-radius: 9px;
  background: var(--subtle-yellow, #ffffde);
  width: 79px;
  height: 40px;
  border: 0;

  color: var(--2-chalk-brown, #55504f);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  font-family: Circularstd-Book;
}

.sentButton {
  margin-left: auto;
  border-radius: 9px;
  background: var(--3-pastel-brown, #928e8c);
  width: 79px;
  height: 40px;
  border: 0;

  color: var(--1-dark-brown, #2d2927);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  font-family: Circularstd-Book;
}

@media only screen and (max-width: 768px) {
  .searchMain {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    margin-left: 0;
    width: auto;
    right: 11px;
    top: 15px;
  }
  .searchBar {
    display: none;
  }

  .searchIcon {
    position: relative;
    background-color: #fffff2;
    border-radius: 50%;
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 44px;
    margin-top: 0;
  }

  .searchResultDivDesktop {
    display: none;
  }
}
