.main {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 7px 6px;
  gap: 5px;

  border-radius: 8px;
  background: var(--error-red, #b82c35);

  min-width: 44px;
  min-height: 34px;
}

.number {
  color: var(--5---Cream-white, #fffff2);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.48px;
}
