.main {
  padding: 0 15px;
}

.title {
  background: var(--5---Cream-white, #fffff2);
  height: 45px;

  padding: 0 10px;

  color: var(--0---carbon-brown, #181716);
  font-size: 22px;
  font-weight: 450;
  letter-spacing: -0.22px;

  display: flex;
  align-items: center;
}

.wrapper {
  border: 1px solid var(--light-outline, #464542);

  padding: 20px;

  display: flex;
  flex-direction: column;

  gap: 15px;
}

.wrapperGhostbox {
  border: 1px solid var(--light-outline, #464542);

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 20px;
}

.label {
  font-size: 11px;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: 1.21px;
}

.elementDiv {
  display: flex;
  align-items: center;

  padding: 5px;

  gap: 10px;

  width: fit-content;

  border-radius: 5px;

  background: var(--2---Chalk-Brown, #55504f);
}

.ghostboxDiv {
  border-radius: 7px;
  border: 1px dashed rgba(255, 255, 242, 0.2);

  display: flex;
  align-items: center;

  padding: 15px 10px;

  gap: 10px;
}

.ghostboxText {
  color: var(--5---Cream-white, #fffff2);
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;

  opacity: 0.8;
}
