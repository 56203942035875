.main {
  color: #fffff2;
  margin-top: 56px;
  display: inline-block;
  padding: 0 10px;
}

.parent {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.archDiv {
  align-self: center;
  display: flex;
  flex-direction: column;
}

.archLine {
  width: 2px;
  height: 8px;
  background-color: #fffff2;
  opacity: 0.5;
  align-self: center;
}

.long {
  height: 1px;
  background-color: #fffff2;
  opacity: 0.5;
  align-self: center;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.elDiv {
  align-self: center;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 768px) {
  .main {
    padding: 0 10px;
  }
}
