.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  position: fixed;
  left: 50%;
  top: 50%;

  width: 100%;
  height: 100%;

  z-index: 1000000000000000;

  background: #190a034f;
  backdrop-filter: blur(5px);
}

.wrapper {
  display: flex;
  flex-direction: column;

  width: 97%;
  height: auto;

  color: #fffff2;

  overflow: auto;

  border-radius: 12px;

  background-color: #2d2927;

  position: relative;

  padding: 35px 15px;

  transform: translateY(300%);
  transition: transform 0.2s linear;
}

.images {
  display: flex;
  align-items: center;
  justify-content: center;
}

.smallImageContainer {
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.smallImage {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid black;
}

.svg {
  margin: 0 5px;
  width: 31px;
  height: 31px;
  background-color: #fffff2;
  border-radius: 50%;
  padding: 5px;
}

.title {
  color: var(--5-cream-white, #fffff2);
  text-align: center;
  font-size: 26px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  letter-spacing: -0.26px;
  margin-top: 20px;
}

.unlock {
  color: var(--5-cream-white, #fffff2);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  opacity: 0.8;
  margin-top: 5px;
}

.textArea {
  min-height: 130px;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #5c5c5c;
  border-radius: 4px;
  padding: 15px 18px;
  font-size: 16px;
  margin-top: 18px;
  width: 100%;

  color: var(--5-cream-white, #fffff2);
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  opacity: 0.5;
  outline: none !important;
}

.textAreaMessage {
  min-height: 50px;
  border-radius: 0px 7px 7px 7px;
  border: 1px solid var(--2---Chalk-Brown, #55504f);
  background: var(--2---Chalk-Brown, #55504f);
  padding: 15px 18px;
  font-size: 16px;
  margin-top: 18px;
  width: 100%;

  color: var(--5---Cream-white, #fffff2);
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  font-family: Circularstd-Book !important;
  outline: none !important;
}

.textArea::placeholder {
  color: var(--5-cream-white, #fffff2);
  font-size: 12px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  opacity: 0.5;
}

.counter {
  text-align: flex-end;
  margin-top: 7.5px;
  color: var(--5-cream-white, #fffff2);
  text-align: right;
  font-size: 10px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  opacity: 0.5;
}

.button {
  border-radius: 7px;
  background: var(--subtle-yellow, #ffffde);
  backdrop-filter: blur(5.436563491821289px);
  border: 0;
  height: 50px;

  color: var(--0-carbon-brown, #181716);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  margin-top: 35px;
}

.buttonPending {
  border-radius: 7px;
  background: #928e8c;
  backdrop-filter: blur(5.436563491821289px);
  border: 0;
  height: 50px;

  color: #55504f;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  margin-top: 35px;
}
