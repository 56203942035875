.background {
  width: 100vw;
  height: 100vh;

  background-color: transparent;

  position: relative;

  z-index: 1000000000;

  position: fixed;
}

.main {
  width: 85%;
  height: 100vh;

  margin-left: -100%;

  transition: margin 0.5s;

  background: var(--1---Dark-brown, #2d2927);

  display: flex;
  flex-direction: column;

  padding-top: 48px;
}

.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 18px;
}

.title {
  color: var(--3---pastel-brown, #928e8c);
  font-size: 16px;
  font-weight: 700;
  line-height: 140%;
}

.navRightDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: 8px;
}

.navNewText {
  color: var(--5---Cream-white, #fffff2);
  text-align: center;
  font-size: 8px;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 0.88px;
}

.wrapper {
  margin-top: 29px;

  display: flex;
  flex-direction: column;

  gap: 5px;

  padding: 0 13px;
}

.conversationDiv {
  padding: 10px;

  border-radius: 7px;

  position: relative;
}

.conversationTitle {
  color: var(--5---Cream-white, #fffff2);
  font-family: AkkuratStd;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}

.conversationSelectorDiv {
  position: absolute;
  top: 43px;
  right: 0;

  padding: 15px;

  display: flex;
  flex-direction: column;

  gap: 15px;

  border-radius: 10px;
  background: var(--2---Chalk-Brown, #55504f);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);

  z-index: 2;
}

.conversationSelectorOption {
  color: var(--5---Cream-white, #fffff2);
  font-size: 14px;
  font-weight: 500;
  line-height: 150%;
}
