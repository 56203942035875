.wrapperMain {
  width: 100%;
  height: 100%;

  position: relative;
}

.main {
  width: 100%;
  min-width: 211px;

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  /* overflow-x: hidden; */
}

.wrapper {
  width: 211px;
  min-width: 211px;

  border-radius: 20px;
  border: 1px solid var(--light-outline, #464542);

  padding-top: 22px;
  padding-bottom: 22px;

  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 12px;
}

.stickerDiv {
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);

  padding: 7px 10px;

  border-radius: 7px;
  background: var(--vibrant-blue, #2cb0ff);

  min-width: max-content; /* Ensures the div fits the text */
  white-space: nowrap;
}

.sticketText {
  text-align: center;
  font-size: 13px;
  font-weight: 700;
}

.title {
  text-align: center;
  font-size: 26px;
  font-weight: 300;
}

.dimensionalPlusText {
  font-family: "DM Sans";
  font-size: 14.783px;
  font-weight: 500;
  letter-spacing: -0.443px;

  margin-top: 2px;
}

.dimensionalPlus {
  background: linear-gradient(90deg, #8bdcff 0%, #6978ff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.iconDiv {
  margin-top: 35px;
}

.pricePerMessage {
  margin-top: 25px;

  max-width: 107px;

  color: var(--3---pastel-brown, #928e8c);
  text-align: center;
  font-size: 12px;
  font-weight: 450;

  height: 30px;
}

.btn {
  border: 0;
  border-radius: 9px;

  background: var(--5---Cream-white, #fffff2);

  padding: 11px 13px;

  color: var(--0---carbon-brown, #181716);
  font-size: 14px;
  font-weight: 700;

  margin-top: 25px;
}

.circlesWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 8px;

  margin-top: 10px;
}

.circle {
  width: 10px;
  height: 10px;

  border-radius: 50%;

  background-color: #fffff2;

  opacity: 0.35;
}
