.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  height: 100dvh;
  z-index: 1000000000000000;
  background-color: rgb(0 0 0 / 78%);
  overflow: hidden;
}

.wrapper {
  display: flex;
  flex-direction: column;

  gap: 40px;

  padding: 20px;

  border-radius: 25px;
  background: #fffff2;
}

.title {
  color: var(--1---Dark-brown, #2d2927);
  text-align: center;
  font-size: 26px;
  font-weight: 450;
  line-height: 100%;
  letter-spacing: -0.78px;

  align-self: center;
}

.body {
  color: var(--1---Dark-brown, #2d2927);
  text-align: center;
  font-size: 16px;
  font-weight: 450;
  line-height: 130%;

  align-self: center;
}

.btnWrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  gap: 10px;
}

.btnCancel {
  border: 0;
  border-radius: 9px;
  background: var(--2---Chalk-Brown, #55504f);

  padding: 11px 13px;

  color: var(--5---Cream-white, #fffff2);
  text-align: center;
  font-size: 14px;
  font-weight: 700;
}

.btnApprove {
  border: 0;
  border-radius: 9px;
  background: var(--error-red, #b82c35);

  padding: 11px 13px;

  color: var(--5---Cream-white, #fffff2);
  text-align: center;
  font-size: 14px;
  font-weight: 700;
}

.input {
  width: 200px;
  height: 40px;

  border-radius: 5px;
  border: 1px solid rgb(29 29 11 / 17%);

  background-color: #55504f;

  padding: 0 10px;

  color: var(--5---Cream-white, #fffff2);
  font-size: 12px;
  font-weight: 450;
}
