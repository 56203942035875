.scoreBarDivParent {
  width: 100%;
  display: flex;
  align-items: center;
}

.scoreBarDivParentNoneReport {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.scoreBarDivParentLarge {
  width: 100%;
  display: flex;
  align-items: center;
}

.scoreBarDivParentSnippet {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.scoreBar {
  display: flex;
  width: 196px;
  height: 6px;
}

.scoreWrapper {
  display: flex;
  padding-top: 6px;
  padding-bottom: 3px;
  position: relative;
}

.scoreWrapperSnippet {
  display: flex;
  padding-top: 6px;
  padding-bottom: 3px;
  position: relative;

  gap: 4px;

  flex-grow: 9;
}

.selected {
  border-radius: 2px;
  height: 6px;
  width: 36px;
  margin-right: 4px;
  background-color: #2d2927;
}

.selectedNonReport {
  border-radius: 2px;
  height: 6px;
  width: 36px;
  margin-right: 4px;
  background-color: #fffff2;
}

.selectedLarge {
  border-radius: 2px;
  height: 6px;
  width: 51px;
  margin-right: 4px;
  background-color: #2d2927;
}

.selectedSnippet {
  border-radius: 2px;
  height: 6px;
  background-color: #fffff2;

  flex-grow: 1;
  min-width: 45px;
  max-width: 45px;
}

.selectedSnippetPeer {
  border-radius: 2px;
  height: 6px;
  background: linear-gradient(90deg, #19896e 0.74%, #229d3d 100.74%), #fffff2;
  flex-grow: 1;
  min-width: 45px;
  max-width: 45px;
}

.unselected {
  border-radius: 2px;
  height: 6px;
  width: 36px;
  margin-right: 4px;
  background-color: #2d2927;
  opacity: 0.2;
}

.unselectedNonReport {
  border-radius: 2px;
  height: 6px;
  width: 36px;
  margin-right: 4px;
  background-color: #fffff2;
  opacity: 0.2;
}

.unselectedLarge {
  border-radius: 2px;
  height: 6px;
  width: 51px;
  margin-right: 4px;
  background-color: #2d2927;
  opacity: 0.2;
}

.unselectedSnippet {
  border-radius: 2px;
  height: 6px;
  background-color: #fffff2;
  flex-grow: 1;
  opacity: 0.2;
  min-width: 45px;
  max-width: 45px;
}

.scoreTextDiv {
  display: flex;
  align-items: center;
  margin-left: 28px;
}

.scoreTextDivLarge {
  display: flex;
  align-items: center;
  margin-left: 20px;
}

.scoreTextDivNonReport {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: 28px;
}

.scoreTextDivSnippet {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  margin-left: 8px;
  flex-grow: 1;
}

.iconDiv {
  display: flex;
  flex-direction: column;
  margin-left: 4px;
  position: relative;
  top: -8px;
}

.arrow {
  width: 9px;
  height: 5px;
}

.neutral {
  width: 9px;
  height: 1.714px;
  border-radius: 2.143px;
  background: #2d2927;
}

.neutralNonReport {
  width: 9px;
  height: 1.714px;
  border-radius: 2.143px;
  background: #fffff2;
}

.scoreText {
  color: #2d2927;
  text-align: right;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  backdrop-filter: blur(5.436563491821289px);
}

.scoreTextNonReport {
  color: var(--5---Cream-white, #fffff2);
  text-align: right;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  opacity: 0.5;
  backdrop-filter: blur(5.436563491821289px);
}

.caption {
  margin-top: 7px;
  font-weight: 450;
  font-size: 12px;
  line-height: 15px;
  color: #fffff2;
  mix-blend-mode: normal;
  opacity: 0.5;
}

.restrictIcon {
  position: absolute;
  width: 18px;
  height: 18px;
  left: 45%;
  top: 0;
}

.premiumIcon {
  position: absolute;
  /* width: 18px;
  height: 18px; */
  left: 40%;
  top: 0;
}

@media only screen and (max-width: 768px) {
  .scoreBarDivParent {
    padding-right: 4px;
  }
  .scoreTextDivNonReport {
    margin-left: 8px;
  }

  .scoreTextDivLarge {
    margin-left: 0;
  }

  .scoreBarDivParentLarge {
    justify-content: space-between;
  }

  .selected {
    width: 33px;
  }

  .selectedNonReport {
    width: 33px;
  }

  .unselected {
    width: 33px;
  }

  .unselectedNonReport {
    width: 33px;
  }
}
