.wrapper {
  overflow-y: auto;
  padding: 0 5px;
}

.notificationDiv {
  width: 100%;
  min-height: 82px;

  display: flex;
  align-items: center;

  gap: 15px;

  border-bottom: 1px solid var(--light-outline, #464542);

  padding-left: 15px;
  padding-right: 10px;
}

.primariIconDiv {
  width: 52px;
  height: 52px;

  border-radius: 50%;

  background-color: #55504f;

  display: flex;
  justify-content: center;
  align-items: center;
}

.rewardLevelDiv {
  width: 44px;
  height: 44px;

  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  background-image: linear-gradient(270deg, #bf7ae1 0%, #6962cf 100%);
}

.leftDiv {
  display: flex;
  flex-direction: column;

  height: 100%;
  /* min-height: 82px; */
}

.createdOn {
  color: var(--5---Cream-white, #fffff2);
  font-size: 10px;
  font-weight: 450;

  opacity: 0.35;
}

.body {
  font-size: 14px;
  font-weight: 450;

  flex-grow: 1;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}

.secondaryButton {
  border: 0;
  border-radius: 9px;

  background: var(--5---Cream-white, #fffff2);

  padding: 11px 13px;

  color: var(--0---carbon-brown, #181716);
  text-align: center;
  font-size: 14px;
  font-weight: 700;
}

.peerRatingButton {
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 9px;
  border: 0;

  width: 55px;
  height: 40px;

  padding-left: 5px;
  padding-top: 11px;
  padding-bottom: 11px;

  background: var(--5---Cream-white, #fffff2);
}
