.main {
  width: 100%;
  min-height: 125px;

  border-radius: 8px;

  background: var(--error-red, #b82c35);

  padding: 16px 15px 10px 15px;
}

.title {
  color: var(--5---Cream-white, #fffff2);
  font-size: 16px;
  font-weight: 500;
}

.subtitle {
  color: var(--5---Cream-white, #fffff2);
  font-size: 14px;
  font-weight: 450;

  opacity: 0.8;

  margin-top: 7px;
}

.buttonsDiv {
  margin-top: 10px;

  width: 100%;

  display: flex;
  justify-content: flex-end;

  gap: 5px;
}

.customizeButton {
  border-radius: 4px;
  border: 1px solid var(--5---Cream-white, #fffff2);

  padding: 5px 7px;

  background-color: transparent;

  color: var(--5---Cream-white, #fffff2);
  text-align: center;
  font-size: 14px;
  font-weight: 450;
}

.enableButton {
  padding: 5px 7px;

  border: 0;
  border-radius: 4px;

  background: var(--subtle-yellow, #ffffde);

  color: var(--1---Dark-brown, #2d2927);
  text-align: center;
  font-size: 14px;
  font-weight: 450;
}

/* settings */

.mainSettings {
  width: 100%;
  height: 140px;

  border-radius: 10px;

  background: var(--error-red, #b82c35);

  padding: 19px 19px 23px 19px;

  display: flex;
  flex-direction: column;
  align-items: center;

  margin-bottom: 6px;
}

.settingsInfo {
  color: var(--5---Cream-white, #fffff2);
  text-align: center;
  font-size: 14px;
  font-weight: 450;
}

.enableButtonSettings {
  padding: 11px 13px;

  border: 0;
  border-radius: 9px;

  background: var(--5---Cream-white, #fffff2);

  color: var(--0---carbon-brown, #181716);
  text-align: center;
  font-size: 14px;
  font-weight: 700;

  margin-top: 18px;
}

.activeRowWrapper {
  display: flex;
  flex-direction: column;

  gap: 18px;

  margin-top: 14px;
}

.activeRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.activeRowTextDiv {
  display: flex;
  flex-direction: column;

  gap: 2px;
}

.activeRowTitle {
  color: var(--5---Cream-white, #fffff2);
  font-size: 14px;
  font-weight: 700;
}

.activeRowDesc {
  color: var(--5---Cream-white, #fffff2);
  font-size: 14px;
  font-weight: 450;
}

.buttonDiv {
  width: 100%;

  display: flex;
  justify-content: flex-end;

  margin-top: 25px;
}

.saveButton {
  border: 0;
  border-radius: 4px;

  padding: 5px 7px;

  background: var(--subtle-yellow, #ffffde);

  color: var(--1---Dark-brown, #2d2927);
  font-size: 14px;
  font-weight: 450;

  min-width: 91px;
}
