.main {
  display: flex;
  flex-direction: column;

  padding: 0 20px;
  padding-top: 62px;
  padding-bottom: 44px;

  width: 100%;
  height: 100vh;
}

.imageDiv {
  aspect-ratio: 1 / 1;
  width: 100%;

  position: relative;
}

.title {
  margin-top: 40px;

  color: #fffff2;
  font-size: 26px;
  font-weight: 450;
}

.body {
  width: 315px;
  max-width: 315px;

  color: rgba(255, 255, 242, 0.8);
  font-size: 16px;
  font-weight: 400;

  margin-top: 20px;
}

.circleDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
}

.circleActive {
  width: 10px;
  height: 10px;
  background-color: #fffff2;
  border-radius: 50%;
  margin-right: 5px;
}

.circle {
  width: 10px;
  height: 10px;
  background-color: #fffff2;
  opacity: 0.35;
  border-radius: 50%;
  margin-right: 5px;
}

.button {
  border: 0;
  border-radius: 7px;

  background: var(--5---Cream-white, #fffff2);

  padding: 14px;

  color: var(--0---carbon-brown, #181716);
  font-size: 16px;
  font-weight: 450;
}

.bottom {
  margin-top: auto;

  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 55px;
}
