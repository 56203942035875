.main {
  margin-top: 38px;
  color: var(--5---Cream-white, #fffff2);
  padding: 0 10px;
}

.title {
  font-size: 20px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
}

.bottom {
  margin-top: 46px;
  margin-bottom: 50px;
}

.mapDiv {
  margin-top: 26px;
}

.personDiv {
  border: 1px solid #464542;
  border-bottom: 0;
  height: 74px;
  width: 100%;
  max-width: 550px;
  display: flex;
  align-items: center;
}

.numberDiv {
  height: 100%;
  width: 30px;
  border-right: 1px solid #464542;
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  color: #464542;
}

.imageDiv {
  margin-left: 10px;
}

.scoreNameDiv {
  margin-left: 9px;
}

.name {
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
}

/* archetype */
.statsParent {
  border: 1px solid #363634;
}

.statsHeader {
  background-color: #fffff2;

  width: 100%;
  height: 43.2px;

  display: flex;
  align-items: center;

  padding: 0 10px;

  color: var(--0---carbon-brown, #181716);
  font-size: 22px;
  font-weight: 450;
  letter-spacing: -0.22px;
}

.statsRow {
  display: flex;
  align-items: center;
  justify-content: space-between;

  border-bottom: 1px solid #363634;

  padding: 10px 10px 11px 8px;
}

.leftStats {
  display: flex;
  align-items: center;

  gap: 6px;
}

.iconDiv {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 28px;
  height: 28px;
}

.rowDesc {
  color: var(--5---Cream-white, #fffff2);
  font-size: 13px;
  font-weight: 500;
}

.statResult {
  color: var(--5---Cream-white, #fffff2);
  text-align: right;
  font-size: 13px;
  font-weight: 500;
}

.archWrapperTitle {
  font-size: 20px;
  font-weight: 450;

  margin-top: 36px;

  padding-left: 20px;
}

.archWrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  gap: 2px;

  margin-top: 16px;
}

.archPersonDiv {
  width: 120px;
  height: 153px;

  border-radius: 7px;
  border: 1px solid var(--light-outline, #464542);

  display: flex;
  flex-direction: column;
  align-items: center;

  padding-top: 14px;
  padding-bottom: 11px;

  overflow: hidden;
}

.archPersonName {
  text-align: center;
  font-size: 12px;
  font-weight: 450;

  margin-top: 14px;
}

.archPersonUsername {
  text-align: center;
  font-size: 12px;
  font-weight: 450;
  opacity: 0.4;
}

.privateTraitDiv {
  margin-top: 20px;
}
