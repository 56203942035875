.main {
  padding: 15px 18px;

  border-radius: 12px;
  border: 1px dashed var(--light-outline, #464542);

  width: 100%;
}

.mainGiven {
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 10px;

  border-radius: 12px;
  background: #2d2927;

  padding: 15px 18px;

  width: fit-content;

  align-self: center;
}

.title {
  font-size: 20px;
  font-weight: 450;
}

.improveText {
  font-size: 12px;
  font-weight: 450;

  margin-top: 4px;

  opacity: 0.5;
}

.buttonWrapper {
  margin-top: 20px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.emojiBox {
  border-radius: 7px;
  border: 1px solid var(--light-outline, #464542);

  padding: 9px 5px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.emojiBoxSelected {
  border-radius: 7px;
  border: 1px solid var(--light-outline, #fffff2);

  padding: 9px 5px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.emoji {
  color: #fff;
  text-align: center;
  font-size: 28px;
  font-weight: 450;
}

.desc {
  font-size: 12px;
  font-weight: 450;

  margin-top: 4px;
}

.input {
  margin-top: 20px;

  width: 100%;

  border-radius: 7px;
  border: 1px solid var(--light-outline, #464542);

  padding: 11px 10px;

  background: var(--0---carbon-brown, #181716);

  color: var(--5---Cream-white, #fffff2);
  font-size: 15px;
  font-weight: 450;
}

.submitBtnDiv {
  margin-top: 10px;

  display: flex;
  justify-content: flex-end;
}

.submitBtn {
  border: 0;
  border-radius: 9px;

  background: var(--5---Cream-white, #fffff2);

  padding: 11px 13px;

  color: var(--0---carbon-brown, #181716);
  font-size: 14px;
  font-weight: 700;
}

.yourFeedback {
  color: var(--3---pastel-brown, #928e8c);
  font-size: 14px;
  font-weight: 450;
}
