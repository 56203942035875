.parent {
  display: flex;
  align-items: center;
  width: 95%;
  height: 80px;
  margin-top: 5%;
  border: dashed #55504f 1px;
  border-radius: 7px;
  margin-bottom: 10px;
  padding-right: 5px;
}

.parentLight {
  display: flex;
  align-items: center;
  width: 95%;
  height: 80px;
  margin-top: 5%;
  border: dashed 1px rgba(255, 255, 242, 0.2);
  border-radius: 7px;
  margin-bottom: 10px;
  padding-right: 5px;
}

.image {
  margin: 15px 13px;
}

.imageLatest {
  margin: 15px 13px;

  width: 25px;
  height: 25px;

  display: flex;
  justify-content: center;
  align-items: center;

  opacity: 0.8;
}

.parent p {
  font-size: 16px;
  color: #fffff2;
}

.messagePremium {
  color: var(--5---Cream-white, #fffff2);
  font-size: 14px;
  font-weight: 450;
  opacity: 0.8;
}

.text {
  color: var(--5---Cream-white, #fffff2);
  font-size: 14px;
  font-weight: 450;
}

.button {
  margin-left: auto;
  margin-right: 10px;
  border-radius: 9px;
  background: var(--5-cream-white, #fffff2);
  padding: 10px;
  border: 0;

  color: var(--0-carbon-brown, #181716);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.regenButtonDiv {
  margin-left: auto;
  margin-right: 10px;
}

.traitMainDiv {
  width: 100%;
  height: 70px;

  display: flex;
  align-items: center;

  padding: 0px 10px;

  gap: 10px;

  border-radius: 7px;
  border: 1px dashed rgba(255, 255, 242, 0.2);
  backdrop-filter: blur(20px);
}

.traitIconDiv {
  width: 20px;
  height: 20px;

  opacity: 0.4;
}

.privateText {
  font-size: 14px;
  color: #fffff2;

  opacity: 0.4;
}

@media only screen and (max-width: 768px) {
  .parent {
    height: 60px;
  }

  .parent p {
    font-size: 14px;
    max-width: 200px;
  }
  .image {
    margin: 0 15px;
  }

  .button {
    margin-right: 2px;
  }
}
