.main {
  border-radius: 8px;
  background: linear-gradient(90deg, #19896e 0.74%, #229d3d 100.74%),
    linear-gradient(90deg, #38a289 0.74%, #00a023 100.74%);

  padding: 7px;
  gap: 4px;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
}

.mainSmall {
  border-radius: 14px;
  background: linear-gradient(90deg, #19896e 0.74%, #229d3d 100.74%),
    linear-gradient(90deg, #38a289 0.74%, #00a023 100.74%);

  padding: 3px 6px;
  gap: 4px;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
}

.mainVerySmall {
  border-radius: 14px;
  background: linear-gradient(90deg, #19896e 0.74%, #229d3d 100.74%),
    linear-gradient(90deg, #38a289 0.74%, #00a023 100.74%);

  padding: 2px 5px;
  gap: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
}

.iconDiv {
  width: 18px;
  height: 18px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.iconDivSmall {
  width: 12px;
  height: 12px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.text {
  color: var(--5---Cream-white, #fffff2);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.48px;
}
