.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  position: fixed;

  width: 100%;
  height: 100%;

  z-index: 10000000;

  color: var(--5---Cream-white, #fffff2);
}

.backgroundDiv {
  position: fixed;

  width: 100%;
  height: 100%;

  background: var(--3---pastel-brown, #928e8c);
  backdrop-filter: blur(2px);

  opacity: 0.6;
}

.postDiv {
  width: 100%;

  border-radius: 20px 20px 0px 0px;
  background: var(--1---Dark-brown, #2d2927);

  display: flex;
  flex-direction: column;

  gap: 18px;

  padding: 20px 15px;

  z-index: 100000000;

  transform: translateY(300%);
  transition: transform 0.5s linear;
}

.top {
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 5px;
}

.postToConnectionsDiv {
  font-size: 16px;
  font-weight: 450;
}

.input {
  background: var(--1---Dark-brown, #2d2927);

  border-radius: 7px;
  border: 1px solid var(--2---Chalk-Brown, #55504f);

  padding: 14px 10px;

  color: var(--5---Cream-white, #fffff2);
  font-size: 14px;
  font-weight: 450;
  font-family: Circularstd-Book;

  outline: none;
  resize: none;
}

.inputLoading {
  background: var(--1---Dark-brown, #2d2927);

  border-radius: 7px;
  border: 1px solid var(--2---Chalk-Brown, #55504f);

  padding: 14px 10px;

  color: #928e8c;
  font-size: 14px;
  font-weight: 450;
  font-family: Circularstd-Book;
}

.buttonDiv {
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 10px;
}

.btn {
  width: 100%;

  padding: 11px 13px;

  border: 0;
  border-radius: 9px;

  background: var(--5---Cream-white, #fffff2);

  color: var(--0---carbon-brown, #181716);
  text-align: center;
  font-size: 14px;
  font-weight: 700;
}

.buttonDivText {
  color: var(--3---pastel-brown, #928e8c);
  font-size: 10px;
  font-weight: 450;
}

.loaderDiv {
  height: 40px;
  width: 100%;
}
