.wrapper {
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (max-width: 768px) {
  .wrapper {
    width: 364px;
    align-self: center;
  }
}
