.main {
  width: 100%;
  color: #fffff2;
}

.parent h2 {
  font-size: 20px;
}

.scoreDescDiv {
  display: flex;
  flex-direction: column;
  padding: 15px;
  border-bottom: 1px solid #464542;
}

.scoreBarDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.scoreBarParent {
  position: relative;
  margin-top: 9px;
}

.scoreBarEmpty {
  width: 100%;
  height: 12px;
  border-radius: 7px;
  opacity: 0.05000000074505806;
  background: var(--subtle-yellow, #ffffde);
}

.scoreBar {
  width: 278.579px;
  height: 12px;
  border-radius: 14px;
  background: var(--subtle-yellow, #ffffde);
  position: absolute;
  top: 0;
}

.scorePara {
  color: var(--5-cream-white, #fffff2);
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  opacity: 0.5;
  margin-top: 16px;
}

.improvementTitle {
  color: var(--5-cream-white, #fffff2);
  font-size: 20px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
}

.scoreNumber {
  color: #fff;
  text-align: right;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.histogramParent {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  margin-top: 50px;
  transform: rotate(180deg);
  height: 240px;
  position: relative;
  padding: 0 20px;
}

.histogramBar {
  width: 22.17px;
  background: #ffffde;
  border-radius: 24px;
  max-height: 100%;
  min-width: 22px;
  margin-left: 0;
}

.labels {
  display: flex;
  justify-content: space-between;
  padding-right: 40px;
  margin-top: 10px;
  font-family: "Circularstd-Book";
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #928e8c;
  mix-blend-mode: normal;
  padding: 0 20px;
}

.averageScoreDiv {
  width: 2px;
  position: absolute;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000;
  bottom: 0;
}

.averageScoreCircle {
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: #f493ac;
}

.averageScoreLine {
  width: 2px;
  background-color: #f493ac;
  height: 210px;
}

.userScoreLine {
  width: 2px;
  height: 230px;
}

.userScoreCircle {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.userScoreCircle img {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  transform: rotate(180deg);
  border: 1px solid black;
}

.subTitleDiv {
  display: flex;
  align-items: center;
  color: rgba(255, 255, 242, 0.8);
  font-family: AkkuratStd;
  font-size: 13px;
  font-weight: 500;
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.avScoreLabelCircle {
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #e7aebe;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin-right: 10px;
}

@media only screen and (max-width: 768px) {
  .histogramParent {
    justify-content: space-between;
  }

  .histogramBar {
    width: 22.17px;
    background: #ffffde;
    border-radius: 24px;
    max-height: 100%;
    min-width: 22px;
    margin-left: 0;
  }
}
