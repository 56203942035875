.main {
  width: 100%;
  height: 100vh;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.mainTop {
  width: 100%;
  height: 100dvh;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loader {
  border: 12px solid #55504f;
  /* border-top: 12px solid #c4c4c4; */
  /* border-bottom: 12px solid #fffff2; */
  /* border-right: 12px solid #c4c4c4; */
  border-left: 12px solid transparent;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
  margin-bottom: 5%;
  position: relative;
}

.main p {
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  color: #fffff2;
  margin-top: 79px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
