.main {
  width: 100%;

  padding-bottom: 100px;
}

.swipeWrapper {
  max-width: 610px;
  width: 100%;
  margin-top: -20px;
}

.parent {
  margin-left: 30px;
  margin-top: 40px;
}

.heading {
  font-family: Circularstd-Book;
  font-weight: 450;
  font-size: 22px;
  line-height: 33px;
  letter-spacing: -0.01em;
  color: #fffff2;

  margin-bottom: 20px;
  padding-left: 15px;
}

.storiesDiv p {
  font-size: 13px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: right;
  color: rgba(255, 255, 242, 0.8);
  align-self: flex-end;
  margin-right: 80px;
}

.bottomButtonDiv {
  margin-top: 20px;
  align-self: center;
  margin-right: 80px;
}

/* analysis tab */

.analysisTabDiv {
  margin-top: 17px;

  padding: 0 5.5px;

  display: flex;
  flex-direction: column;
}

.majorHeading {
  color: var(--5---Cream-white, #fffff2);
  font-size: 30px;
  font-weight: 450;

  padding-left: 9.5px;
}

.majorSubHeading {
  color: rgba(255, 255, 242, 0.5);
  font-size: 13px;
  font-weight: 450;

  padding-left: 9.5px;

  margin-top: 3px;
}

.sectionHeader {
  color: var(--5---Cream-white, #fffff2);
  font-size: 22px;
  font-weight: 450;

  margin-top: 40px;

  padding-left: 9.5px;
}

.detailsWrapper {
  display: grid;
  gap: 4px;

  grid-template-columns: repeat(4, 1fr);
  width: fit-content;

  margin-top: 20px;

  align-self: center;
}

.dimensionDiv {
  height: fit-content;
  /* width: fit-content; */

  grid-row: span 1;
  grid-column: span 2;
}

.reportDiv {
  height: fit-content;
  /* width: fit-content; */

  grid-row: span 2;
  grid-column: span 2;
}

.snippetDiv {
  height: fit-content;
  /* width: fit-content; */

  grid-row: span 1;
  grid-column: span 1;
}

.paDiv {
  height: fit-content;
  /* width: fit-content; */

  grid-row: span 1;
  grid-column: span 1;
}

.storiesWrapper {
  flex-wrap: nowrap;
  display: flex;
  gap: 10px;
  overflow-x: auto;

  padding-left: 10px;
}

.storiesWrapper::-webkit-scrollbar {
  display: none;
}

.storiesWrapper {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.moreButton {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  margin-top: 20px;

  padding-right: 8px;

  gap: 4px;
}

.moreText {
  color: rgba(255, 255, 242, 0.8);
  font-size: 13px;
  font-weight: 500;
}

@media only screen and (max-width: 768px) {
  .parent {
    margin-left: 0;
    margin-top: 0;
    padding: 0 10px;
    margin-bottom: 100px;
  }

  .storiesDiv p {
    position: absolute;
    right: 10px;
    margin-right: 0;
  }

  .bottomButtonDiv {
    display: none;
  }
}
