/* preview */
.previewMain {
  display: flex;
  flex-direction: column;

  padding: 13px 8.5px 20px 10.5px;

  gap: 25px;

  border-radius: 7px;
  border: 1px solid var(--3---pastel-brown, #928e8c);

  height: 252px;
  max-height: 252px;
}

.previewTop {
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 5px;
}

.previewTitle {
  color: var(--subtle-yellow, #ffffde);
  font-size: 16px;
  font-weight: 500;
  line-height: 110%;
  letter-spacing: -0.48px;
}

.previewContentWrapper {
  display: flex;
  flex-direction: column;

  gap: 10px;
}

.previewContentRow {
  display: flex;
  flex-direction: column;

  gap: 5px;
}

.previewContentTitle {
  color: var(--5---Cream-white, #fffff2);
  font-size: 9px;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: 0.99px;
}

.previewContentParagraph {
  color: var(--3---pastel-brown, #928e8c);
  font-size: 12px;
  font-style: normal;
  font-weight: 450;
  line-height: 130%;

  max-width: 161px;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}
