.endorsedElementsMain {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  padding-left: 5%;
}

.elementParent {
  display: flex;
  margin: 30px 0 0;
  flex-wrap: wrap;
}

.elementContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  border: 1px solid #fffff2;
  align-items: center;
  width: 91px;
  height: 107px;
  text-align: center;
  overflow: hidden;
}

.elementTop {
  padding-left: 5px;
  padding-right: 5px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.iconDiv {
  width: 15px;
  height: 15px;
  margin-right: auto;
}

.wrapperCircle {
  display: inline-block;
  position: relative;
}

.premiumIcon {
  position: absolute;
  position: absolute;
  bottom: -2px;
  left: 3px;
}

.mainCircle {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  position: relative;
  background-color: rgba(0, 0, 0, 0);
  box-sizing: content-box !important;
}

.elementNameContainer {
  margin-bottom: 5px;
  margin-top: 5px;
  height: 30px;
  display: flex;
  align-items: center;
  overflow: hidden;
  padding: 0 2px;
}

.elName {
  font-size: 12px;
  line-height: 15.18px;
  margin-top: 0;
  opacity: 1;
}

@media only screen and (max-width: 768px) {
  .endorsedElementsMain {
    display: flex;
    flex-direction: column;
    margin-top: 35px;
    padding-left: 15px;
  }

  .elementParent {
    display: flex;
    margin: 30px 0 0;
    flex-wrap: nowrap;
    overflow-x: scroll;
    padding-bottom: 5px;
  }

  .elementContainer {
    display: flex;
    flex-direction: column;
    border: 1px solid #fffff2;
    align-items: center;
    width: 91px;
    height: 107px;
    text-align: center;
    min-width: 91px;
  }
}
