.main {
  width: 100%;
  margin-top: 23px;
  align-self: center;
}

.wrapper {
  border: 1px solid #464542;
}

.header {
  font-size: 20px;
  line-height: 25px;
  letter-spacing: -0.01em;
  color: #fffff2;
  font-weight: 450;

  background: #181716;
  border: 1px solid #464542;

  padding: 2%;
}

.row {
  width: 100%;
  height: 38px;
  border-bottom: 1px solid #464542;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.rowChild {
  display: flex;
  align-items: center;
}

.icon {
  width: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rowChild h3 {
  color: var(--5-cream-white, #fffff2);
  font-size: 13px;
  font-weight: 450;
  margin-left: 10px;

  display: flex;
  align-items: center;
  gap: 4px;
}

.dimensionIconDiv {
  width: 20px;
  height: 20px;

  display: block;
}

@media only screen and (max-width: 768px) {
  .main {
    width: 100%;
    margin-top: 22px;
  }
}
