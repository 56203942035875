.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #fffff2;
  height: 100%;
  width: 500px;
  border-radius: 15px;
}

.topText {
  font-weight: 450;
  font-size: 10px;
  line-height: 13px;
  margin-top: 7px;
}

.iconDiv {
  width: 84px;
  height: 84px;
  margin-top: 32px;
}

.main h1 {
  font-weight: 450;
  font-size: 26px;
  line-height: 33px;
  margin-top: 11px;
}

.estimates {
  margin-top: 15px;
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
  opacity: 0.8;
}

.main h3 {
  font-weight: 450;
  font-size: 16px;
  line-height: 130%;
  margin-top: 27px;
  text-align: center;
  padding: 0 20px;
}

.startButton {
  background: #ffffde;
  backdrop-filter: blur(5.43656px);
  border-radius: 7px;
  width: 217px;
  height: 55px;
  border: 0;
  margin-top: 53px;
  cursor: pointer;
  margin-bottom: 31px;

  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #181716;
}

@media only screen and (max-width: 768px) {
  .main {
    width: 100%;
  }
}
