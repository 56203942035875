.main {
  display: flex;
  flex-direction: column;

  gap: 15px;
}

.title {
  font-size: 19px;
  font-weight: 450;
  line-height: 120%;

  padding: 0 15px;
}

.tabParent {
  margin-top: 15px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  background: var(--0---carbon-brown, #181716);

  position: sticky;
  top: 0;

  z-index: 3;
}

.tabText {
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  line-height: 130%;

  opacity: 0.3;

  flex: 1;

  padding: 4px 0;


  border-bottom: 2px solid #fffff2;

  transition: all 0.3s;
}

.wrapper {
  width: 100%;
}

.mostLikelyTitle {
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;

  margin-top: 35px;
}

.mostLikelyWrapper {
  margin-top: 15px;
}

.mostLikelyRow {
  padding: 10px 20px 11px 15px;

  display: flex;
  align-items: center;

  justify-content: space-between;

  border: 1px solid var(--light-outline, #464542);
}

.label {
  color: var(--4---Beige, #e6e1df);
  font-size: 13px;
  font-weight: 700;
}

.group {
  text-align: right;
  font-size: 13px;
  font-weight: 450;
}

.breakdownWrapper {
  margin-top: 15px;

  display: grid;
  grid-template-columns: repeat(2, 1fr);

  grid-gap: 15px 17px;
  gap: 15px 17px;

  padding-bottom: 5px;
}

.breakdownParent {
  display: flex;
  flex-direction: column;

  gap: 4px;

  grid-row: span 1;
  grid-column: span 1;
}

.breakdownRowTitle {
  font-size: 13px;
  font-weight: 450;
}

.breakdownRect {
  width: 100%;
  height: 15px;

  border-radius: 3px;

  background: rgba(255, 255, 242, 0.1);

  padding-left: 20px;
  padding-right: 10px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  position: relative;
}

.breakdownLabel {
  font-size: 10px;
  font-weight: 700;

  z-index: 2;
}

.breakdownPercentage {
  color: var(--3---pastel-brown, #928e8c);
  text-align: right;
  font-size: 9px;
  font-weight: 450;

  z-index: 2;
}

.breakdownPercentageDiv {
  position: absolute;
  top: 0;
  left: 0;

  height: 15px;

  max-width: 100%;

  border-radius: 3px;
  background: var(--2---Chalk-Brown, #55504f);
}

.breakdownGHostboxMain {
  width: 164px;
  height: 52px;

  border: 1px dashed var(--light-outline, #464542);

  display: flex;
  justify-content: center;
  align-items: center;
}

.breakdownGHostboxText {
  color: var(--3---pastel-brown, #928e8c);
  text-align: center;
  font-size: 12px;
  font-weight: 450;
}
