.wrapper {
  display: grid;
  gap: 4px;

  grid-template-columns: repeat(4, 1fr);
  width: fit-content;

  align-self: center;
}

.nushellDiv {
  height: fit-content;

  grid-row: span 2;
  grid-column: span 2;
}

.snippetDiv {
  height: fit-content;

  grid-row: span 1;
  grid-column: span 1;
}
