.mainSmall {
  min-width: 88px;
  width: 88px;
  height: 123px;

  border-radius: 7px;
  border: 1px solid var(--3---pastel-brown, #928e8c);

  display: flex;
  flex-direction: column;
  color: #fffff2;
  background-size: 88px;
  background-position: center;
  cursor: pointer;
  position: relative;
}

.mainSmallResults {
  height: 123px;

  border-radius: 7px;
  border: 1px solid var(--3---pastel-brown, #928e8c);

  display: flex;
  flex-direction: column;
  color: #fffff2;
  background-size: 88px;
  background-position: center;
  cursor: pointer;
  position: relative;
}

.analysisIconDiv {
  align-self: center;

  margin-top: 30px;
}

.titleSmall {
  color: var(--5---Cream-white, #fffff2);
  text-align: center;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
  font-size: 11px;
  font-weight: 500;
  letter-spacing: -0.33px;

  margin-top: auto;
  margin-bottom: 10px;
}

.premiumLogoSmall {
  position: absolute;
  top: 5px;
  left: 5px;
}

@media only screen and (max-width: 768px) {
  .parent {
    margin-left: 0;
    padding: 0 10px;
  }

  .analysisWrapper {
    overflow-x: visible;
  }

  .storiesWrapper {
    flex-wrap: nowrap;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .storiesWrapper::-webkit-scrollbar {
    display: none;
  }
}
