.main {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 100px;
}

.main h2 {
  font-weight: 350;
}

.top {
  width: 980px;
  margin-left: 54px;
}

.heading {
  color: #fffff2;
  font-size: 50px;
  font-family: Circularstd-Book;
  font-weight: 350;
  margin-top: 20px;
}

.subHeading {
  color: #fffff2;
  font-size: 22px;
  font-family: Circularstd-Book;
  font-weight: 350;
  opacity: 0.8;
  line-height: 130%;
  margin-top: 19px;
}

.subHeading span {
  color: #2cb0ff;
  margin-left: 4px;
}

.parent {
  width: 100%;
  margin-bottom: 37px;
  margin-left: 0;
}

.rightBarMain {
  width: 100%;
  margin-left: 0;
  position: relative;
}

.markdownDiv {
  color: #fffff2;
  margin-top: 38px;
  padding: 0 5px;
}

.markdownDiv a {
  color: #2cb0ff;
  opacity: 1;
}

.markdownDiv h3 {
  margin-top: 25px;
  margin-bottom: 10px;
  font-size: 26px;
  line-height: 33px;
  font-weight: 350;
  font-family: Circularstd-Book;
}

.markdownDiv h2 {
  margin-top: 25px;
  margin-bottom: 10px;
  font-size: 26px;
  line-height: 33px;
  font-weight: 350;
  font-family: Circularstd-Book;
}

.markdownDiv ul {
  font-family: Circularstd-Book;
  font-weight: 350;
  font-size: 16px;
  line-height: 130%;
  color: #fffff2;
  opacity: 0.8;
  margin-left: 20px;
  margin-top: 10px;
}

.markdownDiv ol {
  font-family: Circularstd-Book;
  font-weight: 350;
  font-size: 16px;
  line-height: 130%;
  color: #fffff2;
  opacity: 0.8;
  margin-left: 28px;
  margin-top: 10px;
}

.markdownDiv p {
  font-family: Circularstd-Book;
  font-weight: 350;
  font-size: 16px;
  line-height: 130%;
  color: #fffff2;
  opacity: 0.8;
  margin-top: 20px;
}

.detialsDiv {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  color: white;
  align-content: baseline;
}

.archetypeDiv {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .main {
    max-width: 500px;
    align-items: flex-start;
  }
}

@media only screen and (max-width: 768px) {
  .main {
    width: 100%;
  }

  .top {
    width: 100%;
    padding: 0 15px;
    margin-left: 0;
  }

  .parent {
    width: 100%;
    margin-bottom: 37px;
    margin-left: 0;
  }

  .subHeading {
    font-size: 16px;
  }

  .rightBarMain {
    width: 100%;
    margin-left: 0;
  }

  .markdownDiv {
    padding: 0 10px;
  }
}
