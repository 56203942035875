@font-face {
  font-family: "Circularstd-Book";
  src: local("CircularStd-Book"),
    url("../../src/assets/fonts/CircularStd-Book.woff") format("woff");
}

@font-face {
  font-family: "Circularstd-Book";
  src: local("CircularStd-Bold"),
    url("../../src/assets/fonts/CircularStd-Bold.woff") format("woff");
  font-weight: 700;
}

@font-face {
  font-family: "Circular Air";
  src: local("Circular Air-Light"),
    url("../../src/assets/fonts//Circular\ Air-Light.woff") format("woff");
  font-weight: 300;
}

@font-face {
  font-family: "AkkuratStd";
  src: local("AkkuratStd"),
    url("../../src/assets/fonts/AkkuratStd-Regular.woff") format("woff");
  font-weight: 450;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

button {
  font-family: Circularstd-Book !important;
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

a {
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  background-color: #181716;
  font-family: Circularstd-Book;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

input {
  outline: none;
}

a {
  color: inherit;
  text-decoration: none;
}

.flex {
  display: flex;
}

.androidBetaWarning {
  color: var(--5---Cream-white, #fffff2);
  text-align: center;
  font-size: 12px;
  font-weight: 450;
}

.headingFont {
  font-family: Circularstd-Book;
  font-weight: 400;
}

.archetype-image {
  clip-path: url(#ArchetypeImageClipPath);
  -webkit-clip-path: url(#ArchetypeImageClipPath);
  position: absolute;
  top: 20px;
  left: 20px;
}

.archetype-image-small {
  clip-path: url(#ArchetypeImageClipPath);
  -webkit-clip-path: url(#ArchetypeImageClipPath);
  position: absolute;
  top: 20px;
  left: 20px;
}

.other-archetype-image {
  clip-path: url(#ArchetypeImageClipPath);
  -webkit-clip-path: url(#ArchetypeImageClipPath);
  position: absolute;
  top: 20px;
  left: 7.5px;
}

.archetype-image-large {
  clip-path: url(#ArchetypeImageClipPathLarge);
  -webkit-clip-path: url(#ArchetypeImageClipPathLarge);
  position: absolute;
  top: 48px;
  left: 42px;
}

.archetype-image-large-modal {
  clip-path: url(#ArchetypeImageClipPathLarge);
  -webkit-clip-path: url(#ArchetypeImageClipPathLarge);
  position: absolute;
  top: 5px;
  left: 19px;
}

.archetype-image-discovery {
  clip-path: url(#ArchetypeImageClipPathLarge);
  -webkit-clip-path: url(#ArchetypeImageClipPathLarge);
  position: absolute;
  top: 38px;
  left: 42px;
}

.patternMaksContainer {
  clip-path: url(#patternImageClipPath);
  -webkit-clip-path: url(#patternImageClipPath);
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.patternMaksContainerLarge {
  clip-path: url(#patternImageClipPathLarge);
  -webkit-clip-path: url(#patternImageClipPathLarge);
  width: 100%;
  height: 100%;
  display: flex;
  position: relative;
}

.dailyInsightIconMain {
  width: 80px;
  height: 80px;
  position: relative;
  -webkit-mask-image: url(/insightMask.png);
  mask-image: url(/insightMask.png);
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: cover;
}

.dailyInsightIconMainLarge {
  width: 125px;
  height: 128px;
  -webkit-mask-image: url(/insightMaskLarge.png);
  mask-image: url(/insightMaskLarge.png);
  mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: cover;
}

.dailyInsightIconSmall {
  width: 52px;
  height: 52px;
  position: relative;
  -webkit-mask-image: url(/insightMask.png);
  mask-image: url(/insightMask.png);
  mask-repeat: no-repeat;
  mask-position: center;
  mask-size: cover;
}

.dailyInsightContainer {
  display: flex;
  width: 100%;
  height: 100%;
}

.innerInsightDiv {
  flex: 1;
  height: 100%;
}

.echarts-for-react {
  height: 400px !important;
  width: 534px;
  min-width: 534px;
  align-self: center;
}

/* .shimmer {
  color: grey;
  display: inline-block;
  -webkit-mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300%
    100%;
  background-repeat: no-repeat;
  animation: shimmer 2.5s infinite;
  font-size: 50px;
  max-width: 200px;
  width: 100%;
} */

@keyframes shimmer {
  100% {
    -webkit-mask-position: left;
  }
}

.loader-parent {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  /* border: 12px solid #55504f;
  border-left: 12px solid transparent;
  border-radius: 50%; */
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
  margin-bottom: 5%;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.stickyContainer {
  position: fixed !important;
  width: 580px;
  padding: 0 2%;
  top: 0;
  z-index: 1000;
}

.RightBar_mobileBioLinksParent__m07ds + .stickyContainer {
  padding-top: 100px;
}

.mobile-logo {
  display: none;
}

.section-heading {
  font-size: 26px;
  font-weight: 350;
  font-family: Circularstd-Book;
  color: #fffff2;
  line-height: 33px;
}

.section-sub-heading {
  font-family: Circularstd-Book;
  font-size: 13px;
  color: #928e8c;
  font-weight: 350;
}

.active {
  border: 1px solid #fffff2;
}

.activePeer {
  /* border: 1px solid var(--0-vibrant-blue, #2cb0ff); */
  border: 1px solid #2cb0ff !important;
}

.dictCircle0 {
  width: 86px;
  height: 86px;
  background: rgba(141, 139, 255, 0.2);
  border: 2px solid #8d8bff;
  border-radius: 50%;
  margin-right: 45px;
}
.dictCircle1 {
  width: 69px;
  height: 69px;
  background: rgba(174, 138, 221, 0.2);
  border: 2px solid #ae8add;
  border-radius: 50%;
  margin-right: 45px;
}
.dictCircle2 {
  width: 58px;
  height: 58px;
  background: rgba(197, 138, 197, 0.2);
  border: 2px solid #c58ac5;
  border-radius: 50%;
  margin-right: 45px;
}
.dictCircle3 {
  width: 69px;
  height: 69px;
  background: rgba(222, 137, 170, 0.2);
  border: 2px solid #de89aa;
  border-radius: 50%;
  margin-right: 45px;
}
.dictCircle4 {
  width: 86px;
  height: 86px;
  background: rgba(255, 136, 136, 0.2);
  border: 2px solid #ff8888;
  border-radius: 50%;
}

.quadrant1 {
  z-index: 10000;
  position: absolute;
  font-size: 12px;
  top: 77px;
  left: 63px;
}

.quadrant2 {
  z-index: 10000;
  position: absolute;
  font-size: 12px;
  top: 77px;
  right: 43px;
}

.quadrant3 {
  z-index: 10000;
  position: absolute;
  font-size: 12px;
  bottom: 77px;
  right: 63px;
}

.quadrant4 {
  z-index: 10000;
  position: absolute;
  font-size: 12px;
  bottom: 77px;
  left: 63px;
}

.lovequadrant1 {
  z-index: 10000;
  position: absolute;
  font-size: 12px;
  top: 77px;
  left: 63px;
}

.lovequadrant2 {
  z-index: 10000;
  position: absolute;
  font-size: 12px;
  top: 77px;
  right: 43px;
}

.lovequadrant3 {
  z-index: 10000;
  position: absolute;
  font-size: 12px;
  bottom: 77px;
  left: 63px;
}

.lovequadrant4 {
  z-index: 10000;
  position: absolute;
  font-size: 12px;
  bottom: 77px;
  right: 63px;
}

.swiper-slide {
  /* touch-action: pan-x; */
}

.blink {
  animation: blinker 0.2s linear infinite;
}

dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100000000;
  border-radius: 8px;
  background-color: #464542;
  color: #fffff2;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-width: 0;
}

.dialogue h1 {
  font-size: 18px;
  padding: 5px;
}

.dialogue p {
  font-size: 14px;
  text-align: center;
  padding: 5px;
  font-weight: 400;
}

.dialogButtonDiv {
  width: 100%;
  display: flex;
  margin-top: 10px;
  border-top: 1px solid #fffff2;
  color: #2cb0ff;
}

.dialogButton {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
}

.firstButton {
  border-right: 1px solid #fffff2;
  font-weight: bold;
}

.elBody {
  margin: 2px;
}

.elBodyNoMarginMobile {
  margin: 2px;
}

.elBodyFiveMArgin {
  margin: 5px;
}

.elBodyNoMarginTopBottom {
  margin: 0 2px;
}

.elBodyNoMargin {
  margin: 0;
}

.elBodyUnknown {
  margin-top: 10px;
  margin-right: 6px;
}

.PhoneInputInput {
  border-radius: 0 !important;
  border: 0 !important;
  border-bottom: 1px #fffff2 solid !important;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  html,
  body {
    max-width: none;
    overflow-x: visible;
  }
}

@media only screen and (max-width: 768px) {
  dialog {
    border-radius: 0;
  }

  html,
  body {
    max-width: none;
    overflow-x: visible;
  }

  a {
    cursor: auto;
  }

  .androidBetaWarning {
    font-size: 9px;
  }

  .stickyContainer {
    position: fixed;
    width: 100%;
    padding: 0 0;
    top: 0;
    z-index: 1000;
  }

  .mobile-logo {
    display: flex;
    color: #fffff2;
    font-size: 25.57px;
    margin-top: 5px;
  }

  .logo {
    display: none;
  }

  .archetype-image {
    top: 15px;
    left: 20px;
  }

  .archetype-image-small {
    top: 20px;
    left: 20px;
  }

  .archetype-image-large {
    position: static;
  }

  .archetype-image-large-modal {
    left: 17px;
    top: 10px;
  }

  .echarts-for-react {
    height: 200px !important;
    width: 100%;
    min-width: 0;
  }

  .dictCircle0 {
    width: 60px;
    height: 60px;
    background: rgba(141, 139, 255, 0.2);
    border: 2px solid #8d8bff;
    border-radius: 40px;
    margin-right: 22px;
  }
  .dictCircle1 {
    width: 50px;
    height: 50px;
    background: rgba(174, 138, 221, 0.2);
    border: 2px solid #ae8add;
    border-radius: 40px;
    margin-right: 22px;
  }
  .dictCircle2 {
    width: 44px;
    height: 44px;
    background: rgba(197, 138, 197, 0.2);
    border: 2px solid #c58ac5;
    border-radius: 40px;
    margin-right: 22px;
  }
  .dictCircle3 {
    width: 50px;
    height: 50px;
    background: rgba(222, 137, 170, 0.2);
    border: 2px solid #de89aa;
    border-radius: 40px;
    margin-right: 22px;
  }
  .dictCircle4 {
    width: 60px;
    height: 60px;
    background: rgba(255, 136, 136, 0.2);
    border: 2px solid #ff8888;
    border-radius: 40px;
  }

  dialog {
    width: 80%;
  }

  .elBodyNoMarginMobile {
    margin: 0;
  }

  /* .swiper-slide {
    overflow: auto !important;
  } */
}
