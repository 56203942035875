.wrapper {
  display: flex;
  flex-direction: column;

  gap: 10px;

  padding: 0 15px;
}

.optionDiv {
  position: relative;

  padding: 11px 20px;

  border-radius: 7px;
  background: var(--2---Chalk-Brown, #55504f);

  width: 100%;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.optionLabel {
  color: var(--6---Bone-white, #fffffa);
  font-size: 14px;
  font-weight: 700;

  z-index: 2;
}

.optionLabelPercentage {
  color: var(--6---Bone-white, #fffffa);
  font-size: 14px;
  font-weight: 450;

  z-index: 2;
}

.pollScoreDiv {
  border-radius: 7px;
  background: var(--vibrant-blue, #2cb0ff);

  height: 40px;

  position: absolute;
  left: 0;
}

.ghostboxMain {
  display: flex;
  flex-direction: column;

  padding: 0 15px;
}

.ghostboxDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 30px 27px;

  gap: 20px;

  width: 100%;

  border-radius: 7px;
  border: 1px dashed rgba(255, 255, 242, 0.2);
}

.warningIconDiv {
  width: 40px;
  height: 40px;

  padding: 8.679px 7.333px 9px 7.369px;
}

.ghostboxText {
  text-align: center;
  font-size: 14px;
  font-weight: 450;
}
