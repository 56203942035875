.main {
  display: flex;
  flex-direction: column;
  width: 100%;

  padding-top: 13px;
  padding-bottom: 15px;

  border-bottom: 2px solid var(--2---Chalk-Brown, #55504f);
}

.mainConnected {
  display: flex;
  flex-direction: column;
  width: 100%;

  padding-top: 13px;
  padding-bottom: 37px;
}

.top {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
}

.topInfoDiv {
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 6px;
}

.numConnections {
  color: var(--5---Cream-white, #fffff2);
  text-align: center;
  font-size: 26px;
  font-weight: 450;
  line-height: 130%;
  letter-spacing: -0.78px;
}

.connectionsText {
  color: var(--5---Cream-white, #fffff2);
  text-align: center;
  font-size: 11px;
  font-weight: 450;
  line-height: 130%;
  letter-spacing: -0.33px;
}

.bottom {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 10px;
}

.name {
  color: var(--5---Cream-white, #fffff2);
  text-align: center;
  font-size: 31px;
  font-weight: 450;
  line-height: 130%;
  letter-spacing: -0.93px;
}

.url {
  color: var(--3---pastel-brown, #928e8c);
  text-align: center;
  font-size: 12px;
  font-weight: 450;
}

.linkIcon {
  display: flex;
  width: 18px;
  height: 18px;
  padding: 1.7px 1.628px 1.648px 1.68px;
  justify-content: center;
  align-items: center;
}

.ctaButton {
  border: 0;
  border-radius: 9px;

  background: var(--5---Cream-white, #fffff2);

  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;

  padding: 11px 13px;

  color: var(--0---carbon-brown, #181716);
  font-size: 14px;
  font-weight: 700;

  width: fit-content;
}

.profileCtaDiv {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 15px;
}

.ambassDiv {
  margin-top: 14px;

  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 6px;
}
