.main {
  display: flex;
  flex-direction: column;
}

.heading {
  font-size: 26px;
  font-weight: 450;
  letter-spacing: -0.78px;
}

.wrapper {
  display: flex;
  align-items: center;

  margin-top: 15px;
  gap: 8px;

  overflow-x: scroll;
}

.assessmentDiv {
  width: 323px;
  min-width: 323px;
  height: 362px;

  display: flex;
  flex-direction: column;
  align-items: center;

  border-radius: 7px;
  border: 1px solid var(--3---pastel-brown, #928e8c);

  padding-bottom: 27px;
}

.iconDiv {
  width: 75px;
  height: 75px;

  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 42px;
}

.dimTitle {
  text-align: center;
  font-size: 26px;
  font-weight: 450;

  margin-top: 31px;
}

.dimDesc {
  text-align: center;
  font-size: 14px;
  font-weight: 450;
  line-height: 130%;

  margin-top: 5px;
}

.dimDetailsText {
  text-align: center;
  font-size: 13px;
  font-weight: 500;

  margin-top: 13px;
}

.btn {
  margin-top: auto;

  border: 0;
  border-radius: 9px;
  background: var(--5---Cream-white, #fffff2);

  padding: 11px 13px;

  color: var(--0---carbon-brown, #181716);
  font-size: 14px;
  font-weight: 700;
}

.viewAllDiv {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  gap: 4px;

  margin-top: 20px;
}

.viewAllText {
  color: rgba(255, 255, 242, 0.8);
  font-size: 13px;
  font-weight: 500;
}

.wrapper::-webkit-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: none;
}
