.h3 {
  font-family: Circularstd-Book;
  font-style: normal;
  font-weight: 350;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: #fffff2;
  opacity: 0.5;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
}

.arrowsDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}
