.main {
  margin-top: 20px;

  display: flex;
  flex-direction: column;

  gap: 20px;

  padding: 0 5px;
}

.mainShimmer {
  display: flex;
  align-items: center;

  gap: 10px;
}

.shimmerCircle {
  width: 52px;
  height: 52px;

  min-width: 52px;
  min-height: 52px;

  border-radius: 50%;
}

.shimmerRect {
  width: 100%;
  height: 50px;
}
