.main {
  display: flex;
  flex-direction: column;

  position: fixed;

  width: 100%;
  height: 100%;

  z-index: 10000000;

  border-radius: 30px 30px 0px 0px;

  background: var(--0---carbon-brown, #181716);

  padding: 0 10px;
  padding-top: 9px;

  color: var(--5---Cream-white, #fffff2);

  margin-top: 100vh;

  overflow-x: hidden;

  transition: margin 0.5s;
}

.mainLowZIndedx {
  display: flex;
  flex-direction: column;

  position: fixed;

  width: 100%;
  height: 100%;

  border-radius: 30px 30px 0px 0px;

  background: var(--0---carbon-brown, #181716);

  padding: 0 10px;
  padding-top: 9px;

  color: var(--5---Cream-white, #fffff2);

  margin-top: 100vh;

  overflow-x: hidden;

  transition: margin 0.5s;
}

.header {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 15px;

  padding-bottom: 10px;

  border-bottom: #464542 solid 1px;
}

.line {
  width: 30px;
  height: 3px;

  border-radius: 4px;
  background: var(--4---Beige, #e6e1df);
}

.headerTitle {
  font-size: 14px;
  font-weight: 450;
  line-height: 120%;
}

.closeButtonDiv {
  position: absolute;
  right: 0px;
  bottom: 0;
}

.wrapper {
  display: flex;
  flex-direction: column;

  gap: 16px;

  padding-top: 10px;

  overflow-y: auto;

  padding-bottom: 150px;
}

.wrapper::-webkit-scrollbar {
  display: none;
}

.bottom {
  width: 100%;

  position: absolute;
  bottom: 0;
  left: 0;
}

.commentThreadWIthShowMore {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.ghostboxParent {
  display: flex;
  align-items: center;

  padding: 0px 10px;

  width: 100%;

  border: 1px dashed rgba(255, 255, 242, 0.2);

  height: 70px;

  margin-top: 30px;
}

.ghostboxText {
  font-size: 14px;
  font-weight: 450;

  opacity: 0.8;
}

.loadingDivDeletion {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: fixed;

  width: 100%;
  height: 100%;

  z-index: 10000000000;

  background: var(--0---carbon-brown, #181716);
}

/* caption */
.captionSection {
  margin-top: 10px;

  display: flex;
  align-items: flex-start;

  gap: 8px;

  padding-bottom: 16px;

  border-bottom: 1px solid #464542;
}

.captionDeatailsDiv {
  display: flex;
  flex-direction: column;

  gap: 4px;
}

.captionDeatailsTop {
  display: flex;
  align-items: center;

  gap: 10px;
}

.captionUsernameAndDate {
  color: var(--3---pastel-brown, #928e8c);
  font-size: 14px;
  font-weight: 450;
  line-height: 120%;
}

.captionAuthorTag {
  border-radius: 4px;

  background: var(--3---pastel-brown, #928e8c);

  padding: 2px 4px;

  color: var(--5---Cream-white, #fffff2);
  font-size: 11px;
  font-weight: 450;
}

.caption {
  color: var(--4---Beige, #e6e1df);
  font-size: 14px;
  font-weight: 450;
  line-height: 120%;
}
