.main {
  display: flex;
  flex-direction: column;

  padding: 0 20px;
  padding-bottom: 50px;
}

.logoDiv {
  display: flex;
  justify-content: center;
  align-items: center;

  padding-top: 36px;
}

.titleDiv {
  margin-top: 32px;

  display: flex;
  flex-direction: column;
  gap: 5px;
}

.title {
  color: var(--5---Cream-white, #fffff2);
  font-size: 26px;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: -0.52px;
}

.description {
  color: var(--35-text, #c0b9b7);
  font-size: 14px;
  font-weight: 450;
  line-height: 130%;
}

.orderedMatchParentDiv {
  margin-top: 50px;
}

.orderedMatchFirstCard {
  border-radius: 7px;
  border: 1px solid var(--3---pastel-brown, #928e8c);

  display: flex;
  flex-direction: column;

  gap: 16px;

  padding: 25px 20px;
}

.firstCardTitle {
  color: var(--5---Cream-white, #fffff2);
  text-align: center;
  font-size: 26px;
  font-style: normal;
  font-weight: 450;
  line-height: 130%;
}

.firstCardDesc {
  color: var(--35-text, #c0b9b7);
  font-family: AkkuratStd;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
}

.whyText {
  color: var(--35-text, #c0b9b7);
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: 1.21px;
  text-decoration-line: underline;
}

.firstCardUl {
  color: var(--35-text, #c0b9b7);
  font-family: AkkuratStd;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;

  padding-left: 20px;
}

.otherMatchesText {
  margin-top: 20px;

  color: var(--5---Cream-white, #fffff2);
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: 1.21px;
}

.otherMAtchesMappedDiv {
  display: flex;
  align-items: center;

  gap: 10px;

  margin-top: 10px;
}

.otherMAtchesMappedTextDiv {
  display: flex;
  align-items: center;

  gap: 5px;
}

.otherMAtchesMappedText {
  color: var(--35-text, #c0b9b7);
  font-family: AkkuratStd;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%;
}

.UnOrderedMatchParentDiv {
  margin-top: 38px;

  padding: 0 5px;

  display: flex;
  flex-direction: column;

  gap: 10px;
}

.orderedListTextDiv {
  display: flex;
  align-items: center;

  gap: 5px;
}

.orderedListText {
  color: var(--4---Beige, #e6e1df);
  font-family: AkkuratStd;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
