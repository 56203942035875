.main {
  height: 100vh;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  justify-content: center;
}

.warningDiv {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 158.117px;
  height: 156.842px;

  padding: 34.031px 28.985px 35.289px 29.131px;
}

.text {
  color: var(--5---Cream-white, #fffff2);
  text-align: center;
  font-size: 20px;
  font-weight: 450;
  line-height: 19px;
}

.btn {
  border: 0;

  padding: 11px 13px;

  border-radius: 9px;
  background: var(--5---Cream-white, #fffff2);

  color: var(--0---carbon-brown, #181716);
  text-align: center;
  font-size: 14px;
  font-weight: 700;

  width: fit-content;

  align-self: center;

  margin-top: 50px;
}
