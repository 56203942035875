.row {
  min-width: 88px;
  max-width: 88px;
  min-height: 123px;
  max-height: 123px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  border-radius: 7px;
  border: 1px solid var(--3---pastel-brown, #928e8c);
  padding-top: 30px;
  padding-bottom: 8px;
}

.resultsBlock {
  width: 100%;
  height: 123px;

  display: flex;
  justify-content: center;
  align-items: center;

  gap: 23px;

  border-radius: 7px;
  border: 1px solid var(--3---pastel-brown, #928e8c);

  position: relative;
}

.resultsDimensionText {
  color: var(--5---Cream-white, #fffff2);
  font-size: 9px;
  font-weight: 700;
  letter-spacing: 1px;

  opacity: 0.5;
}

.resultTitle {
  color: var(--5---Cream-white, #fffff2);
  font-size: 16px;
  font-weight: 500;
  line-height: 110%;
  letter-spacing: -0.48px;

  margin-top: 2px;

  width: 80px;
}

.iconDiv {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rowTitle {
  color: var(--5---Cream-white, #fffff2);
  font-size: 11px;
  font-weight: 500;
  line-height: 110%; /* 12.1px */
  letter-spacing: -0.33px;
  margin-top: auto;
  padding: 0px 3px;
  text-align: center;
}

.premiumLogo {
  position: absolute;

  top: 10px;
  left: 10px;
}

@media only screen and (max-width: 768px) {
  .row {
    max-width: 86px;
    min-width: 86px;
    height: 123px;
  }
}
