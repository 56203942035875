.main {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  width: 100%;
  position: relative;
}

.headerModal {
  padding: 5px 10px;
  background: var(--5---Cream-white, #fffff2);

  color: var(--0---carbon-brown, #181716);
  font-size: 22px;
  font-weight: 450;
  letter-spacing: -0.22px;
}

.chartMain {
  width: 100%;
  height: 468px;

  color: #fffff2;
  text-align: center;

  position: relative;

  display: flex;
  align-self: center;
  flex-direction: column;

  padding: 15px 9px;

  border: 0.5px solid #fffff257;
}

.parent {
  width: 100%;
  max-width: 325px;
  height: 100%;
  display: flex;
  flex-direction: column;
  display: flex;
  flex-direction: column;
  align-self: center;
  position: relative;
}

.label {
  font-size: 13px;
}

.rowTop {
  display: flex;
  border-bottom: 1px solid #fffff257;
  height: 50%;
}

.row {
  display: flex;
  height: 50%;
}

.leftTop {
  border-right: 1px solid #fffff257;
  width: 50%;
  color: #fffff2;
  display: flex;
  align-items: flex-end;
  position: relative;
}

.rightTop {
  width: 50%;
  color: #fffff2;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  position: relative;
}

.leftBottom {
  border-right: 1px solid #fffff257;
  width: 50%;
  position: relative;
}

.rightBottom {
  width: 50%;
  position: relative;
}

.labelOne {
  position: absolute;
  top: 30%;
  left: 50px;

  text-align: center;
  color: var(--3---pastel-brown, #928e8c);
  font-size: 14px;
  font-weight: 450;
}

.labelTwo {
  position: absolute;
  top: 30%;
  right: 60px;

  text-align: center;
  color: var(--3---pastel-brown, #928e8c);
  font-size: 14px;
  font-weight: 450;
}

.labelThree {
  position: absolute;
  bottom: 25%;
  left: 50px;

  text-align: center;
  color: var(--3---pastel-brown, #928e8c);
  font-size: 14px;
  font-weight: 450;
}

.labelFour {
  position: absolute;
  bottom: 25%;
  right: 50px;

  text-align: center;
  color: var(--3---pastel-brown, #928e8c);
  font-size: 14px;
  font-weight: 450;
}

.info {
  margin-top: 30px;

  color: var(--35-text, #c0b9b7);
  font-size: 12px;
  font-weight: 450;
  line-height: 130%;
  text-align: left;
}
