.moreBtn {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 10px;

  border: 0;

  background-color: transparent;

  color: rgba(255, 255, 242, 0.8);
  text-align: center;
  font-size: 14px;
  font-weight: 450;

  opacity: 0.8;
}
