.main {
  display: flex;
  flex-direction: column;

  gap: 10px;
}

.box {
  border-radius: 15px;
  border: 1px solid var(--light-outline, #464542);

  display: flex;
  flex-direction: column;

  padding-top: 16.5px;

  min-height: 350px;
}

.connectionShareDiv {
  display: flex;
  align-items: center;

  gap: 7px;

  width: 375px;
  height: 25px;

  padding-left: 13px;
}

.connectionShareWrapper {
  display: flex;
  align-items: center;
}

.connectionShareDivText {
  color: var(--3---pastel-brown, #928e8c);
  font-size: 11px;
  font-weight: 450;
  line-height: 130%;
}

.quizTitle {
  align-self: center;

  color: var(--5---Cream-white, #fffff2);
  text-align: center;
  font-size: 20px;
  font-weight: 450;
  line-height: 130%;

  width: 259px;

  margin-top: 39px;
}

.quizScore {
  align-self: center;

  color: var(--5---Cream-white, #fffff2);
  text-align: center;
  font-size: 50px;
  font-weight: 450;

  margin-top: 32px;
}

.spanScore {
  color: var(--3---pastel-brown, #928e8c);
  font-size: 16px;
  font-weight: 450;
  line-height: 130%;
  letter-spacing: -0.16px;
}

.yourScoreText {
  color: var(--3---pastel-brown, #928e8c);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 130%;
}

.bottom {
  display: flex;
  justify-content: space-between;

  width: inherit;

  margin-top: auto;
}
