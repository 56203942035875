.main {
  width: 325px;
  color: #fffff2;
  align-self: center;
}

.mainLine {
  width: 100%;
  border-bottom: 1px solid #fffff2;
  margin-top: 110px;
  position: relative;
}

.dividerParent {
  position: absolute;
  top: -13px;
  width: 100%;
  display: flex;
  align-items: center;
}

.dividerChild {
  flex-grow: 1;
  border-left: 1px solid #fffff2;
  border-right: 1px solid #fffff2;
  height: 26px;
}

.captionsDiv {
  display: flex;
  align-items: center;
  margin-top: 13px;
}

.caption {
  width: 33%;
  color: var(--5-cream-white, #fffff2);
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
}

.desc {
  color: var(--5-cream-white, #fffff2);
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  margin-top: 32px;
  opacity: 0.5;
}

.averageScoreDiv {
  width: 2px;
  position: absolute;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000;
  bottom: 0;
  transform: rotate(180deg);
}

.userScoreLine {
  width: 2px;
  min-height: 24px;
}

.userScoreCircle {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.userScoreCircle img {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  border: 1px solid black;
  transform: rotate(180deg);
}

.subTitleDiv {
  display: flex;
  align-items: center;
  color: rgba(255, 255, 242, 0.8);
  font-family: AkkuratStd;
  font-size: 13px;
  font-weight: 500;
  margin-top: 20px;
  margin-bottom: 24px;
}

.avScoreLabelCircle {
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #e7aebe;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin-right: 10px;
}

.averageScoreCircle {
  min-width: 11px;
  min-height: 11px;
  border-radius: 50%;
  background-color: #f493ac;
}
