.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 50%;
  top: 50%;
  width: 100vw;
  height: 100vh;
  height: 100dvh;
  background-color: #181716;
  z-index: 10000;

  overflow-y: auto;

  padding: 0 10px;

  transition: all 0.2s;
}

.xmark {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 35px;
  height: 35px;
  right: 30px;
  top: 39px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: center;

  gap: 20px;

  border-radius: 7px;
  border: 1px dashed rgba(255, 255, 242, 0.2);

  padding: 20px 27px;
}

.warningIconDiv {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;

  padding: 8.679px 7.333px 9px 7.369px;
}

.copy {
  color: var(--5---Cream-white, #fffff2);
  text-align: center;
  font-size: 14px;
  font-weight: 450;
}

.button {
  border: 0;
  border-radius: 9px;

  background: var(--5---Cream-white, #fffff2);

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 11px 13px;

  color: var(--0---carbon-brown, #181716);
  text-align: center;
  font-size: 14px;
  font-weight: 700;
}

@media only screen and (max-width: 768px) {
  .xmark {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 35px;
    height: 35px;
    right: 20px;
    top: 39px;
  }
}
