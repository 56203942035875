.main {
  width: 100%;
  color: #fffff2;
  margin-bottom: 100px;
}

/* .stories {
  margin-top: 35px;
  padding: 0 5%;
}

.storiesWrapper {
  display: flex;
  flex-wrap: wrap;
  overflow-x: auto;
  margin-top: 10px;
  gap: 5px;
}

.storiesHeader {
  font-size: 26px;
  font-weight: 350;
  font-family: Circularstd-Book;
  color: #fffff2;
  line-height: 33px;
}

.allStories {
  font-size: 13px;
  line-height: 17px;
  color: rgba(255, 255, 242, 0.8);

  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 5px;
  margin-right: 50px;
} */

.stories {
  margin-top: 50px;
  padding-left: 5%;
}

.storiesDiv {
  margin-top: 40px;
}

.dimensionSummariesDiv {
  padding: 0 5%;
}
@media only screen and (max-width: 768px) {
  .main {
    width: 100%;
  }
  /* .stories {
    padding: 0;
    padding-left: 15px;
  }

  .dimensionSummariesDiv {
    padding: 0 15px;
    margin-top: -35px;
  }

  .storiesWrapper {
    flex-wrap: nowrap;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .storiesWrapper::-webkit-scrollbar {
    display: none;
  }

  .allStories {
    margin-right: 10px;
  } */
}
