.main {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  color: var(--3---pastel-brown, #928e8c);
  text-align: center;
  font-size: 14px;
  font-weight: 450;
}

.linksDiv {
  margin-top: 30px;

  display: flex;
  align-items: center;
  justify-content: center;

  gap: 30px;
}
