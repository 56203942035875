.main {
  display: flex;
  flex-direction: column;

  gap: 15px;
}

.top {
  display: flex;
  align-items: center;

  gap: 5px;
}

.audienceText {
  color: var(--4---Beige, #e6e1df);
  font-size: 14px;
  font-weight: 450;
}

.audienceTextBold {
  font-weight: 700;
}

.bottom {
  display: flex;
  align-items: center;

  gap: 7px;
}

.wrapper {
  display: flex;
  align-items: center;

  gap: 3px;
}

.count {
  color: var(--3---pastel-brown, #928e8c);
  font-size: 12px;
  font-weight: 450;
  line-height: 120%;
}
