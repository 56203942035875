.main {
  width: 325px;
  height: 367px;
  margin-top: 18px;
  align-self: center;
  position: relative;
  display: flex;
  flex-direction: column;
}

.mainCompatibility {
  width: 325px;
  height: 367px;
  margin-top: 18px;
  align-self: center;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 50px;
}

.rowTop {
  display: flex;
  border-bottom: 1px solid #fffff257;
  height: 50%;
}

.rowBottom {
  display: flex;
  height: 50%;
}

.leftTop {
  border-right: 1px solid #fffff257;
  width: 50%;
  color: #fffff2;
  display: flex;
  align-items: flex-end;
}

.RightTop {
  width: 50%;
  color: #fffff2;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.leftBottom {
  border-right: 1px solid #fffff257;
  width: 50%;
}

.rightBottom {
  width: 50%;
}

.label {
  font-size: 13px;
  z-index: 2;
}

.cicle {
  position: absolute;
  left: 33%;
  top: 33%;
  border-radius: 50%;
  width: 109px;
  height: 109px;
  border: #fffff2 1px dashed;
  opacity: 0.2;
}

.imageDiv {
  position: absolute;
  width: 52px;
  height: 52px;
  border-radius: 50%;
}

.quadrantOne {
  position: absolute;
  font-size: 13px;
  z-index: 10;
  opacity: 0.5;
  top: 25%;
  left: 4%;
}

.quadrantOnePeer {
  position: absolute;
  top: 25%;
  left: 4%;

  color: var(--5---Cream-white, #fffff2);
  text-align: center;
  text-shadow: 0px 0px 8px #000;
  font-size: 10px;
  font-weight: 450;

  width: 120px;

  z-index: 10;
}

.quadrantTwo {
  position: absolute;
  font-size: 13px;
  z-index: 10;
  opacity: 0.5;
  top: 25%;
  right: 4%;
}

.quadrantTwoPeer {
  position: absolute;
  top: 25%;
  right: 4%;

  color: var(--5---Cream-white, #fffff2);
  text-align: center;
  text-shadow: 0px 0px 8px #000;
  font-size: 10px;
  font-weight: 450;

  width: 120px;

  z-index: 10;
}

.quadrantThree {
  position: absolute;
  font-size: 13px;
  z-index: 10;
  opacity: 0.5;
  bottom: 25%;
  right: 4%;
}

.quadrantThreePeer {
  position: absolute;
  bottom: 25%;
  right: 4%;

  color: var(--5---Cream-white, #fffff2);
  text-align: center;
  text-shadow: 0px 0px 8px #000;
  font-size: 10px;
  font-weight: 450;

  width: 120px;

  z-index: 10;
}

.quadrantFour {
  position: absolute;
  font-size: 13px;
  z-index: 10;
  opacity: 0.5;
  bottom: 25%;
  left: 4%;
}

.quadrantFourPeer {
  position: absolute;
  bottom: 25%;
  left: 4%;

  color: var(--5---Cream-white, #fffff2);
  text-align: center;
  text-shadow: 0px 0px 8px #000;
  font-size: 10px;
  font-weight: 450;

  width: 120px;

  z-index: 10;
}

.averageLabel {
  display: flex;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 20px;
  margin-left: 20px;
}

.averageCircle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #e7aebe;
}

.averageLabel p {
  margin-left: 10px;
  color: var(--4-beige, #e6e1df);
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.averageScore {
  position: absolute;
}

.crossLineOne {
  position: absolute;
  width: 463.862px;
  height: 1px;
  opacity: 0.3;
  border: 1px dashed #fffff2;
  transform: rotate(45deg);
  left: -75px;
  top: 176px;
}

.crossLineTwo {
  position: absolute;
  width: 463.862px;
  height: 1px;
  opacity: 0.3;
  border: 1px dashed #fffff2;
  transform: rotate(45deg);
  left: -75px;
  top: 176px;
  transform: rotate(135deg);
  left: -67px;
  top: 178px;
}

.octant0 {
  position: absolute;
  font-size: 16px;
  font-weight: 450;
  opacity: 0.5;
  color: var(--5---Cream-white, #fffff2);
  top: 116px;
  left: 26px;
}

.octant1 {
  position: absolute;
  font-size: 16px;
  font-weight: 450;
  opacity: 0.5;
  color: var(--5---Cream-white, #fffff2);
  top: 43px;
  left: 93px;
}

.octant2 {
  position: absolute;
  font-size: 16px;
  font-weight: 450;
  opacity: 0.5;
  color: var(--5---Cream-white, #fffff2);
  top: 43px;
  right: 93px;
}

.octant3 {
  position: absolute;
  font-size: 16px;
  font-weight: 450;
  opacity: 0.5;
  color: var(--5---Cream-white, #fffff2);
  top: 116px;
  right: 26px;
}

.octant4 {
  position: absolute;
  font-size: 16px;
  font-weight: 450;
  opacity: 0.5;
  color: var(--5---Cream-white, #fffff2);
  bottom: 116px;
  right: 26px;
}

.octant5 {
  position: absolute;
  font-size: 16px;
  font-weight: 450;
  opacity: 0.5;
  color: var(--5---Cream-white, #fffff2);
  bottom: 43px;
  right: 93px;
}

.octant6 {
  position: absolute;
  font-size: 16px;
  font-weight: 450;
  opacity: 0.5;
  bottom: 43px;
  left: 93px;
}

.octant7 {
  position: absolute;
  font-size: 16px;
  font-weight: 450;
  opacity: 0.5;
  bottom: 116px;
  left: 26px;
}

.peerPillImage {
  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;

  background: linear-gradient(90deg, #19896e 0.74%, #229d3d 100.74%);

  position: absolute;

  width: 30px;
  height: 30px;
}
