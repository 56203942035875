/* similarity */

.circleParent {
  display: flex;
  align-items: center;
  position: relative;
}

.leftCircle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid #e0cc65;
}

.rightCircle {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid #9d40ff;
  position: absolute;
  left: 8px;
}

.leftCircleNonSimilar {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid #e0cc65;
}

.rightCircleNonSimilar {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 1px solid #9d40ff;
  margin-left: 3px;
}
