.main {
  display: flex;
  flex-direction: column;
}

.heading {
  font-size: 26px;
  font-weight: 450;
  letter-spacing: -0.78px;
}

.wrapper {
  display: flex;
  align-items: center;

  margin-top: 15px;
  gap: 8px;

  overflow-x: scroll;
}

.block {
  border-radius: 7px;
  border: 1px solid var(--3---pastel-brown, #928e8c);

  min-width: 88px;
  max-width: 88px;
  min-height: 123px;

  display: flex;
  flex-direction: column;
  align-items: center;

  cursor: pointer;

  padding-top: 6px;
  padding-bottom: 8px;

  position: relative;

  overflow: hidden;
}

.topText {
  color: var(--3---pastel-brown, #928e8c);
  text-align: center;
  font-size: 11px;
  font-weight: 450;
  line-height: 110%;
  letter-spacing: -0.33px;
}

.imageDiv {
  position: relative;
  margin-top: 3px;
}

.bottomText {
  text-align: center;
  font-size: 11px;
  font-weight: 500;
  line-height: 110%;
  letter-spacing: -0.33px;

  margin-top: auto;
}

.moreBtn {
  align-self: flex-end;
}

.viewAllButtonDiv {
  display: none;
}

.wrapper::-webkit-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: none;
}

.shimmerDiv {
  border: 1px solid var(--3---pastel-brown, #928e8c);
  border-radius: 7px;

  min-width: 88px;
  max-width: 88px;
  min-height: 123px;

  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 10px;

  padding-top: 6px;
  padding-bottom: 8px;
}

.shimmerImage {
  width: 52px;
  height: 52px;
  min-width: 52px;
  min-height: 52px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;

  position: relative;
}

.shimmerLine {
  width: 42px;
  height: 12px;
  min-width: 42px;
  min-height: 12px;
}

@media only screen and (max-width: 768px) {
  .moreBtn {
    display: none;
  }

  .viewAllButtonDiv {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: flex-end;

    gap: 4px;
    margin-top: 15px;
  }

  .viewAllResultsText {
    color: rgba(255, 255, 242, 0.8);
    text-align: right;
    font-size: 13px;
    font-weight: 500;
  }
}
