.main {
  min-height: auto;
  width: 100%;
  height: 100px;
  position: absolute;
  left: 0;
  bottom: 0;
  color: white;
  border-top: 1px solid #464542;
  border-right: none;
  z-index: 100000;
  background-color: #181716;
}

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding: 0 15px;
}

.leftSide {
  display: flex;
  align-items: center;

  gap: 20px;
}

.column {
  width: fit-content;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 6px;
}

.columnCompare {
  width: fit-content;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 4px;

  padding-top: 3px;
}

.columnText {
  color: var(--4---Beige, #e6e1df);
  text-align: center;
  font-size: 12px;
  font-weight: 450;
  letter-spacing: -0.371px;
}

.shareDiv {
  display: flex;
  align-items: center;

  gap: 10px;
}
