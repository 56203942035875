.mobileBioLinksParent {
  display: none;
}

@media only screen and (max-width: 768px) {
  .mobileBioLinksParent {
    display: flex;
    flex-direction: column;
    padding: 10px 5% 0;
    margin-top: 10px;
    color: #fffff2;
    overflow: hidden;
  }

  .mobileSocialLinksContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }

  .imageAndBio {
    display: flex;
    align-items: center;
  }

  .bio {
    width: 100%;
    word-break: break-word;
  }

  .smallImageContainer {
    margin-right: 5%;
  }

  .smallImage {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid black;
  }
}
