.main {
  width: 100%;

  border-top: 1px solid var(--light-outline, #464542);
  background: var(--0---carbon-brown, #181716);

  padding: 9px 10px 39px 10px;

  position: relative;
}

.textArea {
  width: 100%;
  min-height: 18px;

  padding: 12px 17px;
  padding-right: 53px;

  border-radius: 25px;
  border: 0;

  background: rgba(255, 255, 242, 0.17);

  color: #fffff2;
  font-size: 14px;
  font-weight: 450;
  font-family: Circularstd-Book;

  outline: none;
  resize: none;
}

.textAreaDisabled {
  width: 100%;
  min-height: 18px;

  padding: 12px 17px;
  padding-right: 53px;

  border-radius: 25px;
  border: 0;

  background: rgba(255, 255, 242, 0.17);

  color: #928e8c;
  font-size: 14px;
  font-weight: 450;
  font-family: Circularstd-Book;

  outline: none;
  resize: none;
}

.postButton {
  color: var(--vibrant-blue, #2cb0ff);
  font-size: 14px;
  font-weight: 450;

  position: absolute;
  right: 21px;
  top: 19px;
}

.replyingDiv {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 12px 25px;

  border-top: 1px solid var(--light-outline, #464542);
  background: var(--1---Dark-brown, #2d2927);
}

.replyingText {
  color: var(--3---pastel-brown, #928e8c);
  font-size: 13px;
  font-weight: 450;
}

.replyingCloseButton {
  margin-left: auto;
}
