.dialogue {
  position: fixed;
  left: 50%;
  top: 50%;
  width: 100%;
  height: 100%;
  z-index: 100000000000;
  background-color: rgba(0, 0, 0, 0.33);
}

.main {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100000000;
  color: #fffff2;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-width: 0;
  transition: all 0.3s;
}

.mainMiddle {
  position: fixed;
  top: 250px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100000000;
  color: #fffff2;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-width: 0;
  transition: all 0.3s;
}

.mainConnections {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100000000;
  color: #fffff2;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-width: 0;
  transition: all 0.3s;
}

.wrapper {
  height: 425px;
  width: 550px;
  border-radius: 8px;
  background-color: rgba(45, 41, 39, 1);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 25px;
}

.title {
  width: 100%;
  color: #fffff2;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  margin-top: 20px;
}

.titleRow {
  width: 100%;
  color: #fffff2;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  margin-top: 20px;
  padding-bottom: 50px;
  border-bottom: 1px #464542 solid;
}

.row {
  margin-top: 10px;
  width: 100%;
  color: #fffff2;
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 130%;
  padding-bottom: 10px;
  border-bottom: 1px #464542 solid;
}

.message {
  margin-top: 30px;
  width: 100%;
  color: #fffff2;
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 130%;
}

.acceptButton {
  margin-top: auto;
  width: 510px;
  height: 44px;
  border-radius: 7px;
  background: #ffffde;
  backdrop-filter: blur(5.436563491821289px);
  color: #181716;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  border: none;
}

.cancelButton {
  border: 0;
  width: 510px;
  height: 44px;
  background-color: transparent;
  margin-bottom: 40px;

  color: #fffff2;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  border: none;
}

.dismissButton {
  border: 0;
  width: 510px;
  height: 44px;
  background: var(--subtle-yellow, #ffffde);
  margin-bottom: 40px;
  margin-top: auto;
  border-radius: 7px;

  color: var(--0-carbon-brown, #181716);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  border: none;
}

@media only screen and (max-width: 768px) {
  .main {
    top: auto;
    bottom: -212.5px;
    /* bottom: -400px; */
    left: 50%;
    width: 100%;
  }

  .mainMiddle {
    top: auto;
    bottom: -212.5px;
    left: 50%;
    width: 100%;
  }

  .mainConnections {
    top: auto;
    bottom: -212.5px;
    left: 50%;
    width: 100%;
  }

  .wrapper {
    height: 425px;
    width: 100%;
  }

  .acceptButton {
    width: 335px;
  }

  .cancelButton {
    width: 335px;
  }

  .dismissButton {
    width: 335px;
  }
}
