.main {
  width: 87.857px;
  height: 123px;

  border-radius: 7px;

  display: flex;
  flex-direction: column;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  padding-top: 5px;
  padding-bottom: 8px;
  padding-left: 3.7px;
  padding-right: 3.7px;
}

.username {
  color: var(--5---Cream-white, #fffff2);
  text-align: center;
  font-size: 11px;
  font-weight: 450;
  line-height: 110%;
  letter-spacing: -0.33px;
}

.imageDiv {
  margin-top: 28px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.storyName {
  color: var(--5---Cream-white, #fffff2);
  text-align: center;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
  font-size: 11px;
  font-weight: 500;
  letter-spacing: -0.33px;

  margin-top: auto;
}
