.archetypesMain {
  margin-top: 40px;
}

.archetypesParent {
  display: flex;
  flex-wrap: wrap;

  gap: 4px;

  width: 100%;

  padding-left: 5px;
}

.archInArchetypes {
  margin-top: 10px;
  margin-right: 6px;
  /* margin-bottom: 10px; */
}

.architypeBody {
  position: relative;
  min-width: 91px;
  width: 91px;
  height: 107px;
  border: #fffff2 1px solid;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transform: translateZ(0);
}

.architypeBodyApp {
  position: relative;
  min-width: 88px;
  width: 88px;
  height: 123px;
  border: #fffff2 1px solid;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transform: translateZ(0);
}

.architypeBodyUnknown {
  position: relative;
  width: 91px;
  min-width: 91px;
  height: 107px;
  border: #fffff2 1px solid;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  margin-right: 6px;
  /* margin-bottom: 10px; */
}

.architypeBodyUnknownApp {
  position: relative;
  min-width: 88px;
  width: 88px;
  height: 123px;
  border: #fffff2 1px solid;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.topText {
  font-size: 9px !important;
  margin-bottom: auto !important;
  color: #fffff2 !important;
  opacity: 0.5 !important;
  margin-top: 3px !important;
  letter-spacing: 0.11em !important;
}

.topTextUnknown {
  font-size: 9px !important;
  margin-bottom: auto;
  color: #fffff2;
  opacity: 0.5;
  margin-top: 3px;
  letter-spacing: 0.11em !important;
}

.archSVGUnknown {
  margin-top: 5px;
}

.archSVGUnknownApp {
  margin-top: 13px;
}

.bottomText {
  font-size: 10px !important;
  margin-top: auto !important;
  margin-bottom: 5px !important;
  text-align: center !important;
  line-height: 15.18px !important;
  font-weight: 350 !important;
  color: #fffff2;
}

.bottomTextApp {
  font-size: 12px !important;
  margin-top: auto !important;
  margin-bottom: 12px !important;
  text-align: center !important;
  line-height: 15.18px !important;
  font-weight: 350 !important;
  color: #fffff2;
}

.bottomTextUnknown {
  font-size: 10px !important;
  margin-top: 15px;
  font-weight: 350 !important;
}

.architypeBody img {
  justify-self: center;
  align-self: center;
}

.scoreDiv {
  position: absolute;
  bottom: 0;
  left: 0;
}

.archinarchetype {
  margin-top: 10px;
  margin-right: 6px;
  /* margin-bottom: 10px; */
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .archetypesParent {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 768px) {
  .architypeBody {
    min-width: 91px;
  }

  .topText {
    font-size: 9px !important;
    margin-bottom: auto !important;
  }

  .topTextUnknown {
    font-size: 9px;
    margin-bottom: 0;
  }

  .archSVGUnknown {
    margin-bottom: 2px;
  }

  .archCheckmarks p {
    margin-left: 2px;
  }

  .bottomText {
    font-size: 12px !important;
    margin-bottom: 10px !important;
    text-align: center !important;
  }

  .bottomTextUnknown {
    font-size: 12px;
    margin-bottom: 8px;
    text-align: center;
  }

  .architypeBody img {
    justify-self: center;
    align-self: center;
    filter: hue-rotate(0deg);
  }
}
