.textAreaDiv {
  position: relative;

  width: 100%;
  height: fit-content;
}

.textArea {
  width: 100%;

  outline: none;
  resize: none;

  border-radius: 25px;

  background: rgba(255, 255, 242, 0.17);

  padding: 12px 17px;
  padding-right: 50px;

  min-height: 18px;

  border: 0;

  font-family: Circularstd-Book;
  color: var(--5---Cream-white, #fffff2);
  font-size: 14px;
  font-weight: 450;
}

.sendButtonDiv {
  width: 30px;
  height: 30px;

  background-color: #fffff2;

  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.sendButtonParent {
  position: absolute;
  right: 10px;
  top: 6px;
}
