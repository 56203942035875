.suggestedMessagesSection {
  display: flex;
  flex-direction: column;

  margin-top: 20px;

  padding: 0 7.5px;

  margin-bottom: 150px;
}

.suggestedMessagesTitle {
  font-size: 26px;
  font-weight: 450;
  line-height: 140%;

  padding: 0 7.5px;
}

.mappedCategoriesWrapper {
  margin-top: 10px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 7.5px;
}

.category {
  color: var(--3---pastel-brown, #928e8c);
  font-size: 11px;
  font-weight: 700;
}

.mappedsuggestionsWrapper {
  margin-top: 32px;
  padding: 0 0.5px;

  display: flex;
  flex-direction: column;
}

.mappedsuggestionsDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;

  gap: 25px;

  padding: 20px 10px;

  border-bottom: 1px solid var(--light-outline, #464542);
}

.mappedsuggestionsText {
  color: var(--4---Beige, #e6e1df);
  font-size: 15px;
  font-weight: 450;
  line-height: 130%;
}
