.tabContainer {
  display: flex;
  color: grey;
  z-index: 1000;
  overflow-x: auto;
  padding-top: 10px;
}

.tabContainerProfile {
  display: flex;
  color: grey;
  z-index: 1000;
  overflow-x: auto;
  padding-top: 10px;
  padding-left: 5%;
  padding-right: 5%;
}

.tabContainer::-webkit-scrollbar {
  display: none;
  -webkit-appearance: none;
}

.tabContainer {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.tabContainerProfile::-webkit-scrollbar {
  display: none;
  -webkit-appearance: none;
}

.tabContainerProfile {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.tabChild {
  display: flex;
  justify-content: center;
  color: rgb(255, 255, 242, 0.3);
  border-bottom: solid 2px grey;
  width: 130px;
  text-align: center;
  padding: 2%;

  position: relative;
}

.tabChild h3 {
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 0.12em;
}

.tabActive {
  color: #fffff2;
  border-bottom-color: rgb(255, 255, 242, 1);
}

.tabButtonContainer {
  display: flex;
}

.tabButton {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  gap: 7px;
  width: 120px;
  height: 30px;
  background: #55504f;
  border-radius: 7px;
  margin-top: 20px;
  color: #e6e1df;
  font-size: 10px;
  line-height: 17px;
  letter-spacing: 0.11em;
}

.tabButtonLast {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px;
  gap: 7px;
  width: 120px;
  height: 30px;
  background: #55504f;
  border-radius: 7px;
  margin-left: auto;
  margin-top: 20px;
  color: #e6e1df;
  font-size: 10px;
  line-height: 17px;
  letter-spacing: 0.11em;
}

.blueDot {
  position: absolute;
  top: -6px;
  right: 20%;
}

@media only screen and (max-width: 768px) {
  .tabChild {
    min-width: 100px;
  }

  .tabChild h3 {
    font-size: 12px;
  }

  .tabContainerProfile {
    padding: 0;
    padding-top: 10px;
  }
}
