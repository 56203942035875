.wholeThread {
  display: flex;
  flex-direction: column;

  gap: 16px;

  width: 100%;

  overflow-x: hidden;
}

.whole {
  display: flex;
  align-items: flex-start;

  width: 100%;
}

.infoAndEditBoxWrapper {
  display: flex;
  align-items: center;
}

.wrapper {
  width: 100%;

  display: flex;
  flex-direction: column;

  gap: 16px;
}

.mainReplyDiv {
  display: flex;
  flex-direction: column;
  width: 100%;

  gap: 16px;
}

.main {
  display: flex;
  align-items: flex-start;

  gap: 8px;

  width: 100%;
}

.rightDiv {
  display: flex;
  flex-direction: column;

  gap: 3px;

  width: 100%;
}

.commentMetaDiv {
  display: flex;
  align-items: center;

  gap: 10px;
}

.commentMeta {
  color: var(--3---pastel-brown, #928e8c);
  font-size: 14px;
  font-weight: 450;
  line-height: 120%;
}

.commentBody {
  color: var(--4---Beige, #e6e1df);
  font-size: 14px;
  font-weight: 450;
  line-height: 120%;
}

.bottom {
  display: flex;
  align-items: center;

  width: 100%;

  margin-top: 4.5px;
}

.bottomRight {
  display: flex;
  align-items: center;

  gap: 15px;

  margin-left: auto;
}

.likeDiv {
  display: flex;
  align-items: center;

  gap: 5px;
}

.unlikeDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.replyText {
  color: var(--3---pastel-brown, #928e8c);
  font-size: 14px;
  font-weight: 500;
  line-height: 120%;
}

.repliesWrapper {
  padding-left: 50px;

  display: flex;
  flex-direction: column;

  gap: 16px;

  overflow-y: hidden;
}

.replyingUsername {
  color: var(--vibrant-blue, #2cb0ff);
  font-size: 14px;
  font-weight: 450;
  line-height: 120%;
}

.editBox {
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  background: var(--error-red, #b82c35);
}

.editBoxParent {
  width: 0;
  max-width: 50px;
  height: 100%;

  transition: width 0.5s, margin 0.5s;
}

.captionAuthorTag {
  border-radius: 4px;

  background: var(--3---pastel-brown, #928e8c);

  padding: 2px 4px;

  color: var(--5---Cream-white, #fffff2);
  font-size: 11px;
  font-weight: 450;
}
