.main {
  height: 430px;
  width: 100%;

  border-radius: 15px;
  border: 1px solid var(--light-outline, #464542);

  padding: 25px 15px 20px 15px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  text-align: center;
  font-size: 26px;
  font-weight: 450;
  letter-spacing: -0.26px;
}

.iconDiv {
  margin-top: 40px;
}

.midText {
  text-align: center;
  font-size: 16px;
  font-weight: 450;
  opacity: 0.5;

  margin-top: 40px;

  max-width: 325px;
}

.button {
  width: 100%;
  max-width: 325px;
  height: 44px;

  border: 0;
  border-radius: 7px;
  background: var(--5---Cream-white, #fffff2);
  backdrop-filter: blur(5.436563491821289px);

  margin-top: 20px;

  color: var(--0---carbon-brown, #181716);
  text-align: center;
  font-size: 16px;
  font-weight: 450;
}

.notNowBtn {
  width: 100%;
  max-width: 325px;
  height: 44px;

  background-color: transparent;
  border: 0;

  color: var(--5---Cream-white, #fffff2);
  text-align: center;
  font-size: 16px;
  font-weight: 450;

  margin-top: 9px;
}
