.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  flex: 1;

  gap: 20px;
}

.message {
}
