.main {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 15px;

  gap: 8px;
}

.mainbutton {
  min-width: 83px;
  height: 40px;
  border-radius: 9.156px;
  background: var(--5-cream-white, #fffff2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.mainConnected {
  min-width: 83px;
  height: 40px;
  border-radius: 9.156px;
  background: var(--5-cream-white, #fffff2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.declineButton {
  min-width: 83px;
  height: 40px;
  border-radius: 9px;
  background: var(--2---Chalk-Brown, #55504f);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 12px;

  color: var(--5---Cream-white, #fffff2);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.pending {
  min-width: 83px;
  height: 40px;
  border-radius: 9px;
  background: var(--3---pastel-brown, #928e8c);
  display: flex;
  align-items: center;
  justify-content: center;

  color: var(--2---Chalk-Brown, #55504f);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.text {
  color: #181716;
  text-align: center;
  font-size: 14.242px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.textConnected {
  color: #181716;
  text-align: center;
  font-size: 14.242px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 5px;
}

.rateBackground {
  border-radius: 9px;
  background: var(--5-cream-white, #fffff2);

  display: flex;
  align-items: center;

  gap: 4px;
  width: 99px;
  max-height: 40px;

  padding: 11px 13px;
}

.rateIconDiv {
  width: 26px;
  height: 26px;
}

.assess {
  color: var(--0---carbon-brown, #181716);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.messageButton {
  padding: 11px 13px;

  border: 0;
  border-radius: 9px;

  background: var(--5---Cream-white, #fffff2);

  color: var(--0---carbon-brown, #181716);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
