.main {
  width: 30px;
  height: 30px;

  border-radius: 9px;
  background: #77cba3;

  display: flex;
  align-items: center;
  justify-content: center;
}
