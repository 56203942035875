.main {
  width: 100%;
  margin-top: 50px;
  position: relative;
}

.row {
  margin-top: 30px;
}

.rowParent {
  display: flex;
  justify-content: space-between;
}

.rowInfo {
  display: flex;
  flex-direction: column;
}

.selected {
  width: fit-content;

  border-radius: 4px;
  background: var(--2---Chalk-Brown, #55504f);
  padding: 3px;

  color: var(--5---Cream-white, #fffff2);
  font-size: 16px;
  font-weight: 450;
  line-height: 130%;
}

.unselected {
  color: var(--5---Cream-white, #fffff2);
  font-size: 16px;
  font-weight: 450;
  line-height: 130%;
}

.description {
  color: var(--5---Cream-white, #fffff2);
  font-size: 11px;
  font-weight: 450;
  line-height: 130%;

  margin-top: 3.5px;

  max-width: 166px;

  opacity: 0.8;
}

.lineParent {
  display: flex;
  position: relative;

  margin-top: 11px;
}

.lineRight {
  height: 13px;
  width: 1px;
  background-color: #fffff257;
}

.lineMiddle {
  width: 99%;
  border-bottom: 1px #fffff257 solid;
  margin-bottom: 6.5px;
}

.lineLeft {
  height: 13px;
  width: 1px;
  background-color: #fffff257;
}

.midLine {
  position: absolute;
  height: 13px;
  width: 1px;
  background-color: #fffff257;
  left: 50%;
}

.imageDiv {
  position: absolute;
  width: 30px;
  height: 30px;
  bottom: -8px;
}

.circle {
  position: absolute;

  width: 21px;
  height: 21px;

  border-radius: 50%;
  background-color: #ffffde;
}

.circleGrey {
  position: absolute;

  width: 21px;
  height: 21px;

  border-radius: 50%;
  background-color: #928e8c;
}

.toolBarParent {
  margin-top: 10px;
  display: flex;
}

.filterTitle {
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  color: #fffff2;
  opacity: 0.5;
  margin-right: 17px;
}

.filterText {
  font-weight: 700;
  font-size: 11px;
  line-height: 16px;
  color: #fffff2;
  opacity: 0.5;
  margin-right: 15px;
}

.active {
  font-weight: 700;
  font-size: 11px;
  line-height: 16px;
  color: #fffff2;
  margin-right: 15px;
  opacity: 1;

  text-decoration: underline;
}

.toolTipDIv {
  position: absolute;
  z-index: 20000000;
  width: 300px;
}

.peerRatedImage {
  width: 30px;
  height: 30px;

  background: linear-gradient(90deg, #19896e 0.74%, #229d3d 100.74%);

  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;
}

@media only screen and (max-width: 768px) {
  .main {
    margin-top: 25px;
  }

  .toolTipDIv {
    width: 300px;
  }
}
