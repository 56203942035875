.patternParent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #fffff2;
  width: 91px;
  height: 107px;
  position: relative;
  overflow: hidden;
}

.patternParentNoBorder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 91px;
  height: 107px;
  position: relative;
  overflow: hidden;
}

.patternTop {
  padding-left: 5px;
  padding-right: 5px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.checkmarks {
  display: flex;
  align-items: center;
  position: absolute;
  top: 5px;
  right: 1px;
  color: grey;
  font-size: 10px;
  padding: 2px;
}

.patternMidContainer {
  display: flex;
  height: 47px;
  width: 47px;
  margin-left: 21px;
  margin-right: 21px;
  position: absolute;
  bottom: 35px;
  left: 4px;
}

.innerDiv {
  height: 100%;
  flex: 1 1 0px;
}

.bottomText {
  font-size: 12px;
  text-align: center;
  line-height: 15.18px;
  font-weight: 350;
}

.elementNameContainer {
  margin-bottom: 8px;
  margin-top: auto;
  height: 30px;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.premiumIcon {
  position: absolute;
  top: 49px;
  left: 27.7px;
  z-index: 1000;
}

@media only screen and (max-width: 768px) {
}
