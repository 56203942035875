.main {
  display: flex;
  flex-direction: column;

  position: fixed;

  width: 100%;
  height: 100%;

  z-index: 10000000;

  border-radius: 30px 30px 0px 0px;

  background: #2d2927;

  color: var(--5---Cream-white, #fffff2);

  margin-top: 100vh;

  overflow-x: hidden;
  overflow-y: auto;

  transition: margin 0.5s;
}

.header {
  height: 30px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.line {
  width: 43px;
  height: 4px;

  border-radius: 4px;

  background-color: #555;
}
