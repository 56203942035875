.leftBar {
  width: 280px;
}

.leftBarModal {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px;
}

.defDiv {
  margin-top: 50px;
}

.defTitleDiv {
  display: inline-block;
  border-bottom: 2px solid #fffff2;
  padding-bottom: 2px;
}

.defDiv h3 {
  font-size: 16px;
  line-height: 110%;
  color: #fffff2;
  font-family: Circularstd-Book;
  font-weight: 350;
}

.defDiv p {
  margin-top: 15px;
  font-family: Circularstd-Book;
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 110%;
  color: #fffff2;
  opacity: 0.8;
}

.buttonDiv {
  margin-top: 5px;
}

.scoreDiv {
  margin-top: 40px;
}

.premiumDeniedDiv {
  margin-top: 15px;
}

/* modal */

.defDivModal {
  margin-top: 0;
  color: var(--35-text, #c0b9b7);
}

.defDivModal h3 {
  display: none;
}

.defDivModal p {
  text-align: center;
  width: 345px;
  line-height: 130%;
  padding: 0 10px;
  margin-top: 0;
}

.availability {
  width: 100%;
  align-self: flex-start;
  margin-top: 50px;
}

@media only screen and (max-width: 768px) {
  .leftBar {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .defDiv {
    margin-top: 0;
  }

  .defTitleDiv {
    display: none;
  }

  .defDiv p {
    text-align: center;
    padding: 0 10px;
    margin-top: 0;
  }

  .availability {
    padding: 0 10px;
  }

  .avScoreDiv {
    width: 345px;
  }

  .buttonDiv {
    width: 345px;
  }

  .scoreDiv {
    margin-top: 18px;
    width: 100%;
  }

  .premiumDeniedDiv {
    padding: 0 10px;
  }
}
