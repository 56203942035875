.connectionIconModal {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #fffff2;
}

.requiredDivModal {
  display: flex;
  align-items: center;
}

.requiredDivModalText {
  font-size: 12px;
  font-weight: 450;
  opacity: 0.8;
  margin-left: 8px;
}
