.parent h2 {
  font-size: 20px;
}

.histogramParent {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 50px;
  transform: rotate(180deg);
  height: 240px;
  position: relative;
}

.histogramParentModal {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-top: 50px;
  transform: rotate(180deg);
  height: 240px;
  position: relative;
  align-self: center;
  width: 100%;
  gap: 24px;
  padding: 0 5px;
}

.histogramBar {
  width: 20.17px;
  background: #ffffde;
  border-radius: 24px;
  margin-left: 40px;
  max-height: 100%;
}

.histogramBarModal {
  background: #ffffde;
  border-radius: 24px;
  width: 12px;
}

.histogramBarCompare {
  min-width: 18px;
  background: #ffffde;
  border-radius: 24px;
  margin-left: 20px;
  max-height: 100%;
}

.labels {
  display: flex;
  justify-content: space-between;
  padding-right: 40px;
  margin-top: 10px;
  font-family: "Circularstd-Book";
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #928e8c;
  mix-blend-mode: normal;
}

.subTitleDiv {
  display: flex;
  align-items: center;
  color: rgba(255, 255, 242, 0.8);
  font-size: 13px;
  font-weight: 500;
  font-family: AkkuratStd;
}

.avScoreLabelCircle {
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #7886ff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  margin-right: 10px;
}

.averageScoreDiv {
  width: 2px;
  position: absolute;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000;
  bottom: 0;
}

.averageScoreCircle {
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: #7886ff;
}

.connectionAverageScoreCircle {
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: #f493ac;
}

.averageScoreLine {
  width: 2px;
  background-color: #7886ff;
  height: 210px;
}

.connectionAverageScoreLine {
  width: 2px;
  background-color: #f493ac;
  height: 210px;
}

.userScoreLine {
  width: 2px;
  height: 230px;
}

.userScoreCircle {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.userScoreCircle img {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  transform: rotate(180deg);
  border: 1px solid black;
}

@media only screen and (max-width: 768px) {
  .histogramParent {
    justify-content: space-between;
  }

  .histogramParentModal {
    gap: 20px;
  }

  .histogramBar {
    margin-left: 0;
    width: 14px;
  }

  .histogramBarCompare {
    margin-left: 0;
    width: 14px;
  }

  .labels {
    padding-right: 0;
  }
}
