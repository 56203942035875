.parent {
  margin-top: 40px;
  width: 100%;
  padding: 0 15px;
  padding-bottom: 15px;
  position: relative;
}

.requiredTitle {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: 0.11em;
  color: #fffff2;
}

.row {
  height: 70px;
  width: 100%;
  border: 0.5px solid #464542;
  display: flex;
  align-items: center;
}

.iconDiv {
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 14px;
  margin-right: 12px;
}

.row h2 {
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  color: #fffff2;
}

.row p {
  font-weight: 450;
  font-size: 12px;
  line-height: 15px;
  color: #fffff2;
  margin-top: 3px;
}

.inactive {
  opacity: 0.5;
}

.otherParent {
  margin-top: 53px;
}

.buttonDivActive {
  padding: 11px 15px;
  height: 40px;
  background: #fffff2;
  border-radius: 9px;

  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-left: auto;
  margin-right: 15px;
  border: 0;
  color: #181716;
}

.buttonDiv {
  padding: 11px 15px;
  height: 40px;
  background: #55504f;
  border-radius: 9px;

  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-left: auto;
  margin-right: 15px;
  border: 0;
  color: #fffff2;
}

.status {
  margin-top: 55px;
  margin-bottom: 90px;
  color: #fffff2;
}

.statusHeader {
  height: 45px;
  width: 100%;
  background-color: #fffff2;
  display: flex;
  align-items: center;
}

.statusHeader h1 {
  font-weight: 450;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: -0.01em;
  color: #181716;
  margin-left: 10px;
}

.statusIconDiv {
  margin-left: 8px;
  width: 26px;
  height: 26px;
}

.row h3 {
  margin-left: 6px;
  font-size: 13px;
  line-height: 17px;
  color: #fffff2;
}

.row p {
  margin-left: auto;
  margin-right: 15px;
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
}

.heading {
  font-family: Circularstd-Book;
  font-weight: 450;
  font-size: 26px;
  line-height: 33px;
  letter-spacing: -0.01em;
  color: #fffff2;
}

.subHeading {
  font-weight: 450;
  font-size: 13px;
  line-height: 16px;
  color: rgba(255, 255, 242, 0.5);
  margin-top: 5px;
}

.checkParentDiv {
  margin-left: auto;
  margin-right: 25px;
}

.checkParent {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9px;
}

.tableHeader {
  height: 45px;
  padding: 10px;
  background: #fffff2;
  font-weight: 450;
  font-size: 20px;
  line-height: 25px;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: #181716;
  margin-top: 35px;
}

.closeButton {
  position: absolute;
  right: 15px;
  top: -16px;
  z-index: 12;
}

.gatedParent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.soonIcon {
  width: 77px;
  height: 77px;
  opacity: 0.5;
}

.soonText {
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 19px;
  margin-top: 10px;
}

.retrtyButton {
  margin-top: 40px;
  border-radius: 9px;
  background: var(--5-cream-white, #fffff2);
  width: 62px;
  height: 40px;
  border: 0;

  color: var(--0-carbon-brown, #181716);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  font-family: Circularstd-Book;
}

.friendSignupWrapper {
  border: 1px solid var(--light-outline, #464542);
  padding: 0 10px;
  padding-bottom: 12px;
}

.friendSignupText {
  color: var(--3-pastel-brown, #928e8c);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 130%; /* 20.8px */
  letter-spacing: -0.32px;
  margin-top: 20px;
  padding: 0 20px;
}

.imageParent {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}

.imageDiv {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-left: 20px;
  margin-bottom: 2px;
}

.emptyImageDiv {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 3px solid #55504f;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 20px;
}

.image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.inviteButtonDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.inviteButton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  border-radius: 9px;
  background: var(--5-cream-white, #fffff2);
  width: 106px;
  height: 40px;

  color: var(--0-carbon-brown, #181716);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  font-family: Circularstd-Book;
  line-height: normal;
  border: 0;
}

.friendSignupWarning {
  color: var(--3-pastel-brown, #928e8c);
  text-align: center;
  font-size: 11px;
  font-style: normal;
  font-weight: 450;
  line-height: 130%; /* 14.3px */
  letter-spacing: -0.22px;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0 20px;
}

.checkmarkCompleteDiv {
  margin-left: auto;
}

.peerRatingWrapper {
  padding: 11px 19px;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--light-outline, #464542);
}

.peerRatingText {
  color: var(--3-pastel-brown, #928e8c);
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 130%; /* 20.8px */
  letter-spacing: -0.32px;
}

.scoreBar {
  position: relative;
  width: 100%;
  height: 6px;
  border-radius: 10px;
  background: #928e8c3d;
  margin-top: 14px;
}

.scoreBarInner {
  position: absolute;
  left: 0;
  top: 0;
  height: 6px;
  border-radius: 10px;
  background: #fffff2;
}

.peerRatingCount {
  margin-top: 10px;
  font-size: 26px;
  font-weight: 450;
  opacity: 0.5;
}

.peerRatingButton {
  border: 0;
  margin-top: 21px;
  padding: 11px 13px;
  border-radius: 9px;
  background: var(--5---Cream-white, #fffff2);
  align-self: center;
  height: 40px;

  color: var(--0---carbon-brown, #181716);
  text-align: center;
  font-size: 14px;
  font-weight: 700;
}

/* premium invite gate */

.premiumDiv {
  margin-top: 15px;

  width: 100%;
  height: 214px;

  display: flex;
  flex-direction: column;
  align-items: center;

  border-radius: 20px;
  background: var(--2---Chalk-Brown, #55504f);

  padding: 35px 0;
}

.premiumTopText {
  color: var(--3---pastel-brown, #928e8c);
  text-align: center;
  font-size: 16px;
  font-weight: 450;
  line-height: 130%;
  letter-spacing: -0.32px;
}

.premiumBottomText {
  text-align: center;
  font-size: 23.482px;
  font-weight: 500;
  letter-spacing: -0.704px;

  margin-top: 7px;
}

.plus {
  background: linear-gradient(90deg, #8bdcff 0%, #6978ff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.premiumButton {
  padding: 11px 13px;

  border: 0;
  border-radius: 9px;
  background: var(--5---Cream-white, #fffff2);

  margin-top: auto;

  color: var(--0---carbon-brown, #181716);
  text-align: center;
  font-size: 14px;
  font-weight: 700;
}

/* peer assessment gating */
.peerAssessmentTopDiv {
  display: flex;
  align-items: center;

  gap: 10px;

  margin-top: 10px;
}

.peerTopText {
  color: var(--35-text, #c0b9b7);
  font-size: 14px;
  font-weight: 450;
}

.peerButton {
  border: 0;
  border-radius: 9px;

  padding: 11px 13px;

  background: var(--5---Cream-white, #fffff2);

  color: var(--0---carbon-brown, #181716);
  text-align: center;
  font-size: 14px;
  font-weight: 700;

  margin-top: 38px;
}

@media only screen and (max-width: 768px) {
  .parent {
    margin-left: 0;
    margin-top: 29px;
    padding: 0 10px;
    width: 100%;
  }

  .table {
    width: 100%;
    margin-top: 30px;
  }

  .row {
    height: 70px;
    width: 100%;
    border: 1px solid #fffff2;
    display: flex;
    align-items: center;
  }

  .iconDiv {
    width: 30px;
    height: 30px;
    margin-right: 20px;
  }

  .otherParent {
    margin-top: 75px;
  }

  .buttonDivActive {
    width: auto;
    height: 46px;
    background: #fffff2;
    border-radius: 9px;

    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-left: auto;
    margin-right: 15px;
    border: 0;
    color: #181716;
  }

  .buttonDiv {
    width: auto;
    height: 46px;
    background: #55504f;
    border-radius: 9px;

    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    margin-left: auto;
    margin-right: 15px;
    border: 0;
    color: #fffff2;
  }

  .closeButton {
    top: -6px;
  }
}
