.analysisWrapper {
  display: flex;
  align-items: center;
  overflow-x: auto;
  flex-wrap: wrap;
  max-width: 550px;
  color: #fffff2;
  gap: 10px;
  overflow-y: hidden;
}

.reportBoxResults {
  display: flex;
  flex-direction: column;
  align-items: center;

  min-width: 88px;
  width: 88px;
  height: 123px;

  padding-bottom: 11px;

  background-position: center;
  background-size: cover;

  border-radius: 7px;
  border: 1px solid var(--3---pastel-brown, #928e8c);
}

.reportBoxResultsTab {
  position: relative;

  display: flex;
  flex-direction: column;

  /* width: 180px; */
  height: 252px;

  padding-bottom: 29px;
  padding-left: 16px;

  background-position: center;
  background-size: cover;

  border-radius: 5.683px;
}

.titleResults {
  margin-top: auto;

  color: var(--5---Cream-white, #fffff2);
  text-align: center;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
  font-size: 11px;
  font-weight: 500;
  letter-spacing: -0.33px;
}

.titleResultsTab {
  color: var(--5---Cream-white, #fffff2);
  font-size: 22px;
  font-weight: 450;
  line-height: 100%;
  letter-spacing: -0.66px;

  margin-top: auto;
}

.subTitleResultsTab {
  color: var(--5---Cream-white, #fffff2);
  font-size: 13px;
  font-weight: 450;
  line-height: 115%;

  max-width: 150.135px;

  margin-top: 6.46px;
}

.premiumIcon {
  position: absolute;

  top: 18px;
  left: 16px;
}
