.main {
  margin-top: 40px;
  padding: 0 20px;
}

.header {
  font-size: 20px;
  font-weight: 450;
  line-height: 130%;
  margin-bottom: 10px;
}

.desc {
  color: var(--35-text, #c0b9b7);
  font-size: 16px;
  font-weight: 450;
  line-height: 130%;
  margin-bottom: 20px;
}
