.main {
  width: 114px;
  height: 114px;
  border-radius: 7px;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 12px;
  backdrop-filter: blur(10px);
  background-size: cover;
  background-position: center;
  position: relative;
  flex-shrink: 0;
}

.blackedOut {
  filter: grayscale(100%);
}

.main h1 {
  font-family: Circularstd-Book;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 15px;
  letter-spacing: -1px;
  color: #101010;
  width: 100%;

  margin-top: 15px;
}

.readmoreicon {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.invitePill {
  position: absolute;
  top: 3px;
  left: 3px;
  z-index: 100;
}

@media only screen and (max-width: 768px) {
}
