.main {
  height: 100vh;
  height: 100dvh;
  background-color: #181716;
}

.wrapper {
  width: 100%;
  height: 100%;
}

.top {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 20px;
}

.iconDiv {
  margin-top: 40px;
  display: flex;
  align-items: center;

  gap: 10px;
}

.analysisIconDiv {
  width: 80px;
  height: 80px;

  display: flex;
  align-items: center;
  justify-content: center;

  border: solid 4.6px #fffff2;
  border-radius: 50%;
}

.premiumIconDiv {
  margin-top: 18px;
}

.title {
  font-size: 40px;
  font-style: normal;
  font-weight: 450;
  line-height: 110%;
  font-size: 26px;
  letter-spacing: -0.72px;

  margin-top: 10px;

  align-self: center;
  text-align: center;
}

.subtitle {
  color: var(--35-text, #c0b9b7);
  font-size: 16px;
  font-weight: 450;
  line-height: 130%;

  margin-top: 10px;

  align-self: center;
  text-align: center;
}

.body {
  display: flex;
  flex-direction: column;
}

.container {
  padding: 0 15px;

  margin-top: 60px;
}

.traitsContainer {
  padding: 0 15px;
  margin-top: 50px;
}

.container p {
  margin-top: 20px;
  color: #fffff2c9;
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  white-space: pre-wrap;
}

.container h3 {
  margin-top: 20px;
  color: #fffff2;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
}

.container ol {
  padding: 0 25px;
  margin-top: 10px;
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  color: #fffff2c9;
}

.container em {
  color: #fffff2;
}

.container a {
  color: var(--vibrant-blue, #2cb0ff);
}

.sectionTitle {
  font-size: 22px !important;
  font-style: normal !important;
  font-weight: 450 !important;
  line-height: normal !important;
  text-decoration-line: underline !important;

  margin-top: 0 !important;
}

.paragraph {
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  margin-top: 20px;
}

.chartContainer {
  margin-top: 50px;
  padding: 0 5px;
}

.chartDiv {
  border: 1px solid var(--5-cream-white, #fffff2);
  display: flex;
  flex-direction: column;
}

.chartHeader {
  width: 100%;
  height: 45px;
  background: #fffff2;
  display: flex;
  align-items: center;
}

.chartTitle {
  color: #181716;
  font-size: 22px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  letter-spacing: -0.22px;
  margin-left: 10px;
}

.feedbackContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 68px;
  margin-bottom: 50px;
}

.twoByTwoDiv {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 21px 20px;
}

.closeBtn {
  position: fixed;
  right: 20px;
  top: 50px;
}
