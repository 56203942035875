.parent {
  margin-top: 50px;
}

.titleDiv {
  display: flex;
  align-items: center;

  gap: 8px;

  margin-left: -5px;
}

.title {
  color: var(--5-cream-white, #fffff2);
  font-size: 19px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
}

.desc {
  color: var(--5---Cream-white, #fffff2);
  font-size: 14px;
  font-weight: 450;
  line-height: 120%;

  opacity: 0.7;

  margin-top: 5px;
  margin-left: -5px;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;

  gap: 7px;

  margin-left: -15px;
  margin-top: 13px;
}

.elementName {
  color: var(--5---Cream-white, #fffff2);
  font-size: 14px;
  font-weight: 450;
  letter-spacing: -0.14px;

  padding: 8px;

  border-radius: 4px;
  border: 2px solid grey;

  background: var(--2---Chalk-Brown, #55504f);
}

.ghostboxParent {
  display: flex;
  align-items: center;

  padding: 15px 10px;

  margin-top: 13px;

  width: 100%;

  border-radius: 7px;
  border: 1px dashed rgba(255, 255, 242, 0.2);

  margin-left: -5px;
}

.iconDiv {
  width: 40px;
  height: 40px;

  padding: 8.679px 7.333px 9px 7.369px;
}

.ghostBoxText {
  color: var(--5---Cream-white, #fffff2);
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;

  margin-left: 10px;

  opacity: 0.8;
}

.ghostBoxButton {
  border: 0;
  border-radius: 9px;

  background: var(--5---Cream-white, #fffff2);

  padding: 11px 13px;

  margin-left: 15px;

  color: var(--0---carbon-brown, #181716);
  text-align: center;
  font-size: 14px;
  font-weight: 700;
}
