.socialImageContainer {
  display: flex;
  margin: 15px 0;

  cursor: pointer;
}

.socialLinksContainerProfiles {
  display: flex;
  align-items: center;
  gap: 22px;
}

.socialLinksContainerProfiles p {
  display: none;
}

.socialImageContainerNoURL {
  display: none;
}

.socialImage {
  margin-right: 10px;
}

@media only screen and (max-width: 768px) {
  .socialLinksContainer {
    display: none;
  }

  .socialImageContainer p {
    display: none;
  }

  .socialImage {
    margin-right: 22px;
  }
}
