.elementContainer {
  display: flex;
  flex-direction: column;
  margin: 5px;
  border: 1px solid #fffff2;
  align-items: center;
  width: 172px;
  height: 203px;
  text-align: center;
  overflow: hidden;
}

.elementTop {
  padding-left: 5px;
  padding-right: 5px;
  height: 34px;
  width: 100%;
}

.iconDiv {
  width: 25px;
  height: 25px;
  margin-right: auto;
  margin: 8px;
}

.iconDiv p {
  display: none;
}

.mainCircle {
  width: 89px;
  height: 89px;
  border-radius: 50%;
  position: relative;
}

.midCircle {
  background-color: #181716;
  width: 23px;
  height: 23px;
  border-radius: 50%;
  position: absolute;
  top: 37%;
  right: 37%;
}

.premiumIcon {
  position: absolute;
  bottom: -7px;
  left: 26px;
}

.elementNameContainer {
  margin-top: 18px;
  height: 47px;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.elementNameContainer p {
  font-size: 20px;
  line-height: 25px;
  color: #fffff2;
}

/* modal */

.elementContainerModal {
  display: flex;
  flex-direction: column;
  margin: 5px;
  align-items: center;
  width: 100%;
  height: auto;
  text-align: center;
  overflow: hidden;
  position: relative;
}

.elementTopModal {
  padding-left: 5px;
  padding-right: 5px;

  height: auto;

  display: flex;
  flex-direction: column;
  align-items: center;

  position: absolute;
  top: -2px;
  left: 7px;

  font-size: 10px;
  line-height: 13px;
  text-align: center;
  color: #fffff2;
}

.iconDivModal {
  width: 32px;
  height: 32px;
  margin: 8px;
}

.iconDivModal p {
  display: flex;
}

.mainCircleModal {
  width: 132px;
  height: 132px;
  border-radius: 50%;
  position: relative;
}

.midCircleModal {
  background-color: #181716;
  width: 37px;
  height: 37px;
  border-radius: 50%;
  position: absolute;
  top: 36%;
  right: 36%;
}

.premiumIconModal {
  position: absolute;
  bottom: -7px;
  left: 50px;
}

@media only screen and (max-width: 768px) {
  .elementContainer {
    margin: 5px;
    border: none;
    width: 100%;
    height: auto;
    position: relative;
  }

  .mainCircle {
    width: 132px;
    height: 132px;
    border-radius: 50%;
    position: relative;
  }

  .midCircle {
    width: 37px;
    height: 37px;
    top: 36%;
    right: 36%;
  }

  .premiumIcon {
    left: 48px;
  }

  .elementTop {
    position: absolute;
    top: -2px;
    left: 7px;
  }

  .iconDiv {
    width: 32px;
    height: 32px;
    margin-right: auto;
  }

  .iconDiv p {
    display: flex;
    font-size: 10px;
    line-height: 13px;
    text-align: center;
    color: #fffff2;
  }

  .elementNameContainer p {
    font-size: 26px;
    letter-spacing: -0.03em;
  }
}
