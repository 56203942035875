.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: fit-content;
  height: fit-content;
}

.wrapperReversed {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: fit-content;
  height: fit-content;

  transform: rotate(180deg);
}

.mediumArror {
  width: 11px;
  height: 2px;

  background-color: #fffff2;
}
