.rewardsTabDiv {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 6.53px 10.33px;
  gap: 5px;

  border-radius: 8px;
  background: linear-gradient(270deg, #bf7ae1 0%, #6962cf 100%);

  cursor: pointer;
}

.rewardsTabCount {
  color: var(--5---Cream-white, #fffff2);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -0.48px;
}

.rewardsBarMain {
  border-radius: 8px;
  background: linear-gradient(270deg, #bf7ae1 0%, #6962cf 100%);
  padding: 10px 15px;

  display: flex;
  flex-direction: column;

  cursor: pointer;
}

.rewardsLevelParent {
  margin-top: 11px;
}

.rewardLevelTitle {
  font-size: 16px;
  font-weight: 500;
}

.scoreBarWrapper {
  display: flex;
  align-items: center;
  gap: 7px;
}

.scoreBarActive {
  flex-grow: 1;
  height: 10px;

  border-radius: 3.591px;
  background: var(--subtle-yellow, #ffffde);
}

.scoreBarInactive {
  flex-grow: 1;
  height: 10px;

  border-radius: 3.591px;
  background: var(--subtle-yellow, #ffffde);

  opacity: 0.2;
}

.rewardsMidDiv {
  display: flex;
  align-items: center;

  gap: 6px;

  margin-top: 10px;
}

.rewardsMidDivText {
  font-size: 9px;
  font-weight: 500;
  letter-spacing: 0.99px;
}

.rewardsBottomButton {
  border: 0;
  border-radius: 4px;
  background: var(--subtle-yellow, #ffffde);

  padding: 5px 7px;

  width: fit-content;

  align-self: flex-end;

  margin-top: 5px;

  color: var(--1---Dark-brown, #2d2927);
  text-align: center;
  font-size: 14px;
  font-weight: 450;
}

/* modal */

.modalMain {
  padding: 15px 10px;
}

.imageWrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;

  overflow-x: auto;
  overflow-y: hidden;

  gap: 15px;

  margin-top: 16px;
}

.imageWrapper::-webkit-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: none;
}

.imageDiv {
  width: 53px;
  height: 53px;
  border-radius: 50%;
  overflow: visible;
}

.emptyImageDiv {
  width: 52px;
  height: 52px;
  min-width: 52px;
  min-height: 52px;
  border-radius: 50%;
  border: 3px solid #fffff2;
  display: flex;
  align-items: center;
  justify-content: center;

  opacity: 0.5;
}

.image {
  width: 52px;
  height: 52px;
  border-radius: 50%;
}

.modalInviteLinkButton {
  width: fit-content;

  align-self: flex-end;

  border: 0;
  border-radius: 9px;
  background: var(--5---Cream-white, #fffff2);

  padding: 11px 13px;
  margin-top: 30px;

  color: var(--0---carbon-brown, #181716);
  text-align: center;
  font-size: 14px;
  font-weight: 700;
}

.modalInfoText {
  color: var(--3---pastel-brown, #928e8c);
  font-size: 12px;
  font-weight: 450;
  margin-top: 10px;
}

.modalTitle {
  font-size: 26px;
  font-weight: 450;
  letter-spacing: -0.52px;
  margin-top: 40px;
}

.rewardsLevelInfo {
  color: var(--35-text, #c0b9b7);
  font-size: 14px;
  font-weight: 450;

  margin-top: 5px;
}

.levelHeaderWrapper {
  display: flex;
  align-items: center;

  gap: 10px;
  margin-top: 32px;
}

.levelHeading {
  font-size: 20px;
  font-weight: 500;
}

.checkmarDiv {
  width: 22px;
  height: 22px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 6px;
  background: var(--5---Cream-white, #464542);

  padding: 5px;
}

.levelWrapper {
  display: flex;
  align-items: center;

  gap: 10px;
  margin-top: 10px;

  overflow-x: auto;
}

.levelWrapper::-webkit-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
  display: none;
}
