.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 50%;
  top: 50%;
  height: 100vh;
  height: 100dvh;
  width: 100%;

  z-index: 1000000;
  background-color: #18171600;
  overflow: hidden;
}

.parent {
  display: flex;
  flex-direction: column;

  overflow-x: hidden;

  position: relative;

  padding: 0;
  margin: 0;

  width: 100vw;
  height: 100%;

  top: 0;

  overflow-y: hidden;
}

.parentLarge {
  width: 100vw;
  height: 100%;
  top: 0;
}

.wrapperSide {
  display: flex;
  flex-direction: column;

  width: 100vw;
  height: 100%;

  min-height: 100vh;
  min-height: 100dvh;

  color: #fffff2;

  overflow-x: hidden;

  border-radius: 12px;

  background-color: #181716;

  position: relative;

  overflow-y: hidden;

  transform: translateX(100%);
  transition: transform 0.2s linear;

  border-radius: 0;
}

.wrapper {
  display: flex;
  flex-direction: column;

  width: 100vw;
  height: 100%;

  min-height: 100vh;
  min-height: 100dvh;

  color: #fffff2;

  overflow-x: hidden;

  background-color: #181716;

  position: relative;

  overflow-y: hidden;

  transform: translateY(100%);
  transition: transform 0.2s linear;
}

.wrapperNoModal {
  display: flex;
  flex-direction: column;

  width: 100vw;
  height: 100%;

  min-height: 95vh;
  min-height: 95dvh;

  border-radius: 0;

  color: #fffff2;

  overflow-x: hidden;
  overflow-y: hidden;

  background-color: #181716;

  position: relative;

  transform: translateY(100%);
  transition: transform 0.2s linear;
}

.wrapperLarge {
  width: 100vw;
  height: 100%;
  border-radius: 0;
}

.childrenWrapper {
  overflow-y: auto;
  overflow-x: hidden;

  display: flex;
  flex-direction: column;

  width: 100vw;
  height: 100vh;

  overscroll-behavior: contain;
}

.childrenWrapper::-webkit-scrollbar {
  background-color: inherit;
  width: 8px;
}

.childrenWrapper::-webkit-scrollbar-thumb {
  background-color: #55504f;
  border-radius: 5px;
}

.childrenWrapperLarge::-webkit-scrollbar {
  background-color: inherit;
  width: 8px;
}

.childrenWrapperLarge::-webkit-scrollbar-thumb {
  background-color: #55504f;
  border-radius: 5px;
}

@media only screen and (max-width: 768px) {
  .childrenWrapper::-webkit-scrollbar {
    display: none;
  }

  .childrenWrapper::-webkit-scrollbar-thumb {
    display: none;
  }

  .childrenWrapperLarge::-webkit-scrollbar {
    display: none;
  }

  .childrenWrapperLarge::-webkit-scrollbar-thumb {
    display: none;
  }
}
