.main {
  width: 345px;
  height: 214px;

  border-radius: 20px;
  border: 1px dashed #ccc;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.requires {
  color: var(--5---Cream-white, #fffff2);
  text-align: center;
  font-size: 16px;
  font-weight: 450;
  line-height: 130%;
  letter-spacing: -0.32px;

  margin-top: 35px;
}

.dividedTextDiv {
  display: flex;
  align-items: center;

  gap: 4px;

  margin-top: 7px;
}

.dimensionalText {
  color: var(--5---Cream-white, #fffff2);
  text-align: center;
  font-size: 23.482px;
  font-weight: 500;
  letter-spacing: -0.704px;
}

.plusText {
  font-size: 23.482px;
  font-weight: 500;
  letter-spacing: -0.704px;

  background: linear-gradient(90deg, #8bdcff 0%, #6978ff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.button {
  margin-top: 44px;

  padding: 11px 13px;

  border: 0;
  border-radius: 9px;

  background: var(--5---Cream-white, #fffff2);

  color: var(--0---carbon-brown, #181716);
  text-align: center;
  font-size: 14px;
  font-weight: 700;
}
