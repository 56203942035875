.connectionMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: fixed;
  top: 0;
  left: 0;

  z-index: 10001000000;

  width: 100%;
  height: 100%;

  overflow: auto;

  background-color: #00000080;
}

.connectionTopDiv {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  width: 450px;
  height: 50px;

  z-index: 1;

  background-color: #2d2927;
  color: #fffff2;

  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom: 1px #464542 solid;

  padding: 0 10px;
}

.connectionTopDiv h1 {
  font-size: 20px;
  margin-right: 30%;
  font-weight: 350;
}

.connectionCloseDiv {
  margin-right: 2px;

  width: 35px;
  height: 35px;
  background-color: #928e8c;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.connectionBody {
  width: 450px;
  height: 410px;

  background-color: #2d2927;
  color: #2d2927;

  border-radius: 5px;

  overflow-y: scroll;

  position: relative;

  padding: 0 0;
}

.connectionList {
  padding: 5% 0;
}

.ghostBoxParent {
  display: flex;
  justify-content: center;
}

.personDiv {
  display: flex;
  align-items: center;

  margin-bottom: 10px;

  padding: 1% 2%;
}

.connectionsImageContainer {
  position: relative;

  width: 50px;
  height: 50px;

  border-radius: 50%;

  overflow: hidden;
}

.smallImage {
  border-radius: 50%;
}

.bioAndUsername {
  margin-left: 10px;
}

.bioAndUsername h5 {
  font-size: 16px;
  color: #fffff2;
}

.bioAndUsername p {
  font-size: 12px;
  color: grey;
}

@media only screen and (max-width: 768px) {
  .connectionTopDiv {
    width: 350px;
    padding: 0 10px;
  }

  .connectionTopDiv h1 {
    font-size: 20px;
    margin-right: 25%;
  }

  .connectionCloseDiv {
    right: 2%;
  }

  .connectionBody {
    width: 350px;
    padding: 2% 0;
  }
}
