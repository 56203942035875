.verySmallImageContatiner {
  width: 27px;
  height: 27px;
  min-width: 27px;
  min-height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: relative;
}

.smallImageContainer {
  width: 45px;
  height: 45px;
  min-width: 45px;
  min-height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: relative;
}

.smallImageContainerTabBar {
  width: 42px;
  height: 42px;
  min-width: 42px;
  min-height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: relative;
}

.mediumImageContainer {
  width: 52px;
  height: 52px;
  min-width: 52px;
  min-height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: relative;
}

.largeImageContainer {
  width: 80px;
  height: 80px;
  min-width: 80px;
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: relative;
}

.veryLargeImageContainer {
  width: 164px;
  height: 164px;
  min-width: 164px;
  min-height: 164px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: relative;
}

.extremelyLargeImageContainer {
  width: 220px;
  height: 220px;
  min-width: 220px;
  min-height: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: relative;
}

.chatImageContainer {
  width: 34px;
  min-width: 34px;
  height: 34px;
  min-height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: relative;
}

.peerAssessmentContainer {
  width: 120px;
  height: 120px;
  min-width: 120px;
  min-height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  position: relative;
}

.verySmallImage {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  border: 1px solid transparent;
}

.smallImage {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid transparent;
}

.mediumImage {
  width: 47px;
  height: 47px;
  border-radius: 50%;
  border: 1px solid transparent;
}

.largeImage {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  border: 2px solid transparent;
}

.veryLargeImage {
  width: 147px;
  height: 147px;
  border-radius: 50%;
  border: 3px solid transparent;
}

.extremelyLargeImage {
  width: 203px;
  height: 203px;
  border-radius: 50%;
  border: 3px solid transparent;
}

.peerAssessmentImage {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 3px solid transparent;
}

.chatImage {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 2px solid transparent;
}

.iconDiv {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2.5px;
  border-radius: 50%;
  background-color: #fffff2;

  position: absolute;
  top: -6px;
  right: -8px;
}
