.snippetBlock {
  border-radius: 7px;
  border: 1px solid var(--3---pastel-brown, #928e8c);
  min-width: 88px;
  width: 88px;
  height: 123px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 25px;
  position: relative;
}

.snippetBlockGrey {
  border-radius: 7px;
  border: 1px solid var(--3---pastel-brown, #928e8c);
  min-width: 88px;
  width: 88px;
  height: 123px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 25px;
  opacity: 0.3;
  position: relative;
}

.premiumLogo {
  position: absolute;
  top: 5px;
  left: 5px;

  z-index: 100;
}

.emojiStringSnippetBlock {
  color: var(--5---Cream-white, #fffff2);
  text-align: center;
  font-size: 35px;
  font-weight: 500;
  line-height: 110%;
  letter-spacing: -1.05px;
}

.modalEmojiTitle {
  color: #fff;
  text-align: center;
  font-size: 36px;
  font-weight: 450;
  line-height: 130%; /* 46.8px */
}

.snippetName {
  color: #fffff2;
  text-align: center;
  font-size: 11px;
  font-weight: 500;
  line-height: 110%;
  letter-spacing: -0.33px;

  margin-top: auto;
  margin-bottom: 8px;
  padding: 0 2px;
}

.modalMain {
  position: relative;
  padding-bottom: 300px;
}

.modalWrapper {
  padding: 0 20px;
}

.premiumPill {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 15px;
}

.top {
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 35px;
}

.snippetIcon {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: 4.62px #fffff2 solid;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 11px;
}

.title {
  color: var(--5---Cream-white, #fffff2);
  font-size: 26px;
  font-style: normal;
  font-weight: 450;
  line-height: 130%; /* 33.8px */
  margin-top: 50px;

  display: inline-block;
}

.titleLarge {
  color: var(--5---Cream-white, #fffff2);
  font-size: 36px;
  font-style: normal;
  font-weight: 450;
  line-height: 130%; /* 46.8px */
  letter-spacing: -0.72px;
  margin-top: 50px;
}

.traitDiv {
  margin-top: 38px;
}

.labelDiv {
  display: flex;
  margin-top: 25px;
  align-items: center;
}

.averageCircle {
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: #7886ff;
}

.averageConnectionCircle {
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background-color: #f493ac;
}

.label {
  color: var(--35-text, #c0b9b7);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130%; /* 18.2px */
  margin-left: 9px;
  font-family: AkkuratStd;
}

.peopleDiv {
  margin-top: 40px;
}

.personDiv {
  display: flex;
  align-items: center;
  margin-bottom: 22px;
  width: 100%;
}

.nameDiv {
  margin-left: 9px;
}

.name {
  font-size: 16px;
  font-weight: 450;
}

.username {
  font-size: 14px;
  opacity: 0.4;
}

.scoreBar {
  margin-left: auto;
}

.retryButton {
  margin-top: 40px;
  border-radius: 9px;
  background: var(--5-cream-white, #fffff2);
  height: 40px;
  border: 0;
  padding: 11px 13px;

  color: var(--0-carbon-brown, #181716);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  font-family: Circularstd-Book;
}

.llmListName {
  color: var(--5---Cream-white, #fffff2);
  font-size: 50px;
  font-style: normal;
  font-weight: 450;
  line-height: 130%; /* 65px */
  letter-spacing: -1px;
  margin-top: 60px;
}

.llmListNamePeer {
  color: var(--5---Cream-white, #fffff2);
  font-size: 36px;
  font-style: normal;
  font-weight: 450;
  line-height: 130%; /* 65px */
  letter-spacing: -1px;
  margin-top: 60px;
}

.llmListSubtitle {
  color: var(--35-text, #c0b9b7);
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 130%; /* 20.8px */
}

.llmListDiv {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.llmListDivContent {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.tileNumber {
  color: var(--35-text, #c0b9b7);
  text-align: center;
  font-family: AkkuratStd;
  font-size: 16px;
  font-weight: 400;
  line-height: 130%;
}

.llmListTitle {
  text-align: center;
  font-size: 26px;
  font-weight: 450;
  line-height: 130%;
  margin-top: 16px;
}

.llmListTitleContent {
  font-size: 36px;
  font-weight: 450;
  line-height: 110%;
  letter-spacing: -0.72px;
}

.line {
  width: 100%;
  height: 2px;
  margin-top: 10px;
}

.llmListParagraph {
  color: var(--35-text, #c0b9b7);
  font-family: AkkuratStd;
  font-size: 16px;
  font-weight: 400;
  line-height: 130%;
  margin-top: 16px;
}

.llmListParagraphContent {
  color: var(--35-text, #c0b9b7);
  font-family: AkkuratStd;
  font-size: 16px;
  font-weight: 400;
  line-height: 130%;
  margin-top: 30px;
}

.ul {
  color: var(--35-text, #c0b9b7);
  font-family: AkkuratStd;
  font-size: 16px;
  font-weight: 400;
  line-height: 130%;
  margin-top: 30px;
  padding-left: 20px;
}

.llmMatchCard {
  border-radius: 7px;
  border: 1px solid var(--5---Cream-white, #fffff2);
  margin-top: 50px;
  padding: 40px 20px;
}

.why {
  color: var(--35-text, #c0b9b7);
  font-size: 16px;
  font-weight: 700;
  line-height: 130%;
  margin-top: 16px;
  text-decoration: underline;
}

.feedbackDiv {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;

  position: fixed;
  left: 50%;
  bottom: 18px;
  transform: translate(-50%, -50%);

  padding: 0 10px;
}

.rankingListParent {
  margin-top: 50px;
}

.rankingListTitle {
  background: var(--5---Cream-white, #fffff2);
  color: var(--0---carbon-brown, #181716);
  padding: 5px 10px;
  font-size: 26px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  letter-spacing: -0.26px;
}

.rankingListRow {
  border: 1px solid var(--light-outline, #464542);
  border-top: 0;
  display: flex;
  min-height: 42px;
}

.rankingListLeft {
  border-right: 1px solid var(--light-outline, #464542);
  color: var(--35-text, #c0b9b7);
  font-family: AkkuratStd;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
}

.rankingListRight {
  color: var(--35-text, #c0b9b7);
  font-family: AkkuratStd;
  font-size: 16px;
  line-height: 130%;
  display: flex;
  align-items: center;
  margin-left: 12px;
}

/* HeaderedList */

.headerdListDiv {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
}

.headeredListPart {
  font-size: 11px;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: 1.21px;
  font-family: AkkuratStd;
}

.headeredListHeader {
  font-size: 36px;
  font-weight: 450;
  line-height: 110%;
  letter-spacing: -0.72px;
  margin-top: 10px;
}

.lineHeader {
  width: 100%;
  height: 2px;
  margin-top: 10px;
}

.headeredLlmContentDiv {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.naturedStatementsDiv {
  margin-top: 60px;

  display: flex;
  flex-direction: column;

  gap: 10px;
}

.natureStatement {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  font-family: AkkuratStd;
}

.interactionBarDiv {
  position: fixed;
  bottom: -1px;
  z-index: 5000;
  width: 100%;
}

.spanWarning {
  position: sticky;

  bottom: 76px;
  left: 26%;

  color: #fffff2;

  background-color: #2c2a29;

  border-radius: 7px;

  width: 200px;
  height: 55px;

  display: flex;
  align-items: center;
  justify-content: center;

  transition: all 1s;

  z-index: 10;

  display: none;
  opacity: 0;
}

.stickyDiv {
  position: sticky;
  bottom: 10px;
  padding: 0 10px;

  display: none;
}

.llmHeaderAndBodyTitle {
  font-family: AkkuratStd;
  color: var(--5---Cream-white, #fffff2);
  font-size: 22px;
  font-weight: 400;
  line-height: 130%;

  margin-top: 60px;
}

/* spectrums */

.spectrumsDiv {
  margin-top: 50px;
}

.spectrumLegend {
  margin-top: 50px;

  display: flex;
  align-items: center;
  justify-content: center;

  gap: 8px;
}

.peerRatedImage {
  width: 30px;
  height: 30px;

  background: linear-gradient(90deg, #19896e 0.74%, #229d3d 100.74%);

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;
}

.legendText {
  font-size: 14px;
  font-weight: 450;
  line-height: 130%;
}

/* strenghts peer */

.topStrenthsText {
  margin-top: 50px;

  color: var(--5---Cream-white, #fffff2);
  font-size: 26px;
  font-weight: 450;
  line-height: 120%;
  letter-spacing: -0.52px;
}

.accordingTextDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: 18px;
}

.accordingText {
  font-size: 12px;
  font-weight: 700;
}

.mappedSectionsDiv {
  margin-top: 18px;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.topStrengthsMappedDiv {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.traitTop {
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 4px;

  padding: 8px;

  width: fit-content;

  background: var(--2---Chalk-Brown, #55504f);
}

.traitName {
  color: var(--5---Cream-white, #fffff2);
  font-size: 16px;
  font-weight: 450;
}

.traitNameSecondary {
  color: var(--5---Cream-white, #fffff2);
  font-size: 12px;
  font-weight: 450;
}

/* nature */
.accordingTextNature {
  font-size: 12px;
  font-weight: 700;

  margin-top: 50px;
}

.natureTitle {
  color: var(--5---Cream-white, #fffff2);
  font-size: 20px;
  font-weight: 700;
  letter-spacing: -0.6px;

  margin-top: 9px;

  padding: 4px 6px;

  border-radius: 4px;

  width: fit-content;
}

.natureDescription {
  color: var(--35-text, #c0b9b7);
  font-size: 12px;
  font-weight: 450;

  margin-top: 5px;

  width: 315px;
}

.natureImagesDiv {
  margin-top: 30px;

  display: flex;
  justify-content: space-between;
}

.selfNatureImage {
  margin-top: 82px;
}

.selfNatureDetailsDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  margin-top: 30px;
}

.natureLegendsDiv {
  margin-top: 50px;

  display: flex;
  flex-direction: column;

  gap: 5px;
}

.natureLegendsWrapper {
  display: flex;
  align-items: center;

  gap: 7px;
}

.peerRatedLegendIcon {
  width: 30px;
  height: 6px;
  border-radius: 14px;
  background: linear-gradient(90deg, #19896e 0.74%, #229d3d 100.74%), #fffff2;
}

.selfRatedLegendIcon {
  width: 30px;
  height: 6px;
  border-radius: 14px;
  background: var(--5---Cream-white, #fffff2);
}

.natureLegendText {
  color: var(--35-text, #c0b9b7);
  font-size: 14px;
  font-weight: 450;
  line-height: 130%;
}

.natureRowWrapper {
  display: flex;
  flex-direction: column;

  gap: 17px;

  margin-top: 25px;
}

.natureRow {
  display: flex;
  flex-direction: column;
}

.natureRowTopDiv {
  display: flex;
  align-items: center;
  gap: 7px;
}

.natureCircle {
  width: 16px;
  height: 16px;

  border-radius: 50%;
}

.natureTopRowName {
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;

  opacity: 0.7;
}

.natureScoreParent {
  margin-top: 7px;

  border-radius: 4px;
  background: #2f2929a8;

  width: 100%;
  height: 6px;

  position: relative;
}

.natureScorePeer {
  height: 6px;

  background: linear-gradient(90deg, #19896e 0.74%, #229d3d 100.74%), #fffff2;
  border-radius: 4px;

  position: absolute;
  left: 0;
  top: 0;
}

.natureScore {
  height: 6px;

  background: var(--5---Cream-white, #fffff2);
  border-radius: 4px;

  position: absolute;
  left: 0;
  top: 0;
}

/* trait delta */

.deltaTraitWrapper {
  margin-top: 30px;

  display: flex;
  flex-direction: column;
  gap: 30px;
}

.deltaRowDiv {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.deltatraitButton {
  border: 0;
  border-radius: 4px;

  padding: 6px 7px;

  background: var(--2---Chalk-Brown, #55504f);

  width: fit-content;

  color: var(--5---Cream-white, #fffff2);
  font-size: 12px;
  font-weight: 450;
}

/* nutshell */

.nutshellWrapper {
  display: flex;
  flex-direction: column;

  gap: 25px;
}

.nutshellRow {
  display: flex;
  flex-direction: column;

  gap: 5px;
}

.nuthsellTitle {
  color: var(--5---Cream-white, #fffff2);
  font-size: 11px;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: 1.21px;
}

.nutshellPara {
  color: var(--3---pastel-brown, #928e8c);
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 130%;
}

@media only screen and (max-width: 768px) {
  .main {
    padding: 0 10px;
  }

  .parent {
    margin-left: 0;
    margin-top: 0;
    padding: 0 10px;
    margin-bottom: 100px;
  }

  .wrapper {
    width: calc(100vw - 10px);

    margin-left: -10px;

    display: flex;
    flex-wrap: wrap;
    gap: 2px;
  }

  .wrapper::-webkit-scrollbar {
    display: none;
  }

  .snippetBlock {
    max-width: 86px;
    min-width: 86px;
  }

  .snippetBlockGrey {
    max-width: 86px;
    min-width: 86px;
  }

  .bottomButtonDiv {
    margin-right: 0;
  }

  .spanWarning {
    left: 23%;
  }

  .stickyDiv {
    display: block;
  }
}
/* 
@media only screen and (max-width: 360px) {
  .wrapper {
    justify-content: center;
  }
} */
