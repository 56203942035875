.parent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 50%;
  top: 50%;
  width: 100%;
  height: 100%;
  z-index: 1000000000;
  background-color: rgb(0 0 0 / 90%);
  overflow: hidden;
}

.main {
  display: flex;
  flex-direction: column;
  align-self: center;

  padding: 30px 20px;
  gap: 40px;

  border-radius: 25px;

  background: #fffff2;

  max-width: 355px;
  width: 100%;
}

.title {
  color: var(--1---Dark-brown, #2d2927);
  text-align: center;
  font-size: 26px;
  font-weight: 450;
  line-height: 100%;
  letter-spacing: -0.78px;
}

.body {
  color: var(--1---Dark-brown, #2d2927);
  font-size: 16px;
  font-weight: 450;
  line-height: 130%;
}

.btnDiv {
  display: flex;
  align-items: center;

  gap: 5px;
}

.secondaryBtn {
  width: 155px;
  height: 44px;

  border: 0;
  border-radius: 7px;

  background: var(--3---pastel-brown, #928e8c);
  backdrop-filter: blur(5.436563491821289px);

  color: var(--5---Cream-white, #fffff2);
  text-align: center;
  font-size: 16px;
  font-weight: 450;
}

.primaryBtn {
  width: 155px;
  height: 44px;

  border: 0;
  border-radius: 7px;

  background: var(--2---Chalk-Brown, #55504f);
  backdrop-filter: blur(5.436563491821289px);

  color: var(--5---Cream-white, #fffff2);
  text-align: center;
  font-size: 16px;
  font-weight: 450;
}

@media only screen and (max-width: 768px) {
  .main {
    padding: 20px;
  }
}
