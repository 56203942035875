.main {
  border-radius: 14px;
  background: linear-gradient(270deg, #bf7ae1 0%, #6962cf 100%);

  padding: 2px 5px;
  gap: 4px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.iconDiv {
  width: 12px;
  height: 12px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.text {
  color: var(--5---Cream-white, #fffff2) !important;
  text-align: center !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  letter-spacing: -0.36px !important;

  position: relative !important;
  right: auto !important;
  margin: 0 !important;
  padding: 0 !important;
}
