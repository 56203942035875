.main {
  display: none;
}

.shareDivParent {
  width: 100%;
  height: 72px;
  border-radius: 10px;
  background: #55504f;
  display: flex;
  align-items: center;
}

.shareDescDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 10px;
}

.invite {
  color: var(--5-cream-white, #fffff2);
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
}

.linkText {
  margin-top: 3px;
  color: var(--5-cream-white, #fffff2);
  font-size: 12px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  opacity: 0.5;
}

.shareSvg {
  margin-left: auto;
  margin-right: 10px;
}

.smallImage {
  border-radius: 50%;
}

/* @media only screen and (min-width: 769px) and (max-width: 1024px) {
  .searchIconDiv {
    top: 14px;
    left: 30px;
  }
} */

@media only screen and (max-width: 768px) {
  .main {
    display: flex;
    position: fixed;
    color: #fffff2;
    background-color: #2d2927;
    padding: 4% 1%;
    width: 100vw;
    height: 100vh;
    height: 100dvh;
    min-height: 100vh;
    min-height: 100dvh;
    z-index: 1000000;
    flex-direction: column;
    overflow-y: scroll;
    padding-bottom: 70px;
  }

  .top {
    display: flex;
    align-items: center;
    padding: 0 15px;
    position: relative;
  }

  .top p {
    color: #fffff2;
    align-self: center;
    margin-left: 5px;
  }

  .searchIconDiv {
    position: absolute;
    top: 14px;
    left: 25px;
  }

  .input {
    width: 90%;
    border: none;
    display: flex;
    border-radius: 7px;
    background-color: #fffff2;
    font-size: 16px;
    height: 44px;
    padding-left: 48px;
  }

  .resultDiv {
    padding: 20px 15px;
  }

  .traitResultDiv {
    display: flex;
    overflow-x: scroll;
    padding-bottom: 8px;
  }

  .people {
    font-size: 20px;
    margin-bottom: 15px;
  }

  .personDiv {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin-left: 0 !important;
  }

  .imageContainer {
    position: relative;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
  }

  .smallImage {
    border-radius: 50%;
  }

  .bioAndUsername {
    margin-left: 10px;
  }

  .bioAndUsername h5 {
    font-size: 16px;
    font-weight: 400;
  }

  .bioAndUsername p {
    font-size: 14px;
    color: grey;
  }

  .reqButton {
    margin-left: auto;
    border-radius: 9px;
    background: var(--subtle-yellow, #ffffde);
    width: 79px;
    height: 40px;
    border: 0;

    color: var(--2-chalk-brown, #55504f);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    font-family: Circularstd-Book;
  }

  .sentButton {
    margin-left: auto;
    border-radius: 9px;
    background: var(--3-pastel-brown, #928e8c);
    width: 79px;
    height: 40px;
    border: 0;

    color: var(--1-dark-brown, #2d2927);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    line-height: normal;
    font-family: Circularstd-Book;
  }

  .invite {
    font-size: 16px;
  }

  .linkText {
    font-size: 14px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1208px) {
  .searchIconDiv {
    top: 42px;
  }
}
