.content {
  padding: 10px 20px;
}

.closeBtn {
  position: absolute;
  right: 10px;
  top: 10px;
}

.mid {
  align-self: center;
  max-width: 400px;
  width: 100%;
  border: 1px dashed #fffff2;
  height: 150px;
  margin-top: 50%;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.text {
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 130%; /* 20.8px */
  text-align: center;
}

.btn {
  border-radius: 7px;
  background: var(--subtle-yellow, #ffffde);
  width: 70px;
  height: 40px;
  border: 0;
  align-self: center;
  margin-top: 20px;

  color: var(--0---carbon-brown, #181716);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
}

.btnDisabled {
  border-radius: 7px;
  background: #928e8c;
  width: 70px;
  height: 40px;
  border: 0;
  align-self: center;
  margin-top: 20px;

  color: var(--0---carbon-brown, #181716);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
}
