.main {
  display: flex;
  flex-direction: column;

  gap: 20px;

  padding: 0 15px;
  padding-top: 17px;
}

.personDiv {
  display: flex;
  align-items: center;

  gap: 9px;
}

.infoDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: 4px;
}

.name {
  color: var(--5---Cream-white, #fffff2);
  font-size: 16px;
  font-weight: 450;
}

.username {
  color: var(--5---Cream-white, #fffff2);
  font-size: 14px;
  font-weight: 450;

  opacity: 0.4;
}
