.main {
  border: 1px dashed rgba(255, 255, 242, 0.2);
  width: 100%;
  display: flex;
  align-items: center;
  padding: 15px 10px;
}

.text {
  color: var(--5---Cream-white, #fffff2);
  font-size: 14px;
  font-weight: 450;
  opacity: 0.8;
}

.btnDiv {
  margin-left: auto;
}
