.patternParent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #fffff2;
  width: 172px;
  height: 203px;
  margin: 5px;
  position: relative;
  overflow: hidden;
  color: #fffff2;
}

.patternParentModal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 172px;
  height: 203px;
  margin: 5px;
  position: relative;
  overflow: hidden;
  color: #fffff2;
}

.patternTop {
  padding-left: 5px;
  padding-right: 5px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.patternMidContainer {
  display: flex;
  width: 89px;
  height: 89px;
  margin-left: 21px;
  margin-right: 21px;
  position: absolute;
  bottom: 65px;
  left: 24px;
}

.patternMidContainerModal {
  display: flex;
  width: 117px;
  height: 117px;
  margin-left: 21px;
  margin-right: 21px;
  position: absolute;
  bottom: 65px;
  left: 7px;
}

.innerDiv {
  height: 100%;
  flex: 1 1 0px;
}

.bottomText {
  font-size: 20px;
  text-align: center;
  line-height: 23.18px;
  font-weight: 350;
}

.elementNameContainer {
  margin-bottom: 8px;
  margin-top: auto;
  height: 46px;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.premiumIcon {
  position: absolute;
  z-index: 10000;
  bottom: 68px;
}

.premiumIconModal {
  position: absolute;
  z-index: 10000;
  bottom: 59px;
}

@media only screen and (max-width: 768px) {
  .noMargin {
    margin: 0;
  }

  .patternParent {
    border: none;
  }

  .patternMidContainer {
    width: 117px;
    height: 117px;
    left: 7px;
  }

  .premiumIcon {
    bottom: 59px;
  }
}
