.closeBtn {
  position: absolute;
  right: 20px;
  top: 10px;
}

.top {
  height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-bottom: 1px solid #464542;
}

.title {
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  justify-content: flex-end;
}

.usernames {
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  opacity: 0.5;
  margin-top: 4px;
  margin-bottom: 10px;
}

.element {
  width: 100%;

  margin-top: 30px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.description {
  width: 100%;

  margin-top: 35px;

  text-align: center;
  color: var(--35-text, #c0b9b7);
  font-size: 16px;
  font-weight: 450;
}

.histogramDiv {
  padding: 0 15px;
  margin-top: 50px;
}

.scoreDiv {
  margin-top: 35px;
  padding: 0 15px;
  margin-bottom: 30px;
}

.personDiv {
  display: flex;
  align-items: center;
  border: 1px solid var(--light-outline, #464542);
  height: 74px;
  padding-left: 10px;
}

.scoreNameDiv {
  margin-left: 9px;
}

.name {
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
}

@media only screen and (max-width: 768px) {
  .closeBtn {
    position: fixed;
    right: 20px;
    top: 20px;
  }
}
