.main {
  width: 100%;

  color: #fffff2;

  padding-top: 20px;
  padding-bottom: 100px;
}

.wrapper {
  display: grid;
  gap: 4px;

  grid-template-columns: repeat(4, 1fr);
  width: fit-content;

  margin: 0 auto;
  margin-top: 20px;

  align-self: center;
}

.header {
  font-size: 20px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;

  padding: 0 10px;

  margin-top: 50px;

  width: 365px;
}

.smallBlock {
  height: fit-content;
  width: 100%;

  grid-row: span 1;
  grid-column: span 1;
}

.largeBlock {
  height: fit-content;
  width: 100%;

  grid-row: span 1;
  grid-column: span 2;
}

.longBlock {
  height: fit-content;
  width: 100%;

  grid-row: span 2;
  grid-column: span 2;
}

.storyWrapper {
  display: flex;
  align-items: center;

  gap: 10px;

  overflow-x: auto;

  padding: 0 10px;

  margin-top: 20px;
}

.storyBody {
  width: 95px;
  min-width: 95px;
  height: 123px;
  min-height: 123px;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  border-radius: 7px;
  border: 1px solid var(--3---pastel-brown, #928e8c);

  padding-bottom: 10px;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.storyText {
  color: var(--subtle-yellow, #ffffde);
  text-align: center;
  font-size: 11px;
  font-weight: 500;
  line-height: 110%;
  letter-spacing: -0.33px;
}
