.main {
  display: flex;
  flex-direction: column;

  padding: 19px 15px;
}

.inputDiv {
  width: 100%;
  position: relative;
}

.input {
  width: 100%;
  height: 44px;

  border-radius: 7px;
  background: #464542;
  backdrop-filter: blur(2.7182817459106445px);

  border: 0;

  color: var(--5---Cream-white, #fffff2);
  font-size: 16px;
  font-weight: 450;

  padding-left: 50px;
}

.input::placeholder {
  color: var(--5---Cream-white, #fffff2);
  font-size: 16px;
  font-weight: 450;

  opacity: 0.5;
}

.searchIconDiv {
  position: absolute;
  top: 14px;
  left: 7px;
}

.profileWrapper {
  display: flex;
  flex-direction: column;

  gap: 15px;

  margin-top: 25px;
}

.profileDiv {
  display: flex;
  align-items: center;
}

.nameDiv {
  margin-left: 9px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: 4px;
}

.name {
  font-size: 16px;
  font-weight: 450;
}

.username {
  font-size: 14px;
  font-weight: 450;

  opacity: 0.4;
}

.button {
  margin-left: auto;
  padding: 11px 13px;

  border: 0;
  border-radius: 9px;

  color: var(--0---carbon-brown, #181716);
  text-align: center;
  font-size: 14px;
  font-weight: 700;

  background: var(--5---Cream-white, #fffff2);
}

.button:disabled {
  color: #55504f;
  background: var(--3---pastel-brown, #928e8c);
}
