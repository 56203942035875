.main {
  width: 550px;
  position: fixed;
  top: 50%;
  z-index: 100000;
  min-height: 500px;
  background-color: #2d2927;
  padding: 0 15px;
  padding-bottom: 15px;
  overflow: auto;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
}

.parent {
  margin-top: 40px;
  width: 100%;
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  color: var(--5-cream-white, #fffff2);
  text-align: center;
  font-size: 26px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
}

.paraOne {
  color: var(--5-cream-white, #fffff2);
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 130%;
  margin-top: 40px;
  opacity: 0.800000011920929;
}

.paraTwo {
  color: var(--5-cream-white, #fffff2);
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 130%;
  margin-top: 15px;
  opacity: 0.800000011920929;
}

.dismiss {
  align-self: center;
  width: 340px;
  height: 44px;
  border-radius: 7px;
  background: var(--2-chalk-brown, #55504f);
  backdrop-filter: blur(5.436563491821289px);

  color: var(--5-cream-white, #fffff2);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  border: none;
  margin-top: 250px;
}

@media only screen and (max-width: 768px) {
  .main {
    width: 100%;
    height: 100%;
    padding: 0;
    border-radius: 0;
  }

  .parent {
    margin-left: 0;
    margin-top: 29px;
    width: 100%;
    min-height: 97vh;
    min-height: 97dvh;
  }

  .dismiss {
    margin-top: auto;
    margin-bottom: 50px;
  }
}
