.main {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.wrapper {
  width: 100%;
  border: 1px solid #464542;
}

.row {
  width: 100%;
  border-bottom: 1px solid #464542;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.title {
  font-size: 20px;
  font-weight: 450;
  letter-spacing: -0.2px;

  border: 1px solid var(--light-outline, #464542);
  background: var(--0---carbon-brown, #181716);

  padding: 10px;
}

.rowText {
  font-size: 13px;
  font-weight: 500;

  opacity: 0.8;
}

.rowTextHeader {
  font-size: 13px;
  font-weight: 500;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: none;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
}
