.topSection {
  margin-top: 20px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-size: 23.482px;
  font-weight: 500;
  letter-spacing: -0.704px;
}

.plus {
  background: linear-gradient(90deg, #8bdcff 0%, #6978ff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.middle {
  margin-top: 30px;
  width: 100%;
}

.slide {
  min-width: 100%;
  height: 375px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.slideTitleDiv {
  padding: 0 20px;
  margin-top: 25px;
}

.slideTitle {
  font-size: 40px;
  font-weight: 450;
  line-height: 100%;
  letter-spacing: -1.2px;
}

.line {
  width: 100%;
  height: 1px;
  background: #464542;
  margin-top: 20px;
}

.slideSubTitle {
  font-size: 16px;
  font-weight: 450;
  line-height: 120%; /* 19.2px */
  letter-spacing: -0.48px;
  opacity: 0.8;
  margin-top: 10px;
}

.circleDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin-top: 10px;
}

.circleActive {
  width: 10px;
  height: 10px;
  background-color: #fffff2;
  border-radius: 50%;
  margin-right: 5px;
}

.circle {
  width: 10px;
  height: 10px;
  background-color: #fffff2;
  opacity: 0.35;
  border-radius: 50%;
  margin-right: 5px;
}

.bottomDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.continueButton {
  width: 100%;
  max-width: 340px;
  height: 50px;
  border-radius: 7px;
  background: var(--subtle-yellow, #ffffde);
  backdrop-filter: blur(5.436563491821289px);
  border: 0;

  color: var(--0---carbon-brown, #181716);
  text-align: center;
  font-size: 16px;
  font-weight: 450;
}

.bottomText {
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: -0.36px;
  opacity: 0.5;

  margin-top: 17px;
}

@media only screen and (max-width: 768px) {
  .circleDiv {
    margin-bottom: 30px;
  }

  .bottomDiv {
    margin-top: auto;
    margin-bottom: 20px;
  }
}
