.parent {
  display: flex;
  flex-direction: column;
  width: 335px;
}

.archParent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
}

.acro {
  color: var(--5-cream-white, #fffff2);
  font-size: 26px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
}

.nameArch {
  color: var(--5-cream-white, #fffff2);
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
}

@media only screen and (max-width: 768px) {
  .parent {
    padding: 0 10px;
  }
}
