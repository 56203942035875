.main {
  width: 181px;
  /* height: calc(100vh - 3px); */
  color: #fffff2;
  border-right: 1px solid #464542;
  background-color: #181716;
  min-height: 100vh;
  min-height: 100dvh;
  z-index: 999;
}

.logo {
  margin-top: 23px;
  margin-left: 20px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  margin-left: 20px;
  margin-right: 28px;
  position: sticky;
  position: -webkit-sticky;
  top: 20px;
}

.iconWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 27px;
  height: 27px;
}

.tabChild {
  display: flex;
  margin-bottom: 15px;
  align-items: center;
}

.tabChild p {
  opacity: 0.4;
  font-family: Circularstd-Book;
  font-weight: 450;
  font-size: 14px;
  line-height: 18px;
  color: #fffff2;
  margin-left: 12px;
}

.active {
  opacity: 1 !important;
}

.hideDesktop {
  display: none;
}

@media only screen and (max-width: 1024px) {
  .main {
    min-height: auto;
    width: 100%;
    height: 75px;
    position: fixed;
    left: 0;
    bottom: -1px;
    color: white;
    border-top: 1px solid #464542;
    border-right: none;
    z-index: 999;
  }

  .wrapper {
    flex-direction: row;
    margin: 0;
    height: 100%;
    align-items: center;
  }

  .logo {
    display: none;
  }

  .tabChild {
    flex-direction: column;
    margin-bottom: 0;
    align-items: center;
    /* width: 20%; */
    width: 25%;
  }

  .tabChild p {
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.12px;
    margin-left: 0;
    margin-top: 6px;
  }

  .hideMobile {
    display: none;
  }

  .hideDesktop {
    display: flex;
  }
}
