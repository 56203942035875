.main {
  width: 100%;
  margin-top: 50px;
  position: relative;
}

.wrapper {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
  max-width: 550px;
  margin-top: 20px;
  gap: 2px;
}

.snippetBlock {
  border-radius: 7px;
  border: 1px solid var(--3---pastel-brown, #928e8c);
  min-width: 88px;
  max-width: 88px;
  min-height: 123px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 25px;
  padding-bottom: 12px;
  position: relative;
}

.snippetBlockLatestResults {
  border-radius: 7px;
  border: 1px solid var(--3---pastel-brown, #928e8c);
  min-width: 88px;
  max-width: 88px;
  min-height: 123px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 6px;
  padding-bottom: 8px;
  position: relative;
}

.snippetBlockLarge {
  position: relative;
  grid-row: span 2;
  grid-column: span 2;

  border-radius: 7px;
  border: 1px solid var(--3---pastel-brown, #928e8c);

  padding-top: 55px;
  padding-bottom: 12px;

  min-width: 176px;
  max-width: 176px;
  min-height: 254px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.snippetBlockWide {
  display: flex;
  justify-content: center;
  align-items: center;

  gap: 10px;

  border-radius: 7px;
  border: 1px solid var(--3---pastel-brown, #928e8c);

  padding: 27px 26px 27px 15px;

  width: 100%;
}

.loadingDiv {
  width: 51px;
  height: 51px;
}

.loadingDivLarge {
  width: 69.945px;
  height: 69px;
}

.loadingDivVeryLarge {
  width: 69.945px;
  height: 69px;
}

.premIconDiv {
  position: absolute;
  top: 5px;
  left: 5px;
}

.typeIcon {
  position: absolute;
  top: 5px;
  right: 5px;
}

.usernameTextResults {
  color: var(--3---pastel-brown, #928e8c);
  text-align: center;
  font-size: 11px;
  line-height: 110%;
  letter-spacing: -0.33px;
}

.resultsImageDiv {
  position: relative;
  margin-top: 3px;
}

.resultIconDiv {
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  bottom: -4px;
  right: -6px;

  width: 30px;
  height: 30px;

  padding: 5px;

  border-radius: 50%;

  background-color: #55504f;
}

.snippetName {
  color: var(--subtle-yellow, #ffffde);
  text-align: center;
  font-size: 11px;
  font-weight: 500;
  line-height: 110%;
  letter-spacing: -0.33px;
  margin-top: auto;
  padding: 0 5px;
  max-width: 120px;
}

.snippetNameLarge {
  width: 59px;

  color: var(--5---Cream-white, #fffff2);
  font-size: 14px;
  font-weight: 500;
  line-height: 110%; /* 15.4px */
  letter-spacing: -0.42px;
}

.snippetIcon {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.connectionIcon {
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #fffff2;
  position: absolute;
  top: 5px;
  right: 5px;
}

/* generate modal */

.generateModalMain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.generateModalTop {
  display: flex;
  align-items: center;
  justify-content: center;
}

.generatePersonDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 80px;
}

.generateUsername {
  color: var(--5---Cream-white, #fffff2);
  text-align: center;
  font-size: 12px;
  font-weight: 450;
  margin-top: 14px;
}

.snippetIconSmall {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 12px;
  margin-bottom: 15px;
}

.snippetIconSmallModal {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  margin: 0 20px;
  margin-top: 20px;
}

.archetypeAcronym {
  border-radius: 4px;
  background: var(--2---Chalk-Brown, #55504f);
  padding: 3px;
  width: fit-content;
  align-self: center;
  margin-top: 13px;

  text-align: center;
  font-size: 16px;
  font-weight: 450;
  line-height: 130%;
}

.overCompatSection {
  margin-top: 30px;

  padding: 0 10px;
}

.generateContent {
  text-align: center;
  font-size: 20px;
  font-weight: 450;
  line-height: 130%;
  margin-top: 58px;
}

.generateBtn {
  margin-top: 70px;
  border-radius: 9px;
  background: var(--5---Cream-white, #fffff2);
  display: flex;
  padding: 11px 13px;
  justify-content: center;
  align-items: center;
  border: 0;

  color: var(--0---carbon-brown, #181716);
  font-size: 14px;
  font-weight: 700;
}

.generateBtnDisabled {
  margin-top: 70px;
  border-radius: 9px;
  background: #928e8c;
  display: flex;
  padding: 11px 13px;
  justify-content: center;
  align-items: center;
  border: 0;

  color: var(--0---carbon-brown, #181716);
  font-size: 14px;
  font-weight: 700;
}

.computeContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

.computeText {
  text-align: center;
  font-size: 20px;
  font-weight: 450;
  line-height: 130%;
}

.computeBottomText {
  position: absolute;
  bottom: 60px;
  color: var(--35-text, #c0b9b7);
  text-align: center;
  font-size: 14px;
  font-weight: 450;
  line-height: 19px;
}

.computeBottomTextTwo {
  position: absolute;
  bottom: 40px;
  color: var(--35-text, #c0b9b7);
  text-align: center;
  font-size: 14px;
  font-weight: 450;
  line-height: 19px;
  text-decoration: underline;
}

.warningIcon {
  width: 100px;
  height: 100px;
  opacity: 0.2;
}

/* main modal */

.premiumIcon {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;

  margin-top: 13px;
}

.modalTop {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 7px;
}

.modalContent {
  padding-left: 15px;
  padding-right: 15px;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
}

.modalHeader {
  font-size: 36px;
  font-weight: 450;
  line-height: 130%;
  letter-spacing: -1.08px;
}

.modalsubheading {
  color: var(--3---pastel-brown, #928e8c);
  font-size: 16px;
  font-weight: 450;
  line-height: 130%;
  letter-spacing: -0.48px;
  margin-top: 5px;
}

.modalbottom {
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 20px;
  margin-top: 50px;
}

.connectionIconModal {
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #fffff2;
}

.requiredDivModal {
  display: flex;
  align-items: center;
}

.requiredDivModalText {
  font-size: 12px;
  font-weight: 450;
  opacity: 0.8;
  margin-left: 8px;
}

.feedbackDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 50px;
}

/* spectrums */

.spectrumsParent {
  margin-top: 30px;
}

.spectrumsSubHeadingsDiv {
  display: flex;
  flex-direction: column;

  gap: 5px;
}

.spectrumsSubHeading {
  color: var(--5---Cream-white, #fffff2);
  font-size: 22px;
  font-style: normal;
  font-weight: 450;
  line-height: 130%;
  letter-spacing: -0.66px;
}

.spectrumsDescription {
  color: var(--3---pastel-brown, #928e8c);
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 130%;
  letter-spacing: -0.48px;
}

.twoByTwoParent {
  margin-top: 30px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.spectrumHeading {
  font-size: 22px;
  font-weight: 450;
  line-height: 130%;
  letter-spacing: -0.66px;
}

.spectrumSubHeading {
  color: var(--3---pastel-brown, #928e8c);
  font-size: 16px;
  font-weight: 450;
  line-height: 130%;
  letter-spacing: -0.48px;
  margin-top: 5px;
}

.row {
  margin-top: 25px;
}

.rowParent {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  line-height: 130%;
  color: #fffff2;
}

.selected {
  opacity: 1;
  margin-bottom: 13px;
  border-radius: 4px;
  background: var(--2---Chalk-Brown, #55504f);
  padding: 3px;

  font-size: 16px;
  font-weight: 450;
  line-height: 130%; /* 20.8px */
  letter-spacing: -0.48px;
}

.lineParent {
  display: flex;
  position: relative;
  margin-top: 8px;
}

.lineRight {
  height: 13px;
  width: 1px;
  background-color: #fffff2;
}

.lineMiddle {
  width: 99%;
  border-bottom: 1px #fffff2 solid;
  margin-bottom: 6.5px;
}

.lineLeft {
  height: 13px;
  width: 1px;
  background-color: #fffff2;
}

.midLine {
  position: absolute;
  height: 13px;
  width: 1px;
  background-color: #fffff2;
  left: 50%;
}

.imageDiv {
  position: absolute;
  width: 30px;
  height: 30px;
  bottom: -8px;
}

/* venn diagram */

.overallScoreDiv {
  font-size: 12px;
  font-weight: 900;
  line-height: 100%;
  letter-spacing: 1.32px;

  height: 70px;

  border-radius: 13px;
  background: var(--2---Chalk-Brown, #55504f);

  padding: 15px;
}

.overallScoreDivTop {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.overallScoreDivText {
  display: flex;
  align-items: center;

  gap: 5px;
}

.overallScoreTitle {
  color: var(--5---Cream-white, #fffff2);
  font-size: 11px;
  font-weight: 500;
  line-height: 100%;
}

.overallScoreDivBarParent {
  position: relative;
  width: 100%;
  margin-top: 10px;
}

.overallScoreDivBarOutside {
  width: 100%;
  height: 15px;
  border-radius: 11px;
  opacity: 0.22;
  background: var(--subtle-yellow, #ffffde);
  position: absolute;
  left: 0;
  right: 0;
}

.overallScoreDivBarInside {
  height: 15px;
  border-radius: 11px;
  background: var(--subtle-yellow, #ffffde);
  position: absolute;
  left: 0;
  right: 0;
}

.userNames {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
}

.usernameAndImageDivLeft {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.usernameAndImageDivRight {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.username {
  font-size: 11px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 1.21px;
  max-width: 125px;
  word-wrap: break-word;

  margin-top: 7px;
}

.usernameGives {
  font-size: 11px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 1.21px;
  max-width: 125px;
  word-wrap: break-word;
  text-align: end;

  margin-top: 7px;
}

.venWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  height: 250px;
  position: relative;
  margin-top: 32px;
}

.ulLeft {
  font-size: 14px;
  font-weight: 450;
  letter-spacing: -0.28px;
  width: 76px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 50px;
}

.ulCenter {
  font-size: 14px;
  font-weight: 450;
  letter-spacing: -0.28px;
  width: 76px;
  display: flex;
  flex-direction: column;
  margin-left: 50px;
}

.ulRight {
  font-size: 14px;
  font-weight: 450;
  letter-spacing: -0.28px;
  width: 76px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 50px;
}

.span {
  position: relative;
  left: -5px;
}

.circleOne {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  position: absolute;
  left: 5px;
}

.circleTwo {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  position: absolute;
  right: 5px;
}

.scoreBar {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin-top: 32px;
}

.scorebarDiv {
  width: 52px;
  height: 52px;
}

.scorebarText {
  margin-left: 6px;

  font-size: 16px;
  font-weight: 450;
  line-height: 130%; /* 20.8px */
}

.tabParent {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 42px;
}

.activeTab {
  width: 10px;
  height: 10px;
  background-color: #fffff2;
  border-radius: 50%;
  margin: 4px;
}

.inactiveTab {
  width: 10px;
  height: 10px;
  background-color: #fffff2;
  border-radius: 50%;
  margin: 4px;
  opacity: 0.35;
}

/* List view */

.listSection {
  margin-top: 30px;
}

.listTitleDiv {
  display: flex;
  align-items: center;
}

.listTitle {
  color: var(--3---pastel-brown, #928e8c);
  font-size: 11px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 1.21px;
  margin-right: 7px;
}

.nounDiv {
  margin-top: 6px;
  width: 148px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.noun {
  color: var(--5-cream-white, #fffff2);
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: 130%;

  margin-top: 5px;
  margin-right: 5px;
  border-radius: 5px;
  padding: 5px;
  background: var(--2---Chalk-Brown, #55504f);

  border: 1px solid var(--nature-openness, #928e8c);
}

.nounAdjective {
  color: var(--5-cream-white, #fffff2);
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: 130%;
}

/* llmcontent */

.llmTitle {
  font-size: 22px;
  font-style: normal;
  font-weight: 450;
  line-height: 130%;
  margin-top: 35px;
}

.llmParagraph {
  margin-top: 5px;
  color: var(--35-text, #c0b9b7);
  font-family: AkkuratStd;
  font-size: 16px;
  font-weight: 400;
  line-height: 130%;
}

.liDiv {
  display: flex;
}

.llmOl li {
  display: flex;
  color: var(--35-text, #c0b9b7);
  font-family: AkkuratStd;
  font-size: 16px;
  font-weight: 400;
  line-height: 130%;
  margin-top: 15px;
  list-style-type: none;
}

.olNumber {
  display: flex;
  min-width: 22px;
  min-height: 22px;
  width: 22px;
  height: 22px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  margin-top: 15px;
  margin-right: 5px;

  color: var(--1---Dark-brown, #2d2927);
  font-family: Circularstd-Book;
  font-size: 14px;
  font-weight: 700;
  line-height: 130%;
  border-radius: 100px;
  background: var(--4---Beige, #e6e1df);
}

.natureStatementUl {
  margin-top: 10px;

  padding-left: 30px;
}

.natureStatement {
  font-family: AkkuratStd;
  font-size: 24px;
  font-weight: 400;
  line-height: 130%;
}

.unorderedNatureStatementWrapper {
  margin-top: 20px;

  display: flex;
  flex-direction: column;

  gap: 12px;
}

.natureStatementUnordered {
  font-family: AkkuratStd;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

.listViewParent {
  display: flex;
  flex-direction: column;

  gap: 16px;
}

.listViewTopImagesParent {
  display: flex;
  flex-direction: column;

  gap: 14px;
}

.listViewImagesContainer {
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 9px;
}

.listViewTopImagesText {
  color: var(--3---pastel-brown, #928e8c);
  text-align: center;
  font-size: 14px;
  font-weight: 450;
  line-height: 20px;
}

.listViewHeader {
  background-color: #fffff2;

  height: 45px;
  width: 100%;

  padding: 0 10px;

  display: flex;
  align-items: center;
}

.listViewHeaderText {
  color: var(--0---carbon-brown, #181716);
  font-size: 22px;
  font-weight: 450;
  letter-spacing: -0.22px;
}

.listViewSection {
  display: flex;
  justify-content: space-between;

  border: 1px solid var(--light-outline, #464542);

  padding: 17px 10px;
}

.yourTopHeading {
  font-size: 11px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 1.21px;
  margin-bottom: 5px;
}

.yourTopHeadingNew {
  font-size: 14px;
  font-weight: 450;
  line-height: 20px; /* 142.857% */
}

.yourTopTraitName {
  border-radius: 5px;
  border: 1px solid #928e8c;

  background: #55504f;

  padding: 5px;

  color: var(--5---Cream-white, #fffff2);
  font-size: 14px;
  font-weight: 450;
  line-height: 130%; /* 18.2px */

  width: fit-content;

  margin-bottom: 15px;
}

.listViewTraitsWrapper {
  margin-top: 15px;

  display: flex;
  flex-direction: column;

  gap: 10px;
}

.listViewTraitsWrapperRight {
  margin-top: 15px;

  display: flex;
  flex-direction: column;
  align-items: flex-end;

  gap: 10px;
}

.theirTraitDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.listViewIndexCirclesDiv {
  margin-top: 50px;

  display: flex;
  align-items: center;
  justify-content: center;

  gap: 8px;
}

.listViewIndexCircles {
  width: 10px;
  height: 10px;

  background-color: #fffff2;

  border-radius: 50%;

  opacity: 0.35;
}

/* llm list detail view */

.llmListDiv {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tileNumber {
  color: var(--35-text, #c0b9b7);
  text-align: center;
  font-family: AkkuratStd;
  font-size: 16px;
  font-weight: 400;
  line-height: 130%;
}

.llmListTitle {
  text-align: center;
  font-size: 26px;
  font-weight: 450;
  line-height: 130%;
  margin-top: 16px;
}

.llmListParagraph {
  color: var(--35-text, #c0b9b7);
  font-family: AkkuratStd;
  font-size: 16px;
  font-weight: 400;
  line-height: 130%;
  margin-top: 16px;
}

/* headered list */

.headeredListTop {
  display: flex;
  align-self: center;
  align-items: center;
}

.arrowDiv {
  margin: 0 9px;
}

.headeredListTitle {
  font-size: 30px;
  font-weight: 450;
  line-height: 110%;
  letter-spacing: -0.6px;
  margin-top: 15px;
}

.headerdListLine {
  margin-top: 15px;
  height: 2px;
  width: 100%;
  background-color: #fffff2;
}

.headeredListContentDiv {
  margin-top: 50px;
  padding: 0 20px;
}

.reportContentDiv {
  margin-top: 15px;
}

/* cards view */

.cardsSection {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
}

.cardsViewTop {
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
}

.headerdListAsk {
  align-self: center;
  color: var(--3---pastel-brown, #928e8c);
  text-align: center;
  font-size: 11px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 1.21px;
  margin-top: 14px;
}

.headersListCard {
  width: 100%;
  min-height: 200px;
  padding: 0 20px;
  border-radius: 12px;
  background: var(--1---Dark-brown, #2d2927);
  display: flex;
  flex-direction: column;
  cursor: grab;
}

.cardQuestionNumber {
  margin-top: 20px;
  align-self: center;
  text-align: center;
  color: var(--3---pastel-brown, #928e8c);
  font-family: AkkuratStd;
  font-size: 16px;
  font-weight: 400;
  line-height: 130%;
}

.caredQuestion {
  margin-top: 15px;
  font-family: AkkuratStd;
  font-size: 20px;
  font-weight: 400;
  line-height: 130%;
  margin-bottom: 43px;
}

.circleDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin-top: 20px;
}

.circleActive {
  width: 10px;
  height: 10px;
  background-color: #fffff2;
  border-radius: 50%;
  margin-right: 5px;
}

.circle {
  width: 10px;
  height: 10px;
  background-color: #fffff2;
  opacity: 0.35;
  border-radius: 50%;
  margin-right: 5px;
}

/* romantic and work comp report */

.reportTop {
  width: 100%;
  min-height: 480px;
  height: 480px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
}

.reportTop:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 200px;
  background: linear-gradient(transparent, #181716);
}

.premiumIconReport {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;

  position: absolute;

  left: 0;
  right: 0;
  bottom: 115px;
  z-index: 10;
}

.reportTopImagesDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  z-index: 10;
}

.reportHeader {
  font-size: 46px;
  font-weight: 900;
  line-height: 100%;
  letter-spacing: -1.38px;
}

/* sexual compat bars */

.sexualScoresDiv {
  margin-top: 60px;
}

.sexualBarTop {
  display: flex;
  flex-direction: column;
}

.sexualBarTopTextDiv {
  display: flex;
  justify-content: space-between;

  font-size: 14px;
  font-weight: 900;
  line-height: 100%;
  letter-spacing: 1.54px;
}

.sexualBarTopOverallParent {
  position: relative;
  margin-top: 10px;
}

.sexualScoreBarInside {
  height: 15px;
  border-radius: 13px;
  background: linear-gradient(
    90deg,
    #57cbfc 0%,
    #57cbfc 0.01%,
    #a16ebe 47.06%,
    #f40578 100%
  );
  position: absolute;
  left: 0;
  right: 0;
}

.sexualBarBottom {
  margin-top: 22px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.otherScoreRowParent {
  display: flex;
  flex-direction: column;
  width: 155px;
  margin-top: 22px;
}

.otherScoreRowTopDiv {
  display: flex;
  justify-content: space-between;

  font-size: 12px;
  font-weight: 900;
  line-height: 100%;
  letter-spacing: 1.32px;
}

.overallScoreDivBarOutsideSmall {
  width: 100%;
  height: 10px;
  border-radius: 6px;
  opacity: 0.22;
  background: var(--subtle-yellow, #ffffde);
  position: absolute;
  left: 0;
  right: 0;
}

.sexualScoreBarInsideSmall {
  height: 10px;
  border-radius: 13px;
  background: linear-gradient(
    90deg,
    #57cbfc 0%,
    #57cbfc 0.01%,
    #a16ebe 47.06%,
    #f40578 100%
  );
  position: absolute;
  left: 0;
  right: 0;
}

.partText {
  font-size: 11px;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: 1.21px;
}

.coreImage {
  width: 100%;
  height: auto;
  margin-top: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.individualSumamriesImageDiv {
  display: flex;
  align-items: center;
  gap: 10px;

  margin-top: 50px;
}

.usernameHeader {
  font-size: 26px;
  font-weight: 450;
  line-height: 130%;
}

.comaptSNippetsSubHeading {
  font-size: 20px;
  font-weight: 450;
  letter-spacing: -0.2px;

  margin-top: 45px;
}

.filterParent {
  margin-top: 10px;
}

.llmContentDivReport {
  display: inline-block;
  margin-top: 15px;
}

.conversionWrapper {
  width: 100%;
  padding: 0 10px;
  z-index: 10000000000000;
}

.conversionMain {
  height: 55px;
  border-radius: 17.609px;
  background: linear-gradient(90deg, #8bdcff 0%, #6978ff 100%);
  padding: 10px 15px;
  z-index: 10000000000000;
}

.conversionText {
  color: var(--1---Dark-brown, #2d2927);
  font-size: 14px;
  font-weight: 450;
  z-index: 10000000000000;
}

.conversionDiv {
  margin-bottom: 10px;
}

.interactionBarDiv {
  position: sticky;
  bottom: -1px;
  z-index: 50000;
}

/* emoji view */
.emojiCard {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 80px 0px;

  gap: 5px;

  border-radius: 20px;
  border: 1px solid var(--light-outline, #464542);
}

.emojiString {
  color: #000;
  text-align: center;
  font-size: 100px;
  font-weight: 450;
  line-height: 130%;
}

.emojiTitle {
  color: var(--5---Cream-white, #fffff2);
  text-align: center;
  font-size: 22px;
  font-weight: 450;
  line-height: 130%;
}

.emojiDescription {
  color: var(--35-text, #c0b9b7);
  text-align: center;
  font-family: AkkuratStd;
  font-size: 16px;
  font-weight: 400;
  line-height: 130%;
}

.secondaryEmojiDiv {
  display: flex;
  flex-direction: column;

  margin-top: 30px;
}

.secondaryTitle {
  color: var(--5---Cream-white, #fffff2);
  font-size: 11px;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: 1.21px;

  margin-bottom: 10px;
}

.secondaryString {
  color: var(--35-text, #c0b9b7);
  font-family: AkkuratStd;
  font-size: 16px;
  font-weight: 400;
  line-height: 130%;
}

@media only screen and (max-width: 768px) {
  .main {
    margin-top: 35px;
  }

  .wrapper {
    grid-template-columns: repeat(4, 1fr);
    grid-auto-flow: row;
    gap: 1px;
    max-width: 355px;

    margin-left: -2.8%;
    margin-right: -2.5%;
  }
}
