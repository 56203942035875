.main {
  display: flex;
  align-items: center;

  gap: 10px;
}

.line {
  width: 33px;
  height: 1px;

  background: var(--4---Beige, #e6e1df);
}

.text {
  color: var(--4---Beige, #e6e1df);
  font-size: 13px;
  font-weight: 500;
  line-height: 120%;
}
