.main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 100vh;

  max-height: 667px;
}

.icon {
  width: 120px;
  height: 120px;
  position: absolute;
  left: 187px;
  top: 112px;
}

.xmark {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 35px;
  height: 35px;
  right: 30px;
  top: 39px;
}

.privacyDiv {
  display: flex;
  justify-content: center;
  align-items: center;

  gap: 8px;

  position: absolute;

  left: 30px;
  top: 22px;
}

.privacyIconDiv {
  width: 26px;
  height: 26px;
}

.privacyText {
  color: var(--5---Cream-white, #fffff2);
  font-size: 12px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
}

.top {
  display: flex;
  align-items: center;
  width: 100%;

  margin-left: 30px;
  margin-top: 90px;
}

.imageDiv {
  position: relative;
  width: 52px;
  height: 52px;
  border-radius: 50%;
}

.imageDiv img {
  border: 1px solid transparent;
}

.top h1 {
  font-weight: 450;
  font-size: 31px;
  line-height: 130%;
  letter-spacing: -0.02em;
  color: #fffff2;
  margin-left: 6px;
}

.summary {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  padding-bottom: 60px;
}

.premiumGateDiv {
  width: 100%;
  display: flex;
  justify-content: center;

  margin-top: 130px;
}

.loadingScreenDiv {
  min-height: 100vh;
  min-height: 100dvh;
  min-width: 100vw;
  position: fixed;
  z-index: 20000;
  background-color: #000000;
  left: 0;
  top: 0;
}

.waterMarkDiv {
  display: flex;
  align-items: center;
  align-self: center;

  margin-top: 17px;

  gap: 5px;
}

.waterMarkText {
  color: #fffff2;
  font-size: 13.958px;
  font-weight: 500;
  letter-spacing: -0.419px;
}
