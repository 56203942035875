.main {
  width: 100%;
  min-height: 350px;

  border-radius: 15px;
  border: 1px solid var(--light-outline, #464542);

  margin-top: 10px;

  padding-top: 15px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.personalInsightText {
  color: var(--3---pastel-brown, #928e8c);
  text-align: center;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 1.21px;

  margin-bottom: 20px;
}

.insightName {
  font-size: 20px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;

  margin-top: 5px;
}

.body {
  color: var(--4---Beige, #e6e1df);
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 140%;

  margin-top: 25px;

  word-wrap: break-word;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;

  align-self: flex-start;

  padding: 0 20px;
}

.bottom {
  display: flex;
  justify-content: space-between;

  width: 100%;

  margin-top: auto;
}

.bottomLeftDiv {
  display: flex;
  align-items: center;

  gap: 4px;
}

/* ghostbox */
.ghostBoxDivParent {
  margin-top: 38px;
  padding: 0 5px;
}

.ghostboxDiv {
  border-radius: 7px;
  border: 1px dashed rgba(255, 255, 242, 0.2);

  padding: 10px 10px;

  display: flex;
  align-items: center;
  gap: 10px;
}

.warningIconDiv {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;

  opacity: 0.8;

  padding: 8.679px 7.333px 9px 7.369px;
}

.ghostboxMessage {
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;

  opacity: 0.8;
}
