.architypeBody {
  position: relative;
  min-width: 172px;
  width: 172px;
  height: 203px;
  border: #fffff2 1px solid;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.architypeBodyModal {
  position: relative;
  min-width: 172px;
  width: 172px;
  height: 203px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.architypeBodyModalBorder {
  position: relative;
  width: 131px;
  height: 164px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border: #fffff2 1px solid;
}

.archetypeBodyBorder {
  position: relative;
  min-width: 172px;
  width: 172px;
  height: 203px;
  border: #fffff2 1px solid;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-right: 6px;
  overflow: hidden;
}

.topText {
  font-size: 13px;
  margin-bottom: auto;
  color: #fffff2;
  margin-top: 10px;
  letter-spacing: 0.11em;
  font-weight: 650;
  opacity: 0.5;
}

.topTextBorder {
  font-size: 11px;
  margin-bottom: auto;
  color: #fffff2;
  margin-top: 10px;
  letter-spacing: 0.11em;
  font-weight: 650;
  opacity: 0.5;
}

.bottomTextDiv {
  height: 57px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 17px;
}

.bottomTextDivBorder {
  height: 57px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 17px;
}

.bottomText {
  font-size: 10px;
  text-align: center;
  line-height: 15.18px;
  font-weight: 350;
  color: #fffff2;
  font-size: 20px;
  line-height: 25px;
}

.bottomTextBorder {
  font-size: 12px !important;
  text-align: center;
  line-height: 15.18px;
  font-weight: 350;
  color: #fffff2;
  font-size: 20px;
  line-height: 25px;
}

.architypeBody img {
  justify-self: center;
  align-self: center;
  width: 89px;
  height: 89px;
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .archetypesParent {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 768px) {
  .architypeBody {
    min-width: 100%;
    border: none;
    height: 170px;
  }

  .archetypeBodyBorder {
    min-width: 100%;
    border: none;
    height: 160px;
  }

  .topText {
    font-size: 10px;
    line-height: 13px;
    text-align: center;
    color: #fffff2;
    position: absolute;
    top: -4px;
    left: 15px;
  }

  .bottomTextDiv {
    margin-bottom: 0;
  }

  .bottomText {
    font-size: 20px;
    line-height: 25px;
    color: #fffff2;
  }

  .architypeBody img {
    justify-self: center;
    align-self: center;
    width: 145px;
    height: 145px;
  }
}
