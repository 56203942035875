.main {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.wrapper {
  width: 100%;
  border: 1px solid #464542;
}

.row {
  width: 100%;
  height: 38px;
  border-bottom: 1px solid #464542;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.rowText {
  font-size: 13px;
  font-weight: 500;
  opacity: 0.800000011920929;
  color: #fffff2;
}

.header {
  width: 100%;
  height: 48px;
  border-bottom: 1px solid #464542;
  display: flex;
  align-items: center;
  padding: 0 10px;
}

.header h1 {
  color: #fffff2;
  font-size: 20px;
  font-weight: 450;
  letter-spacing: -0.2px;
  margin-right: 10px;
}

.scoreWrapper {
  display: flex;
  padding-top: 6px;
  padding-bottom: 3px;
  margin-top: 2px;
  position: relative;
}

.selected {
  border-radius: 2px;
  height: 6px;
  width: 36px;
  margin-right: 4px;
  background-color: #ffffde;
}

.unselected {
  border-radius: 2px;
  height: 6px;
  width: 36px;
  margin-right: 4px;
  background-color: #ffffde;
  opacity: 0.2;
}

.scoreDescDiv {
  display: flex;
  align-self: flex-end;
  align-items: baseline;
  position: relative;
}

.scoreDescDiv h4 {
  color: #fffff2;
  text-align: right;
  font-size: 12px;
  font-weight: 700;
  line-height: 100%;
  opacity: 0.5;
  backdrop-filter: blur(5.436563491821289px);
}

.iconDiv {
  display: flex;
  flex-direction: column;
  margin-left: 4px;
  position: relative;
  top: -2px;
}

.arrow {
  width: 9px;
  height: 5px;
}

.neutral {
  width: 9px;
  height: 1.714px;
  border-radius: 2.143px;
  background: #fffff2;
}

.restrictIcon {
  position: absolute;
  width: 18px;
  height: 18px;
  left: 45%;
  top: 0;
}

.premiumIcon {
  position: absolute;
  width: 18px;
  height: 18px;
  left: 40%;
  top: 0;
}

.accuracyMain {
  display: flex;
  align-items: center;
}

.accuracybuttonDiv {
  display: flex;
  align-items: center;
}

.circleActive {
  width: 9.391px;
  height: 9px;
  border-radius: 50%;
  background-color: #fffff2;
  margin-left: 9px;
}

.circleInactive {
  width: 9.391px;
  height: 9px;
  border-radius: 50%;
  background-color: #fffff2;
  margin-left: 9px;
  opacity: 0.2;
}

.improveButton {
  width: 68px;
  height: 28px;
  border-radius: 4px;
  background: var(--subtle-yellow, #ffffde);
  border: 0;
  margin-left: 11px;

  color: var(--1---Dark-brown, #2d2927);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
}

.peerGhostBox {
  width: 100%;
  height: 70px;

  display: flex;
  align-items: center;

  padding: 0px 10px;

  border-radius: 7px;
  border: 1px dashed rgba(255, 255, 242, 0.2);
}

.peerGhostBoxPara {
  color: var(--5---Cream-white, #fffff2);
  font-size: 14px;
  font-weight: 450;

  opacity: 0.8;
}

@media only screen and (max-width: 768px) {
  .wrapper {
    width: 100%;
    align-self: center;
  }
}
