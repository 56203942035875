.main {
  margin-top: 20px;
  display: flex;
  overflow-x: auto;
}

@media only screen and (max-width: 768px) {
  .main {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .main::-webkit-scrollbar {
    display: none;
  }
}
