.heading {
  font-family: "Circularstd-Book";
  font-weight: 350;
  font-size: 20px;
  line-height: 25px;
  letter-spacing: -0.01em;
  color: #fffff2;
  margin-bottom: 20px;
}

.headerProfile {
  padding: 5px 10px;
  background: var(--5---Cream-white, #fffff2);

  color: var(--0---carbon-brown, #181716);
  font-size: 22px;
  font-weight: 450;
  letter-spacing: -0.22px;
}

.headerNoProfile {
  padding: 5px 10px;
  border: 0.5px solid #fffff257;
  border-bottom: 0;

  color: var(--5---Cream-white, #fffff2);
  font-size: 22px;
  font-weight: 450;
  letter-spacing: -0.22px;
}
