.main {
  width: 95px;
  min-width: 95px;
  height: 123px;
  min-height: 123px;

  border-radius: 7px;
  border: 1px solid var(--3---pastel-brown, #928e8c);
}

.wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  overflow-x: auto;
  padding: 0 10px;
  margin-top: 20px;
}
