.button {
  display: flex;
  padding: 11px 13px;
  justify-content: center;
  align-items: center;
  border-radius: 9px;
  background: linear-gradient(90deg, #8bdcff 0%, #6978ff 100%), #ffecba;
  max-height: 40px;
  min-width: 79px;
  border: 0;

  color: var(--5---Cream-white, #fffff2);
  text-align: center;
  font-size: 12px;
  font-weight: 700;
}
