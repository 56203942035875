.mainCTA {
  width: 100%;
  min-height: 350px;
  max-height: 350px;

  border-radius: 15px;

  display: flex;
  flex-direction: column;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  padding-top: 8px;
}

.storyDay {
  color: var(--5---Cream-white, #fffff2);
  text-align: center;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  font-size: 10px;
  font-weight: 500;

  align-self: center;
}

.storyTitle {
  border-radius: 4px;

  background: #fffff2;

  width: fit-content;

  color: var(--0---carbon-brown, #181716);
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 1.21px;

  align-self: center;

  margin-top: 17px;
  padding: 3px 5px;
}

.experimentalDiv {
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;

  gap: 10px;

  padding: 4px 6px;

  border-radius: 4px;

  background: var(--caution-orange, #e9ab5e);

  margin-top: 14px;
}

.infoIcon {
  width: 20px;
  height: 20px;
}

.experimentalText {
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.bodyDiv {
  margin-top: 73px;

  display: flex;
  flex-direction: column;

  gap: 4px;
}

.storyBody {
  padding: 2px 4px;

  border-radius: 4px;

  background: var(--5---Cream-white, #fffff2);

  color: var(--0---carbon-brown, #181716);
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.54px;

  width: fit-content;

  align-self: center;
}

.bottom {
  margin-top: auto;
}

/* modal */
.modalMain {
  width: 100%;
  height: 100%;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  position: relative;

  display: grid;

  overflow: hidden;
}

.xmark {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 5%;
  top: 5%;
}

.wrapper {
  margin-top: 120px;

  display: flex;
  flex-direction: column;
}

.logoDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
}

.storyTitleModal {
  border-radius: 4px;

  background: var(--5---Cream-white, #fffff2);

  width: fit-content;
  max-width: 220px;

  color: var(--0---carbon-brown, #181716);
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 1.21px;
  text-align: center;

  align-self: center;

  padding: 3px 5px;
}

.bodyWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  flex-direction: column;

  gap: 2px;

  min-width: 88%;
  max-width: 88%;
}

.feedbackDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;

  margin-top: 25px;
}

.rateMain {
  padding: 12px 16px;

  border-radius: 12px;
  border: 0;

  background: rgba(45, 41, 39, 0.3);

  display: flex;
  align-items: center;

  gap: 8px;
}

.ratedText {
  color: var(--5---Cream-white, #fffff2);
  font-size: 14px;
  font-weight: 450;
}

.instagramButton {
  display: flex;
  align-items: center;

  padding: 12px 16px;

  gap: 8px;

  border-radius: 12px;
  border: 0;

  background: var(--5---Cream-white, #fffff2);
}

.instagramShareText {
  color: var(--0---carbon-brown, #181716);
  font-size: 14px;
  font-weight: 450;
}

.bottomGradientDiv {
  height: 200px;
}

.interractionBar {
  margin-top: auto;
  background: linear-gradient(180deg, rgba(24, 23, 22, 0) 0%, #181716 100%);
}

.sharedPostButtonDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

/* ghostbox */

.ghostboxMain {
  width: 100%;
  min-height: 350px;
  max-height: 350px;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  border-radius: 15px;

  display: flex;
  flex-direction: column;

  padding: 0 5px;
  padding-top: 8px;
}

.ghostboxMid {
  display: flex;
  align-items: center;

  height: 70px;

  gap: 10px;

  padding: 0px 10px;

  border-radius: 7px;
  border: 1px dashed var(--5---Cream-white, #fffff2);

  margin-top: 81px;

  background: rgba(24, 23, 22, 0.5);
}

.warningIconDiv {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 40px;
  height: 40px;

  padding: 8.679px 7.333px 9px 7.369px;

  opacity: 0.8;
}

.ghostBoxText {
  color: var(--5---Cream-white, #fffff2);
  font-size: 14px;
  font-weight: 450;

  opacity: 0.8;
}

.bottomGHostbox {
  margin: 0 -5px;
  margin-top: auto;
}
