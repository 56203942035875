.instructionalMain {
  display: flex;
  flex-direction: column;
}

.instructionTitleSection {
  height: calc(100vh - 196px);

  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: 10px;

  padding: 0 20px;
}

.instructionTitle {
  font-size: 26px;
  font-weight: 450;
  letter-spacing: -0.3px;
}

.instructionSubTitle {
  color: var(--3---pastel-brown, #928e8c);
  font-size: 16px;
  font-weight: 450;
  line-height: 130%;
}

.instructionalBottomSection {
  position: absolute;
  bottom: 0;

  border-top: 1px solid var(--light-outline, #464542);

  display: flex;
  justify-content: center;
  align-items: center;

  padding: 26px 20px 32px 20px;

  width: 100%;
}

.instructionalBottomButton {
  border: 0;
  border-radius: 7px;

  background: var(--5---Cream-white, #fffff2);

  width: 100%;

  padding: 14px;

  color: var(--0---carbon-brown, #181716);
  text-align: center;
  font-size: 16px;
  font-weight: 450;
}

.header {
  position: sticky;
  top: -2px;

  display: flex;
  flex-direction: column;

  padding-top: 44px;
}

.quizTitle {
  color: var(--5---Cream-white, #fffff2);
  text-align: center;
  font-size: 16px;
  font-weight: 450;

  align-self: center;

  max-width: 258px;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.quizSubTitle {
  color: var(--3---pastel-brown, #928e8c);
  text-align: center;
  font-size: 12px;
  font-weight: 450;

  align-self: center;

  margin-top: 1px;
}

.downArrorDiv {
  width: 30px;
  height: 30px;

  transform: rotate(-90deg);

  position: absolute;
  top: 40px;
  left: 11px;

  padding: 5px;
}

.progressBar {
  height: 1px;
  background: #464542;

  margin-top: 4px;

  position: relative;
}

.progress {
  position: absolute;

  background: #fffff2;

  height: 3px;
}

.leadInText {
  margin-top: 64px;

  padding: 0 12px;

  color: #fffff2;
  font-size: 13px;
  font-weight: 400;

  opacity: 0.5;
}

.itemText {
  margin-top: 8px;

  padding: 0 12px;

  color: #fffff2;
  font-size: 26px;
  font-weight: 450;
  letter-spacing: -0.5px;
}

.optionsWrapper {
  display: flex;
  flex-direction: column;

  gap: 10px;

  margin-top: 39px;

  padding: 0 12px;
}

.optionBox {
  border-radius: 7px;

  background: rgba(255, 255, 242, 0.13);

  padding: 13.5px 20px;
}

.optionText {
  color: var(--5---Cream-white, #fffff2);
  font-size: 16px;
  font-weight: 450;
}

.backButtonDiv {
  padding: 0 12px;

  margin-top: 15px;

  display: flex;
  justify-content: flex-end;
}
