.main {
  display: flex;
}

.mainNoScroll {
  display: flex;
  overflow: hidden;
}

@media only screen and (max-width: 768px) {
  .main {
    flex-direction: column;
    overflow-x: hidden;
  }

  .mainNoScroll {
    flex-direction: column;
  }
}
