.nav {
  display: flex;
  align-items: center;
  min-height: 70px;
  height: 70px;

  padding-top: 20px;
  padding-bottom: 10px;

  border-bottom: 1px solid var(--light-outline, #464542);

  position: sticky;
  top: 0;

  background-color: rgb(24, 23, 22);

  z-index: 2;

  position: relative;
}

.backButtonDiv {
  width: 20px;
  height: 20px;

  transform: rotate(-90deg);

  position: absolute;
  left: 10px;
}

.goDeeperIconDiv {
  display: flex;
  align-items: center;
  justify-content: center;

  width: fit-content;
  height: fit-content;

  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.rightSideButtonDiv {
  display: flex;
  align-items: center;

  gap: 20px;

  position: absolute;
  right: 10px;
}

.messageWrapper {
  display: flex;
  flex-direction: column;

  gap: 25px;

  padding: 0 15px;
  padding-top: 10px;

  margin-top: auto;

  margin-bottom: 20px;

  overflow-y: auto;
}

.systemMessageDiv {
  color: var(--4---Beige, #e6e1df);
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 140%;

  list-style-position: inside;
}

.systemMessageDiv p {
  margin-top: 10px;
}

.systemMessageDiv h3 {
  margin-top: 10px;
}

.systemMessageDiv ol {
  padding: 0 10px;
}

.systemMessageDiv li {
  margin-top: 10px;
}

.systemMessageDiv li p {
  margin: 0;
  display: inline;
}

.errorDiv {
  width: 100%;
  height: 70px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  border-radius: 7px;
  border: 1px dashed var(--error-red, #b82c35);

  padding: 5px 10px;
}

.errorMessage {
  font-size: 14px;
  font-weight: 450;
}

.errorBtn {
  border: 0;

  padding: 11px 13px;

  border-radius: 9px;
  background: var(--5---Cream-white, #fffff2);

  color: var(--0---carbon-brown, #181716);
  font-size: 14px;
  font-weight: 700;
}

.userMessageDiv {
  display: flex;
  justify-content: flex-end;
}

.userMessage {
  width: fit-content;
  max-width: 85%;

  padding: 11px 15px;

  border-radius: 20px;

  background: var(--Hyperlink-blue, #2f6fd3);
}

.suggestedMessageDiv {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  gap: 8px;

  overflow-x: scroll;

  min-height: fit-content;

  margin-bottom: 80px;

  padding: 0 15px;
}

.suggestedMessageDiv::-webkit-scrollbar {
  display: none;
}

.suggestedMessage {
  padding: 10px 12px;

  border-radius: 20px;

  border: 1px dashed var(--Hyperlink-blue, #2f6fd3);

  color: var(--4---Beige, #e6e1df);
  font-size: 14px;
  font-weight: 450;
  letter-spacing: -0.28px;

  max-width: 85%;
}

.bottom {
  width: 100%;
  min-height: 60px;

  padding: 9px 10px;

  border-top: 1px solid var(--light-outline, #464542);

  background: var(--0---carbon-brown, #181716);

  box-shadow: 0px -10px 20px 0px #181716;

  position: absolute;
  bottom: 0;
}

.loadingDiv {
  margin-bottom: 75px;

  padding: 0 15px;
}

.conversationFeedbackDiv {
  margin-top: 25px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.scrollToBottomIcon {
  position: fixed;
  bottom: 60px;
  left: 50%;
  transform: translateX(-50%);
}
