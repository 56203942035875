.main {
  width: 100%;
}

.barContainer {
  display: flex;
  /* justify-content: space-evenly; */
  padding: 50px 0;
}

.barContainerOwn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 768px) {
  .barContainer {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1208px) {
  .barContainer {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    padding: 0 100px;
  }
}
