.main {
  width: 100%;
  height: 100vh;

  display: flex;
  flex-direction: column;

  padding: 32px 20px;
}

.title {
  align-self: center;

  text-align: center;
  font-size: 26px;
  font-weight: 450;
  line-height: 100%;
  letter-spacing: -0.78px;
}

.body {
  margin-top: 40px;

  font-size: 16px;
  font-weight: 450;
  line-height: 130%; /* 20.8px */

  display: flex;
  flex-direction: column;

  gap: 5px;
}

.bodyDiv {
  margin-top: 40px;

  display: flex;
  flex-direction: column;
  gap: 20px;
}

.bodyEmail {
  font-size: 16px;
  font-weight: 450;
  line-height: 130%;

  margin-top: 40px;

  opacity: 0.8;
}

.list {
  margin-top: 20px;

  display: flex;
  flex-direction: column;
  gap: 10px;
}

.settingsText {
  color: var(--vibrant-blue, #2cb0ff);
  font-size: 16px;
  font-weight: 450;
  line-height: 130%;
}

.listText {
  font-size: 16px;
  font-weight: 450;
  line-height: 130%;

  opacity: 0.8;
}

.buttonsDiv {
  margin-top: auto;

  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 10px;
}

.dismissBtn {
  border: 0;
  border-radius: 7px;

  background: var(--2---Chalk-Brown, #55504f);

  padding: 14px;

  color: var(--5---Cream-white, #fffff2);
  text-align: center;
  font-size: 16px;
  font-weight: 450;
}

.optionalCta {
  border: 0;
  border-radius: 7px;

  background: var(--5---Cream-white, #fffff2);

  padding: 14px;

  color: var(--0---carbon-brown, #181716);
  text-align: center;
  font-size: 16px;
  font-weight: 450;
}

/* expiration modal */
.mainExpiration {
  width: 100%;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  padding: 0 15px;
}

.closeButtonExpiration {
  position: absolute;

  right: 30px;
  top: 30px;
}

.midExpiration {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 20px 27px;

  gap: 20px;

  border-radius: 7px;
  border: 1px dashed rgba(255, 255, 242, 0.2);

  width: 100%;
}

.warningIconDiv {
  width: 40px;
  height: 40px;

  padding: 8.679px 7.333px 9px 7.369px;
}

.copyExpiration {
  color: var(--5---Cream-white, #fffff2);
  text-align: center;
  font-size: 14px;
  font-weight: 450;
}

.buttonExpiration {
  border: 0;
  border-radius: 9px;
  background: var(--5---Cream-white, #fffff2);

  padding: 11px 13px;

  color: var(--0---carbon-brown, #181716);
  text-align: center;
  font-size: 14px;
  font-weight: 700;
}
