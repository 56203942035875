.main {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.wrapper {
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (max-width: 768px) {
  /* .main {
    padding: 0 10px;
  } */

  .wrapper {
    width: 100%;
  }
}
