.main {
  display: flex;
  flex-direction: column;

  gap: 35px;

  padding: 0 15px;
  padding-top: 20px;
}

.top {
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 10px;
}

.pollText {
  color: var(--3---pastel-brown, #928e8c);
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 1.21px;
}

.pollTitle {
  font-size: 19px;
  font-weight: 450;
  line-height: 120%;
}

.optionsWrapper {
  display: flex;
  flex-direction: column;

  gap: 10px;
}

.optionDiv {
  padding: 11px 20px;

  border-radius: 7px;
  background: var(--2---Chalk-Brown, #55504f);

  width: 100%;
  height: 40px;

  display: flex;
  align-items: center;
}

.optionLabel {
  font-size: 14px;
  font-weight: 700;
}

.showResponseDiv {
  padding: 13px 20px 16px 20px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.showResponseText {
  font-size: 14px;
  font-weight: 450;
}
