.header {
  position: sticky;
  top: 0;

  display: flex;
  align-items: flex-start;
  justify-content: center;

  width: 100%;
  height: 90px;

  border-bottom: 1px solid rgba(255, 255, 242, 0.2);

  padding-top: 46px;

  background: var(--0---carbon-brown, #181716);

  z-index: 3;
}

.headerTitlesDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: 4px;
}

.headerTitle {
  color: #fffff2;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
}

.headerSubTitle {
  color: var(--3---pastel-brown, #928e8c);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
}

.backArrowDiv {
  width: 30px;
  height: 30px;
  transform: rotate(-90deg);

  padding: 5px;

  position: absolute;
  top: 40px;
  left: 11px;
}

.main {
  display: flex;
  flex-direction: column;

  padding-top: 12px;
}

.experimentalDiv {
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;

  gap: 10px;

  padding: 4px 6px;

  border-radius: 4px;

  background: var(--caution-orange, #e9ab5e);
}

.infoIcon {
  width: 20px;
  height: 20px;
}

.experimentalText {
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.iconDiv {
  align-self: center;

  margin-top: 22px;
}

.title {
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;

  margin-top: 5px;
}

.insightTitle {
  color: var(--3---pastel-brown, #928e8c);
  text-align: center;
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.21px;

  margin-top: 22px;
}

.body {
  padding: 0 20px;

  margin-top: 80px;

  color: var(--4---Beige, #e6e1df);
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 140%;

  word-wrap: break-word;
}

.ruleSetText {
  color: var(--3---pastel-brown, #928e8c);
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  letter-spacing: 1.21px;

  margin-top: 40px;

  padding: 0 20px;
}

.scalesDiv {
  padding: 0 20px;

  margin-top: 15px;

  display: flex;
  align-items: center;
  flex-wrap: wrap;

  gap: 5px;
}

.scale {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 8px;

  gap: 5px;

  border-radius: 4px;
  border: 2px solid gray;

  background: var(--2---Chalk-Brown, #55504f);

  width: fit-content;
}

.scaleName {
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
}

.commentInfoDiv {
  margin-top: 40px;

  padding: 0 20px;
}

.sharedPostButtonDiv {
  margin-bottom: 311px;
  margin-top: 40px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.feedbackDiv {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;

  position: fixed;
  left: 50%;
  bottom: 18px;
  transform: translate(-50%, -50%);

  padding: 0 10px;
}

.interactionBarDiv {
  position: absolute;
  bottom: -1px;
  z-index: 5000;
  width: 100%;
}

/* shareview */

.shareViewMain {
  display: flex;
  flex-direction: column;

  padding-top: calc((100vh - 100%) / 2 - 39px);
  padding-bottom: 15px;

  height: 100vh;

  background: var(--0---carbon-brown, #181716);

  overflow: hidden;
}

.bodyShareView {
  padding: 0 20px;

  margin-top: 33px;

  color: var(--4---Beige, #e6e1df);
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 140%;

  word-wrap: break-word;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 10;
  overflow: hidden;
  text-overflow: ellipsis;
}

.scaleShareView {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 6px 7px;

  gap: 3px;

  border-radius: 4px;
  border: 2px solid gray;

  background: var(--2---Chalk-Brown, #55504f);

  width: fit-content;
}

.bottomTextShare {
  margin-top: auto;
  margin-bottom: 90px;

  color: var(--3---pastel-brown, #928e8c);
  font-size: 13.958px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.419px;
  text-align: center;
}
