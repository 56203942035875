.main {
  display: flex;
  flex-direction: column;

  margin-top: 10px;

  color: var(--5---Cream-white, #fffff2);

  padding-bottom: 54px;

  height: 100%;

  overflow-y: auto;
}

.title {
  text-align: center;
  font-size: 20px;
  font-weight: 450;

  align-self: center;
}

.iconDiv {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 29px;
}

.messageRemainingText {
  align-self: center;

  margin-top: 27px;

  text-align: center;
  font-size: 16px;
  font-weight: 450;
}

.plusText {
  background: linear-gradient(90deg, #8bdcff 89.67%, #6978ff 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 16px;
  font-weight: 450;
}

.insightPowerDiv {
  display: flex;
  flex-direction: column;

  margin-top: 70px;

  padding: 0 30px;
}

.insightPowerTitle {
  font-size: 16px;
  font-weight: 450;
}

.insightPowerBarParent {
  margin-top: 6px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  gap: 20px;

  width: 100%;
}

.insightPowerBarWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  flex: 3;

  gap: 3px;
}

.bar {
  height: 6px;
  min-height: 6px;

  flex: 1;

  border-radius: 4px;

  background-color: #ffffde;
}

.textAndArrowDiv {
  display: flex;
  align-items: center;

  gap: 4px;

  flex: 1;
}

.textAndArrowText {
  color: var(--5---Cream-white, #fffff2);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;

  opacity: 0.5;
}

.completingText {
  color: var(--3---pastel-brown, #928e8c);
  font-size: 14px;
  font-weight: 450;

  margin-top: 12px;
}

.dimensionIconParent {
  margin-top: 32px;
}

.dimensionsCompleteText {
  color: var(--4---Beige, #e6e1df);
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 1.21px;
}

.dimensionIconWrapper {
  margin-top: 14px;

  display: flex;
  align-items: center;
  flex-wrap: wrap;

  gap: 12px;
}

.dimensionIcon {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 26px;
  height: 26px;
}

.buttonDiv {
  margin-top: auto;

  display: flex;
  flex-direction: column;

  align-items: center;

  gap: 15px;

  padding-top: 50px;
}

.messageButton {
  border: 0;
  border-radius: 9px;

  background: var(--5---Cream-white, #fffff2);

  padding: 11px 13px;

  width: fit-content;

  color: var(--0---carbon-brown, #181716);
  font-size: 14px;
  font-weight: 700;
}

.closeBtn {
  border: 0;
  border-radius: 9px;

  background: var(--2---Chalk-Brown, #55504f);

  padding: 11px 13px;

  color: var(--5---Cream-white, #fffff2);
  font-size: 14px;
  font-weight: 700;
}

.consumablesMapper {
  margin-top: 27px;

  display: flex;
  align-items: center;

  gap: 18px;

  height: fit-content;
}
