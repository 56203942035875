.main {
  width: 100%;
}

.parent {
  margin-left: 0;
  margin-top: 29px;
  padding: 0 10px;
}

.coreParent {
  margin-top: 50px;
}

.table {
  width: 100%;
  margin-top: 20px;

  border-top: 1px solid #464542;
  border-bottom: 1px solid #464542;
}

.row {
  height: 70px;
  width: 100%;
  display: flex;
  align-items: center;

  border-right: 1px solid #464542;
  border-left: 1px solid #464542;
  border-bottom: 1px solid #464542;
}

.iconDiv {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 14px;
  margin-right: 20px;
}

.row h2 {
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  color: #fffff2;
}

.row p {
  font-weight: 450;
  font-size: 12px;
  line-height: 15px;
  color: #fffff2;
  margin-top: 3px;
}

.inactive {
  opacity: 0.5;
}

.otherParent {
  margin-top: 50px;
}

.buttonDivActive {
  width: 86px;
  height: 46px;
  background: #fffff2;
  border-radius: 9px;

  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-left: auto;
  margin-right: 15px;
  border: 0;
  color: #181716;
}

.buttonDiv {
  width: 86px;
  height: 46px;
  background: #55504f;
  border-radius: 9px;

  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-left: auto;
  margin-right: 15px;
  border: 0;
  color: #fffff2;
}

.status {
  margin-top: 55px;
  margin-bottom: 90px;
  color: #fffff2;
}

.statusHeader {
  height: 45px;
  width: 100%;
  background-color: #fffff2;
  display: flex;
  align-items: center;
}

.statusHeader h1 {
  font-weight: 450;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: -0.01em;
  color: #181716;
  margin-left: 10px;
}

.statusIconDiv {
  margin-left: 8px;
  width: 26px;
  height: 26px;
}

.row h3 {
  margin-left: 6px;
  font-size: 13px;
  line-height: 17px;
  color: #fffff2;
  font-weight: 500;
}

.row p {
  margin-left: auto;
  margin-right: 15px;
  font-weight: 500;
  font-size: 13px;
  line-height: 17px;
}

.heading {
  font-family: Circularstd-Book;
  font-weight: 450;
  font-size: 26px;
  line-height: 33px;
  letter-spacing: -0.01em;
  color: #fffff2;
}

.subHeading {
  font-weight: 450;
  font-size: 13px;
  line-height: 16px;
  color: rgba(255, 255, 242, 0.5);
  margin-top: 5px;
}

.premiumPillDiv {
  margin-left: 10px;
  margin-bottom: 20px;
}
