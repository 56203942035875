.wrapper {
  width: 580px;
  height: 640px;
  border: 1px solid #fffff257;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 56px;
}

.header {
  border-bottom: 1px solid #fffff257;
  padding: 5px;
}

.header h2 {
  color: #fffff2;
  font-size: 20px;
  line-height: 25px;
  font-family: Circularstd-Book;
  font-weight: 350;
}

.main {
  color: #fffff2;
  text-align: center;
  width: 535px;
  height: 604px;
  position: relative;
  align-self: center;
  display: flex;
  flex-direction: column;
}

.parent {
  display: flex;
  flex-direction: column;
  width: 505px;
  height: 574px;
  display: flex;
  flex-direction: column;
  align-self: center;
}

.rowTop {
  display: flex;
  border-bottom: 1px solid #fffff257;
  height: 50%;
}

.row {
  display: flex;
  height: 50%;
}

.label {
  font-size: 13px;
}

.leftTop {
  border-right: 1px solid #fffff257;
  width: 50%;
  color: #fffff2;
  display: flex;
  align-items: flex-end;
}

.leftTopSplit {
  display: inline-block;
  border-top: 300px solid black;
  border-right: 300px dashed #181716;
  transform: rotate(90deg);
  position: absolute;
  left: 382px;
}

.RightTop {
  width: 50%;
  color: #fffff2;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.leftBottom {
  border-right: 1px solid #fffff257;
  width: 50%;
}

.rightBottom {
  width: 50%;
}

.lineTopLeft {
  width: 381px;
  border-top: dashed #fffff257 1px;
  position: absolute;
  top: 160px;
  left: -48px;
  transform: rotate(48.5deg);
}

.lineTopRight {
  width: 381px;
  border-top: dashed #fffff257 1px;
  position: absolute;
  top: 159px;
  left: 203px;
  transform: rotate(131.5deg);
}

.lineBottomLeft {
  width: 381px;
  border-top: dashed #fffff257 1px;
  position: absolute;
  bottom: 159px;
  left: -49px;
  transform: rotate(131.5deg);
}

.lineBottomRight {
  width: 381px;
  border-top: dashed #fffff257 1px;
  position: absolute;
  bottom: 160px;
  left: 203px;
  transform: rotate(48.5deg);
}

.di {
  position: absolute;
  font-size: 16px;
  line-height: 20px;
  font-family: Circularstd-Book;
  color: #fffff2;
  left: 112px;
  top: 232px;
  opacity: 0.5;
}

.id {
  position: absolute;
  font-size: 16px;
  line-height: 20px;
  font-family: Circularstd-Book;
  color: #fffff2;
  left: 194px;
  top: 156px;
  opacity: 0.5;
}

.is {
  position: absolute;
  font-size: 16px;
  line-height: 20px;
  font-family: Circularstd-Book;
  color: #fffff2;
  left: 316px;
  top: 156px;
  opacity: 0.5;
}

.si {
  position: absolute;
  font-size: 16px;
  line-height: 20px;
  font-family: Circularstd-Book;
  color: #fffff2;
  right: 112px;
  top: 232px;
  opacity: 0.5;
}

.sc {
  position: absolute;
  font-size: 16px;
  line-height: 20px;
  font-family: Circularstd-Book;
  color: #fffff2;
  right: 112px;
  bottom: 232px;
  opacity: 0.5;
}

.cs {
  position: absolute;
  font-size: 16px;
  line-height: 20px;
  font-family: Circularstd-Book;
  color: #fffff2;
  left: 316px;
  bottom: 156px;
  opacity: 0.5;
}

.cd {
  position: absolute;
  font-size: 16px;
  line-height: 20px;
  font-family: Circularstd-Book;
  color: #fffff2;
  left: 194px;
  bottom: 156px;
  opacity: 0.5;
}

.dc {
  position: absolute;
  font-size: 16px;
  line-height: 20px;
  font-family: Circularstd-Book;
  color: #fffff2;
  left: 112px;
  bottom: 232px;
  opacity: 0.5;
}

/* modal */
.wrapperModal {
  width: 100%;
  height: 515;
  align-self: center;
  border: 1px solid var(--light-outline, #464542);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 56px;
}

.mainModal {
  width: 100%;
  height: 367px;

  color: #fffff2;
  text-align: center;

  position: relative;

  display: flex;
  align-self: center;
  flex-direction: column;

  padding: 15px 9px;
}

.parentModal {
  width: 100%;
  max-width: 325px;
  height: 100%;
  display: flex;
  flex-direction: column;
  display: flex;
  flex-direction: column;
  align-self: center;
  position: relative;
}

.headerModal {
  padding: 5px 10px;
  background: var(--5---Cream-white, #fffff2);
}

.headerModal h2 {
  color: var(--0---carbon-brown, #181716);
  font-size: 22px;
  font-weight: 450;
  letter-spacing: -0.22px;
}

.rowTopModal {
  display: flex;
  border-bottom: 1px solid #fffff257;
  height: 50%;
}

.lineTopLeftModal {
  width: 100%;
  border-top: dashed #fffff257 1px;
  position: absolute;
  top: 50%;
  left: 0%;
  transform: rotate(43deg);
}

.lineTopRightModal {
  width: 100%;
  border-top: dashed #fffff257 1px;
  position: absolute;
  top: 50%;
  left: 0%;
  transform: rotate(137deg);
}

.diM {
  position: absolute;
  font-size: 16px;
  line-height: 20px;
  font-family: Circularstd-Book;
  color: #fffff2;
  left: 18%;
  top: 119px;
  opacity: 0.5;
}

.idM {
  position: absolute;
  font-size: 16px;
  line-height: 20px;
  font-family: Circularstd-Book;
  color: #fffff2;
  left: 33%;
  top: 77px;
  opacity: 0.5;
}

.isM {
  position: absolute;
  font-size: 16px;
  line-height: 20px;
  font-family: Circularstd-Book;
  color: #fffff2;
  right: 33%;
  top: 77px;
  opacity: 0.5;
}

.siM {
  position: absolute;
  font-size: 16px;
  line-height: 20px;
  font-family: Circularstd-Book;
  color: #fffff2;
  right: 18%;
  top: 119px;
  opacity: 0.5;
}

.scM {
  position: absolute;
  font-size: 16px;
  line-height: 20px;
  font-family: Circularstd-Book;
  color: #fffff2;
  right: 18%;
  bottom: 119px;
  opacity: 0.5;
}

.csM {
  position: absolute;
  font-size: 16px;
  line-height: 20px;
  font-family: Circularstd-Book;
  color: #fffff2;
  right: 33%;
  bottom: 77px;
  opacity: 0.5;
}

.cdM {
  position: absolute;
  font-size: 16px;
  line-height: 20px;
  font-family: Circularstd-Book;
  color: #fffff2;
  left: 33%;
  bottom: 77px;
  opacity: 0.5;
}

.dcM {
  position: absolute;
  font-size: 16px;
  line-height: 20px;
  font-family: Circularstd-Book;
  color: #fffff2;
  left: 18%;
  bottom: 119px;
  opacity: 0.5;
}

.personDiv {
  position: absolute;
}

/* reports */

.wrapperMainReports {
  width: 100%;
  height: 367px;
  max-width: 550px;

  display: flex;
  align-self: center;
  justify-content: center;
}

.mainReports {
  width: 100%;
  height: 367px;
  max-width: 367px;

  color: #2d2927;
  text-align: center;

  position: relative;

  display: flex;
  align-self: center;
  flex-direction: column;

  padding: 15px 9px;
}

.rowTopReports {
  display: flex;
  border-bottom: 1px solid #2d2927;
  height: 50%;
}

.leftTopReports {
  border-right: 1px solid #2d2927;
  width: 50%;
  color: #2d2927;
  display: flex;
  align-items: flex-end;
}

.rightTopReports {
  width: 50%;
  color: #2d2927;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.leftBottomReports {
  border-right: 1px solid #2d2927;
  width: 50%;
}

.blueDotDiv {
  width: 20px;
  height: 20px;

  position: absolute;

  border-radius: 50%;

  background-color: #2cb0ff;
}

@media only screen and (max-width: 768px) {
  .wrapper {
    width: 360px;
    height: 440px;
    margin-top: 50px;
    align-self: center;
  }

  .main {
    width: 315px;
    height: 404px;
    justify-content: center;
  }

  .parent {
    width: 285px;
    height: 285px;
  }

  .lineTopLeft {
    width: 199px;
    top: 128px;
    left: -15px;
    transform: rotate(45deg);
  }

  .lineTopRight {
    width: 199px;
    top: 128px;
    left: 128px;
    transform: rotate(135deg);
  }

  .lineBottomLeft {
    width: 199px;
    bottom: 132px;
    left: -13px;
    transform: rotate(135deg);
  }

  .lineBottomRight {
    width: 199px;
    bottom: 132px;
    left: 127px;
    transform: rotate(45deg);
  }

  .di {
    left: 77px;
    top: 161px;
  }

  .id {
    left: 116px;
    top: 116px;
  }

  .is {
    left: 176px;
    top: 116px;
  }

  .si {
    right: 77px;
    top: 161px;
  }

  .sc {
    right: 70px;
    bottom: 167px;
  }

  .cs {
    left: 175px;
    bottom: 115px;
  }

  .cd {
    left: 116px;
    bottom: 115px;
  }

  .dc {
    left: 77px;
    bottom: 167px;
  }
}
