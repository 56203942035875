.main {
  width: 100%;
  height: 50px;

  display: flex;
  align-items: center;

  padding: 15px;

  background: var(--1---Dark-brown, #2d2927);

  border-radius: 0 0 15px 15px;
}

.iconAndTextDiv {
  display: flex;
  align-items: center;

  gap: 5px;
}

.rightSideDiv {
  display: flex;
  align-items: center;

  gap: 20px;

  margin-left: auto;
}

.timeText {
  color: var(--35-text, #c0b9b7);
  font-size: 14px;
  font-weight: 450;
}

.countText {
  color: var(--4---Beige, #e6e1df);
  font-size: 14px;
  font-weight: 700;
}
