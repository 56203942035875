.markdownDiv {
  color: #fffff2;
  margin-top: 38px;
  padding: 0 5px;
}

.markdownDiv h3 {
  margin-top: 25px;
  font-size: 26px;
  line-height: 33px;
  font-weight: 350 !important;
  font-family: Circularstd-Book !important;
}

.markdownDiv h4 {
  margin-top: 25px;
  font-size: 17px;
  line-height: 33px;
  font-weight: 350 !important;
  font-family: Circularstd-Book !important;
}

.markdownDiv h2 {
  margin-top: 25px;
  margin-bottom: 10px;
  font-size: 26px;
  line-height: 33px;
  font-weight: 350 !important;
  font-family: Circularstd-Book !important;
}

.markdownDiv ul {
  font-family: Circularstd-Book !important;
  font-weight: 350 !important;
  font-size: 16px;
  line-height: 130%;
  color: #fffff2;
  opacity: 0.8;
  margin-left: 20px;
  margin-top: 10px;
}

.markdownDiv p {
  font-family: Circularstd-Book !important;
  font-weight: 350 !important;
  font-size: 16px;
  line-height: 130%;
  color: #fffff2;
  opacity: 0.8;
  margin-top: 20px;
}

.blueDot {
  position: absolute;
  top: 9px;
  right: 19px;

  width: 5px;
  height: 5px;

  border-radius: 50%;

  background-color: #2cb0ff;
}

.blueDotNonAbsolute {
  width: 5px;
  height: 5px;

  border-radius: 50%;

  background-color: #2cb0ff;
}

.blueDotLarge {
  width: 12px;
  height: 12px;

  border-radius: 50%;

  background-color: #2cb0ff;
}

@media only screen and (max-width: 768px) {
  .markdownDiv {
    padding: 0 10px;
  }

  .blueDot {
    right: 6px;
  }
}
