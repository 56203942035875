.main {
  max-width: 610px;
  margin-left: 110px;
}

.ghostboxDiv {
  width: 100%;
  color: #fffff2;
  margin-bottom: 100px;
  padding: 0 5%;
  margin-top: 30px;
}

@media only screen and (max-width: 768px) {
  .main {
    width: 100%;
    margin-left: 0;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1208px) {
  .main {
    margin-left: 0;
    width: 100%;
  }
}
