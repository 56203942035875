.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  left: 50%;
  top: 50%;
  width: 100%;
  height: 100%;
  background-color: #00000054;
  /* z-index: 10000000; */
  background-color: rgba(0, 0, 0, 0.33);
}

.closeBtn {
  position: absolute;
  right: 20px;
  top: 10px;
}

.wrapper {
  width: 430px;
  height: 90%;
  color: #fffff2;
  overflow: auto;
  border-radius: 12px;
  background-color: #2d2927;
  position: relative;
  padding: 0 30px;

  transition: all 0.2s;
}

.title {
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  margin-top: 46px;
}

.personWrap {
  display: flex;
  flex-direction: column;
}

.person {
  display: flex;
  align-items: center;
  margin-top: 25px;
  overflow-y: auto;
  width: 100%;
}

.nameDiv {
  margin-left: 10px;
}

.name {
  font-size: 16px;
  font-weight: 400;
}

.username {
  font-size: 14px;
  color: grey;
}

.circle {
  margin-left: auto;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  border: 2px solid #8080806e;
}

@media only screen and (max-width: 768px) {
  .main {
    height: 100vh;
    height: 100dvh;
    background-color: #181716;
  }

  .wrapper {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }

  .closeBtn {
    position: fixed;
    right: 20px;
    top: 20px;
  }
}
