.main {
  width: 100%;
  height: 100%;

  padding: 59px 48px 117px 47px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  color: var(--5---Cream-white, #fffff2);

  background-color: #181716;
}

.top {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 20px;
}

.description {
  color: var(--3---pastel-brown, #928e8c);
  font-size: 14px;
  font-weight: 450;
  text-align: center;
}

.mid {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 2px;
}

.score {
  text-align: center;
  font-size: 100px;
  font-weight: 300;

  margin-top: -60px;
}

.yourScore {
  text-align: center;
  font-size: 13px;
  font-weight: 500;

  margin-top: -15px;
}

.outOf {
  color: var(--3---pastel-brown, #928e8c);
  font-size: 13px;
  font-weight: 500;
}

.scoreText {
  text-align: center;
  font-size: 20px;
  font-weight: 450;
}
