.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  color: #fffff2;
  padding-bottom: 20px;
  background-color: #2d2927;
  position: relative;
}

.main h1 {
  margin: 20px 0;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-weight: 450;
  line-height: 20px;
}

.content {
  width: 100%;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
}

.natureDesc {
  border: #464542 solid 1px;
  width: 100%;
}

.rowTop {
  border-bottom: #464542 solid 1px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  height: 40px;
}

.rowTop p {
  display: flex;
  align-items: center;
}

.row {
  display: flex;
  align-items: center;
  padding: 10px;
}

.mainNatureText {
  color: var(--5---Cream-white, #fffff2);
  font-size: 13px;
  font-weight: 500;
}

.natureCircle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 7px;
  background-color: #55504f;
}

.mainNatureDescription {
  color: var(--5---Cream-white, #fffff2);
  font-size: 14px;
  font-weight: 450;
  opacity: 0.5;
}

.breakdownText {
  color: var(--5---Cream-white, #fffff2);
  font-size: 11px;
  font-weight: 900;
  line-height: 100%;
  letter-spacing: 1.21px;

  margin-top: 21px;

  align-self: flex-start;

  padding-left: 20px;
}

.listPArent {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  padding: 0 20px;

  margin-top: 31px;

  margin-bottom: 50px;

  gap: 20px;
}

.rowParent {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: 7px;
}

.listRowTop {
  display: flex;
  width: 100%;
  align-items: center;
}

.listRowTop h3 {
  color: var(--5---Cream-white, #fffff2);
  font-size: 16px;
  font-weight: 700;
  line-height: 100%;
}

.listRowDescription {
  color: var(--3---pastel-brown, #928e8c);
  font-size: 12px;
  font-style: normal;
  font-weight: 450;
  line-height: 120%;
}

.listRowBottom {
  width: 100%;
  background-color: #55504f;
  height: 8px;
  border-radius: 8px;
  margin-top: 5px;
}

.rowScore {
  background-color: #ffffde;
  height: 100%;
  border-radius: 8px;
}

.xmark {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 35px;
  height: 35px;
  right: 15px;
  top: 10px;
}

.ghostBoxParent {
  margin-top: 150px;

  padding: 0 15px;

  display: flex;
  justify-content: center;
}

.ghostboxDiv {
  border-radius: 7px;
  border: 1px dashed rgba(255, 255, 242, 0.2);

  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  gap: 9px;

  padding: 15px 10px;
}

.iconDiv {
  display: flex;
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;

  padding: 8.679px 7.333px 9px 7.369px;

  justify-content: center;
  align-items: center;
}

.ghostboxDesc {
  color: var(--5---Cream-white, #fffff2);
  font-size: 14px;
  font-weight: 450;

  opacity: 0.8;
}

.ghostboxButton {
  border: 0;
  border-radius: 9px;

  background: var(--5---Cream-white, #fffff2);

  padding: 11px 13px;

  color: var(--0---carbon-brown, #181716);
  font-size: 14px;
  font-weight: 700;
}
