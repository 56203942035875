.backButton {
  border: 0;
  display: flex;
  padding: 8px;
  align-items: center;
  border-radius: 4px;
  background: rgba(255, 255, 242, 0.13);

  color: var(--5---Cream-white, #fffff2);
  text-align: center;
  font-size: 12px;
  font-weight: 450;
}
