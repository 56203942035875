.main {
  width: 100%;
  height: 100%;

  background: linear-gradient(90deg, #55504f, #787371, #55504f);
  background-size: 300%;
  background-position-x: 100%;
  animation: shimmer 1s infinite linear;
}

@keyframes shimmer {
  to {
    background-position-x: 0%;
  }
}
