.main {
  width: 36px;
  height: 15px;
  border-radius: 14px;
  background: linear-gradient(90deg, #8bdcff 0%, #6978ff 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.main p {
  color: #fffff2 !important;
  text-align: center !important;
  font-size: 9px !important;
  font-weight: 700 !important;
  line-height: normal !important;
  letter-spacing: -0.27px !important;
  margin: 0 !important;
  padding: 0 !important;
}
