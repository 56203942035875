.main {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 20px;
  padding: 3px;
  border-radius: 2px;
  background: #e9ab5e;

  color: #fffff2;
  font-size: 13px;
  font-weight: 700;
  line-height: 130%;
}

.mainFeature {
  color: var(--5---Cream-white, #fffff2);
  font-size: 11px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  border-radius: 4px;
  background: var(--caution-orange, #e9ab5e);

  padding: 4px 6px;

  width: fit-content;
}
