.main {
  width: 100%;
  margin-bottom: 100px;
}

.modalMain {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  /* left: 50%;
  top: 50%; */
  width: 100%;
  height: 100%;
  z-index: 10000;
  /* background: #18171691; */
}

.parent {
  width: 550px;
  display: flex;
  flex-direction: column;
  margin-left: 57px;
  margin-top: 40px;
}

.title {
  font-family: Circularstd-Book;
  font-weight: 450;
  font-size: 26px;
  line-height: 33px;
  letter-spacing: -0.01em;
  color: #fffff2;
  padding: 0 10px;
  margin-top: 15px;
}

.toolBarParent {
  margin-top: 10px;
  display: flex;
  padding: 0 10px;
}

.filterTitle {
  font-weight: 450;
  font-size: 13px;
  line-height: 16px;
  color: #fffff2;
  opacity: 0.5;
  margin-right: 17px;
}

.filterText {
  font-weight: 450;
  font-size: 13px;
  line-height: 16px;
  color: #fffff2;
  opacity: 0.5;
  margin-right: 15px;
}

.active {
  font-weight: 450;
  font-size: 13px;
  line-height: 16px;
  color: #fffff2;
  margin-right: 15px;
  opacity: 1;
}

.wrapper {
  width: 100%;
  padding: 0 10px;
  margin-top: 39px;
}

.storiesWrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

/* slug page */

.slugMain {
  width: 100%;
  background-size: cover;
  background-position: center;
  position: relative;
  height: 100vh;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 15px;
}

.slugMain h1 {
  font-weight: 300;
  font-size: 33px;
  line-height: 42px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -1.5px;
}

.slugMappedDiv {
  margin-top: 40px;
}

.descDiv {
  display: flex;
  align-items: center;
  margin-top: 16px;
  width: 370px;
}

.circle {
  min-width: 34px;
  min-height: 34px;
  border-radius: 50%;
  display: flex;
  margin-right: 14px;
  justify-content: center;
  align-items: center;
}

.circle p {
  font-weight: 900;
  font-size: 18px;
  line-height: 23px;
  color: #464542;
  border-width: 0;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  background-size: cover;
  background-position: center;
}

.descDiv h4 {
  font-weight: 450;
  font-size: 22px;
  line-height: 28px;
}

.xmark {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 35px;
  height: 35px;
  right: 30px;
  top: 39px;
}

@media only screen and (max-width: 768px) {
  .parent {
    width: 100%;
    margin-left: 0;
    margin-top: 0;
  }

  .wrapper {
    width: 100%;
    display: flex;
  }

  .storiesWrapper {
    width: 366px;
  }

  /* slug page */

  .slugMain {
    width: 100%;
  }

  .slugMappedDiv {
    margin-top: 40px;
  }

  .descDiv {
    display: flex;
    width: 100%;
  }
}
