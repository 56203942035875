.main {
  width: 100%;

  display: flex;
  align-items: flex-start;

  gap: 10px;
}

.rightDiv {
  display: flex;
  flex-direction: column;

  gap: 7px;
}

.title {
  color: var(--3---pastel-brown, #928e8c);
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 1.21px;
}

.body {
  color: var(--4---Beige, #e6e1df);
  font-size: 12px;
  font-weight: 450;
  line-height: 130%;
}

.optionWrapper {
  display: flex;
  flex-direction: column;

  gap: 5px;
}

.optionDiv {
  padding: 3px 6px;

  border-radius: 4px;

  background: var(--2---Chalk-Brown, #55504f);

  width: fit-content;
}

.option {
  color: var(--4---Beige, #e6e1df);
  font-size: 12px;
  font-weight: 450;
  line-height: 130%;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}
