.main {
  width: 100%;
  height: 3px;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
}

.selected {
  border-radius: 2px;
  height: 3px;
  flex-grow: 1;
  margin-right: 1.35px;
  background-color: #ffffde;
}

.unselected {
  border-radius: 2px;
  height: 3px;
  flex-grow: 1;
  margin-right: 1.35px;
  background-color: #ffffde;
  opacity: 0.2;
}
