.summaryTableMain {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
}

.summaryTableHeader {
  background-color: #fffff2;
  color: black;
  height: 45px;
  display: flex;
  align-items: center;
  padding: 2%;
  font-size: 26px;
  font-weight: 350;
}

.summaryTableRow {
  border: 1px solid #363634;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3%;
  font-size: 13px;
  line-height: 17.15px;
  margin-top: -1px;
}

.summaryTableTitle {
  color: var(--4---Beige, #e6e1df);
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.summaryTableValueDiv {
  display: flex;
}

.summaryTableValue {
  color: #55504f;
}

.natureCircle {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  margin-right: 10px;
  background-color: #55504f;
}

.summaryTableValueHighlighted {
  color: #e6e1df;
}

.summTableShimmer {
  width: 250px;
  height: 250px;
}

.topStrengthsValue {
  text-align: flex-end;
}

@media only screen and (max-width: 768px) {
  .summaryTableMain {
    margin-top: 10px;
    padding: 0 15px;
    margin-left: -10px;
    margin-right: -10px;
  }

  .summaryTableRow {
    margin-top: -1px;
    padding: 10px;
    font-size: 13px;
    border-width: 1px;
    white-space: nowrap;
    overflow: hidden;
  }

  .summaryTableTitle {
    margin-right: 10px;
  }

  .summaryTableValueDiv {
    max-width: calc(80%);
  }

  .topStrengths {
    font-size: 10.5px;
  }

  .topStrengthsValue {
    font-size: 10.5px;
    max-width: calc(99%);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
  }
}
