.parent {
  width: 100%;
  margin-top: 56px;
  color: #fffff2;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 768px) {
  .parent {
    padding: 0 10px;
  }
}
