.main {
  display: flex;
  align-items: center;
  justify-content: space-between;

  gap: 10px;

  border-radius: 7px;
  border: 1px dashed rgba(255, 255, 242, 0.2);

  padding: 15px 10px;
}

.text {
  color: var(--5---Cream-white, #fffff2);
  font-size: 14px;
  font-weight: 450;

  opacity: 0.8;
}

.btn {
  border: 0;
  border-radius: 9px;

  background: var(--5---Cream-white, #fffff2);

  padding: 11px 13px;

  color: var(--0---carbon-brown, #181716);
  font-size: 14px;
  font-weight: 700;
}
