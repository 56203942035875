.leftBarMain {
  color: #fffff2;
  width: 280px;
  display: flex;
  flex-direction: column;
  font-family: Circularstd-Book;
  margin-left: 54px;
  position: relative;
}

/* profileCompletion */

.mainCompletion {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 11px;

  padding: 0 20px;
}

.mainCompletion p {
  font-style: normal;
  font-weight: 450;
  font-size: 9px;
  line-height: 11px;
  display: flex;
  align-items: center;
  letter-spacing: 0.11em;
  color: #fffff2;
  opacity: 0.4;
}

.nextStepText {
  color: var(--5---Cream-white, #fffff2);
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
}

.compBarMain {
  background: #2d2927;
  /* opacity: 0.05; */
  border-radius: 4px;
  width: 100%;
  height: 6px;
  margin-top: 5px;
  position: relative;
}

.compBar {
  background: var(--subtle-yellow, #ffffde);
  border-radius: 4px;
  height: 6px;
  position: absolute;
  max-width: 100%;
}

.mainCompletion h2 {
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  line-height: 18px;
  margin-top: 5px;
}

.beta {
  display: none;
}

/* connection type bar */

.connectionTypeBarParent {
  position: absolute;
  right: -35px;
}

.typeBarMain {
  display: flex;
  align-items: center;
}

.active {
  border-radius: 50%;
  background-color: #fffff2;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  margin-left: 5px;
}

.inactive {
  border-radius: 50%;
  background-color: #928e8c;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  margin-left: 5px;
}

.activeLarge {
  border-radius: 50%;
  background-color: #fffff2;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  padding: 4px;
}

.shimmerDiv {
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 10px;

  width: 100%;

  padding-top: 13px;
  padding-bottom: 37px;
}

.imageShimmer {
  width: 164px;
  height: 164px;
}

.shimmerInfo {
  width: 149px;
  height: 40px;
}

@media only screen and (max-width: 768px) {
  .barContainer {
    flex-direction: column;
  }

  .leftBarMain {
    width: 100%;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    margin-left: 0;
    position: static;
  }

  .imageContainer {
    width: 164px;
    height: 164px;
  }

  .name {
    font-size: 24px;
    margin-top: 10px;
  }

  .publicLink {
    font-size: 16px;
    color: #fffff2;
  }

  .bio {
    display: none;
  }

  .socialLinksContainer {
    display: none;
  }

  .socialImageContainer p {
    display: none;
  }

  .socialImage {
    margin-right: 22px;
  }

  .mobileSocialLinksContainer {
    display: flex;
    margin-top: 10px;
    justify-content: flex-end;
  }

  .connectionDivList {
    position: absolute;
    top: 87px;
    left: 15px;
    margin-bottom: 20px;
  }

  .settings {
    display: block;
    position: absolute;
    top: 130px;
    right: 15px;
    font-size: 11px;
    text-align: center;
    font-weight: 350;
  }

  .connectionCount {
    font-size: 26px;
    padding-left: 0;
    text-align: center;
  }

  .connection {
    font-size: 11px;
  }

  .connectionMain {
    position: fixed;
    z-index: 10000;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: auto;
    background-color: #00000080;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .connectionBody {
    width: 350px;
    height: 410px;
    background-color: #2d2927;
    color: #2d2927;
    border-radius: 5px;
    overflow-y: scroll;
    position: relative;
    padding: 2% 0;
  }

  .connectionTopDiv {
    width: 350px;
    padding: 0 10px;
  }

  .connectionTopDiv h1 {
    font-size: 20px;
    margin-right: 25%;
  }

  .connectionCloseDiv {
    /* margin-left: auto; */
    right: 2%;

    width: 35px;
    height: 35px;
    background-color: #928e8c;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  .connectionList {
    padding: 5% 0;
  }

  .beta {
    display: flex;
    position: absolute;
    top: 105px;
  }

  .connectionTypeBarParent {
    top: 125px;
    right: 18px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1208px) {
  .leftBarMain {
    margin-left: 0;
    width: 100%;
    margin-bottom: 20px;
    align-items: center;
  }

  .imageContainer {
    width: 240px;
    height: 240px;
  }

  .bio {
    max-width: 500px;
  }

  .compBarMain {
    max-width: 500px;
  }

  .mainCompletion {
    align-items: center;
  }

  /* .connectionDivList {
    position: absolute;
    top: -33px;
    left: 15px;
    margin-bottom: 20px;
  } */
}
