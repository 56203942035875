.imageContainer {
  position: relative;

  width: 280px;
  height: 280px;
  min-height: 280px;

  border-radius: 50%;
  border-width: 14px;

  overflow: hidden;

  border-style: solid;
}

.imageContainerLarge {
  position: relative;

  width: 284px;
  height: 284px;
  min-height: 284px;

  border-radius: 50%;
  border-width: 14px;

  overflow: hidden;

  border-style: solid;
}

.image {
  border: 5px solid transparent;
  border-radius: 50%;
}

@media only screen and (max-width: 768px) {
  .imageContainer {
    width: 164px;
    height: 164px;
    min-height: 164px;

    border-width: 8px;
  }
}
