.main {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  width: 100%;
  max-width: 550px;
}

.headerModal {
  padding: 5px 10px;
  background: var(--5---Cream-white, #fffff2);

  color: var(--0---carbon-brown, #181716);
  font-size: 22px;
  font-weight: 450;
  letter-spacing: -0.22px;
}

.wrapper {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--light-outline, #464542);
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
}

.whiteLine {
  width: 1px;
  height: 74%;
  background: #fffff2;
  position: absolute;
  left: 31.5%;
  top: 31px;
  z-index: 10000;
}

.barWrap {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

.label {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 50px;
}

.bar {
  min-height: 40px;
  height: 40px;
  background: var(--2---Chalk-Brown, #55504f);
  margin-bottom: 20px;
  position: absolute;
  left: 30%;
  top: -10px;
}

.barActive {
  min-height: 40px;
  height: 40px;
  background: var(--5---Cream-white, #fffff2);
  margin-bottom: 20px;
  position: absolute;
  left: 30%;
  top: -10px;
}

.warning {
  color: var(--35-text, #c0b9b7);
  font-size: 12px;
  font-weight: 450;
  line-height: 130%;
  margin-top: 30px;
  margin-bottom: 20px;
}

.headerReports {
  width: 100%;
  height: 45px;
  background: var(--1---Dark-brown, #2d2927);

  color: var(--5---Cream-white, #fffff2);
  font-size: 22px;
  font-weight: 450;
  letter-spacing: -0.22px;

  display: flex;
  align-items: center;

  padding: 0 10px;
}
