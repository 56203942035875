.main {
  width: 500px;
  min-height: 55px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 8px 15px;
  z-index: 100000000;
  position: fixed;
  top: -100px;
  left: 190px;
  transition: all 0.3s;
}

.mainClickable {
  width: 550px;
  min-height: 55px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  padding: 8px 15px;
  z-index: 100000000;
  position: fixed;
  top: -100px;
  left: 190px;
  transition: all 0.3s;
}

.IconDiv {
  width: 27px;
  height: 27px;
  min-width: 27px;
  min-height: 27px;
}

.message {
  color: #fffff2;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-left: 15px;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 11px 13px;

  border: 0;
  border-radius: 9px;
  background-color: #fffff2;

  color: var(--0---carbon-brown, #181716);
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  white-space: nowrap;

  width: fit-content;

  margin-left: auto;
}

@media only screen and (max-width: 768px) {
  .main {
    width: 100%;
    left: 0;
  }

  .mainClickable {
    width: 100%;
    left: 0;
  }
}
