.main {
  width: 355px;
  height: 175px;
  border-radius: 12px;
  border: 1px dashed var(--light-outline, #464542);
  padding: 0 18px;
}

.mainStories {
  width: 354px;
  height: 175px;

  border-radius: 12px;
  border-radius: 12px;

  padding: 0 18px;

  background: #2d2927;

  position: relative;
}

.title {
  font-size: 20px;
  font-weight: 450;
  margin-top: 14px;
}

.subtitle {
  margin-top: 4px;
  font-size: 12px;
  font-weight: 450;
  opacity: 0.5;
}

.emojiParentDiv {
  display: flex;
  margin-top: 28px;
}

.emojiBox {
  width: 72px;
  height: 72px;
  border-radius: 7px;
  border: 1px solid var(--light-outline, #464542);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 5px;
  margin-right: 10px;
}

.emojiBoxSelected {
  width: 72px;
  height: 72px;
  border-radius: 7px;
  border: 1px solid var(--light-outline, #fffff2);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 5px;
  margin-right: 10px;
}

.emoji {
  font-size: 24px;
  font-weight: 450;
  margin-top: 2px;
  font-family: Circularstd-Book;
}

.desc {
  font-size: 12px;
  font-weight: 450;
  margin-top: 2px;
}

.scoreBarParent {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 6px;
}

.scoreBarActive {
  background-color: #fffff2;
  width: 14px;
  height: 4px;
  margin-right: 2px;
}

.scoreBarInactive {
  background-color: #464542;
  width: 14px;
  height: 4px;
  margin-right: 2px;
}

.closeButton {
  position: absolute;
  right: -2px;
  top: -10px;
}

.closeButtonSnippets {
  position: absolute;
  right: 5px;
  top: -11px;
}
