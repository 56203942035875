.messageDiv {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.dailyInsightsDiv {
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 10px;

  padding: 15px;

  border-radius: 20px;
  border: 1px solid var(--light-outline, #464542);

  width: 95%;
}

.iconDiv {
  align-self: center;
}

.dailyInsightText {
  color: var(--35-text, #c0b9b7);
  font-size: 14px;
  font-weight: 450;
  line-height: 140%;
}

.dimensionSummaryDiv {
  display: flex;
  align-items: center;

  gap: 10px;

  padding: 15px;

  border-radius: 20px;
  border: 1px solid var(--35-text, #c0b9b7);
}

.dimensionIconDiv {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 40px;
  height: 40px;
}

.dimensionDescDiv {
  display: flex;
  flex-direction: column;

  gap: 2px;
}

.dimensionTitle {
  font-size: 18px;
  font-weight: 450;
  line-height: 140%;
  letter-spacing: -0.18px;
}

.dimensionText {
  color: var(--3---pastel-brown, #928e8c);
  font-size: 11px;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: 1.21px;
}
