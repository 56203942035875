.main {
  display: flex;
  flex-direction: column;

  padding-top: 20px;
  padding-bottom: 17.5px;
  padding-left: 15px;
  padding-right: 15px;

  border-radius: 15px;
  border: 1px solid var(--light-outline, #464542);

  width: 100%;
}

.sponsoredText {
  color: var(--5---Cream-white, #fffff2);
  font-size: 9px;
  font-weight: 500;

  opacity: 0.5;
}

.iconDiv {
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 19px;
}

.title {
  padding: 0 15px;
  margin-top: 18px;

  color: var(--5---Cream-white, #fffff2);
  text-align: center;
  font-size: 24px;
  font-weight: 450;
  letter-spacing: -0.72px;
}

.ul {
  list-style-position: inside;

  margin-top: 18px;

  padding: 0 5px;
}

.li {
  color: var(--35-text, #c0b9b7);
  font-size: 14px;
  font-weight: 450;

  margin-top: 2px;
}

.button {
  border: 0;
  border-radius: 9px;

  background: var(--5---Cream-white, #fffff2);

  padding: 11px 13px;

  color: var(--0---carbon-brown, #181716);
  font-size: 14px;
  font-weight: 700;

  width: fit-content;

  align-self: center;

  margin-top: 27px;
}

.partnershipText {
  color: var(--3---pastel-brown, #928e8c);
  text-align: center;
  font-size: 10px;
  font-weight: 450;

  margin-top: 17.5px;
}
