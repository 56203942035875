.main {
  display: flex;
  flex-direction: column;

  position: relative;
}

.header {
  position: sticky;
  top: -2px;

  display: flex;
  flex-direction: column;

  padding-top: 44px;

  background: var(--0---carbon-brown, #181716);

  z-index: 10000000000000;
}

.quizTitle {
  color: var(--5---Cream-white, #fffff2);
  text-align: center;
  font-size: 16px;
  font-weight: 450;

  align-self: center;

  max-width: 258px;
}

.quizSubTitle {
  color: var(--3---pastel-brown, #928e8c);
  text-align: center;
  font-size: 12px;
  font-weight: 450;

  align-self: center;

  margin-top: 1px;
}

.downArrorDiv {
  width: 30px;
  height: 30px;

  transform: rotate(-90deg);

  position: absolute;
  top: 40px;
  left: 11px;

  padding: 5px;
}

.progressBar {
  height: 1px;
  background: #464542;

  margin-top: 4px;

  position: relative;
}

.titleSection {
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 20px;

  padding-top: 35px;
}

.emojiString {
  color: #000;
  text-align: center;
  font-size: 50px;
  font-weight: 450;
  line-height: 130%;
}

.quizTitleTitleSection {
  max-width: 345px;

  color: var(--5---Cream-white, #fffff2);
  text-align: center;
  font-size: 30px;
  font-weight: 450;
  line-height: 130%;
  letter-spacing: -0.3px;
}

.unknownSection {
  padding: 0 15px;

  margin-top: 50px;

  display: flex;
  flex-direction: column;
}

.unknownBox {
  border-radius: 20px;
  border: 1px dashed var(--light-outline, #464542);

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 30px 0;
}

.unknownBigText {
  color: var(--35-text, #c0b9b7);
  font-size: 90px;
  font-weight: 450;
  line-height: 130%;
  letter-spacing: -0.9px;
}

.unknownScoreSmallText {
  color: var(--5---Cream-white, #fffff2);
  font-size: 11px;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: 0.33px;
}

.unknownButtonTakeTheQuiz {
  margin-top: 20px;

  border: 0;
  border-radius: 9px;

  background: var(--5---Cream-white, #fffff2);

  padding: 11px 13px;

  color: var(--0---carbon-brown, #181716);
  font-size: 14px;
  font-weight: 700;
}

.scoreBoxSection {
  display: flex;
  flex-direction: column;

  margin-top: 50px;

  padding: 0 15px;
}

.scoreBox {
  border-radius: 20px;
  border: 1px solid var(--light-outline, #464542);

  display: flex;
  flex-direction: column;

  gap: 20px;

  padding: 30px 0px;
}

.scoreTextBig {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.score {
  color: var(--3---pastel-brown, #928e8c);
  font-size: 20px;
  font-weight: 450;
  line-height: 130%;
  letter-spacing: -0.2px;
}

.scoreBig {
  color: var(--5---Cream-white, #fffff2);
  text-align: center;
  font-size: 90px;
  font-weight: 450;
  line-height: 130%;
  letter-spacing: -0.9px;
}

.scoreName {
  color: var(--5---Cream-white, #fffff2);
  text-align: center;
  font-size: 11px;
  font-weight: 700;
  line-height: 130%; /* 14.3px */
  letter-spacing: 0.33px;
}

.percentileDescriptionTemplate {
  max-width: 305px;

  color: var(--3---pastel-brown, #928e8c);
  text-align: center;
  font-family: AkkuratStd;
  font-size: 14px;
  font-weight: 400;
  line-height: 130%;

  align-self: center;
}

.histogramWrapper {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  padding: 0 13px;

  min-height: 201px;
  max-height: 201px;

  position: relative;

  overflow-x: hidden;
}

.histogramBar {
  min-width: 14px;
  background: #ffffde;
  border-radius: 24px;
  max-height: 100%;
}

.histogramLabelWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 13px;

  margin-top: 10px;
}

.histogramLabel {
  color: var(--3---pastel-brown, #928e8c);
  text-align: right;
  font-size: 14px;
  font-weight: 450;
}

.averageScoreDiv {
  width: 2px;
  position: absolute;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  bottom: 0;

  padding: 0 13px;
}

.userScoreLine {
  width: 2px;
  height: 100%;
}

.userScoreCircle {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.userScoreCircleShare {
  border-radius: 50%;
  width: 52px;
  height: 52px;
  min-width: 52px;
  min-height: 52px;

  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
}

.userScoreCircle img {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  border: 1px solid black;
}

.userScoreCircleShare img {
  border-radius: 50%;
  border: 1px solid black;
}

.circle {
  width: 11px;
  height: 11px;

  border-radius: 50%;
}

.collectingGhostBoxSection {
  padding: 0 10px;
}

.collectingGhostBox {
  border-radius: 7px;
  border: 1px dashed rgba(255, 255, 242, 0.2);

  display: flex;
  align-items: center;

  gap: 10px;

  padding: 15px 10px;
}

.collectingGhostBoxText {
  width: 271px;

  font-size: 12px;
  font-weight: 450;

  opacity: 0.8;
}

.labelWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  flex-wrap: wrap;

  gap: 5px 20px;

  padding: 0 10px;

  min-height: 41px;
}

.labelDiv {
  display: flex;
  align-items: center;

  gap: 3.5px;
}

.legendText {
  color: var(--35-text, #c0b9b7);
  font-family: AkkuratStd;
  font-size: 14px;
  font-weight: 400;
  line-height: 130%;
}

.filterParent {
  display: flex;
  flex-wrap: wrap;

  gap: 10px;

  padding: 0 10px;
}

.showAveragesByText {
  font-size: 13px;
  font-weight: 450;

  white-space: nowrap;
}

.filterText {
  color: var(--3---pastel-brown, #928e8c);
  font-size: 13px;
  font-weight: 700;
}

.postResultsSection {
  display: flex;
  align-items: center;

  justify-content: center;

  gap: 6px;

  margin-top: 50px;
}

.connectionScoresSection {
  padding: 0 15px;

  display: flex;
  flex-direction: column;

  gap: 5px;

  margin-top: 50px;
}

.connectionResultsTitle {
  padding-left: 10px;

  font-size: 20px;
  font-weight: 450;
}

.connectionScoresWrapper {
  display: flex;
  flex-direction: column;

  margin-top: 15px;
}

.connectionScoreBox {
  display: flex;
  align-items: center;

  border-top: 1px solid var(--light-outline, #464542);

  padding: 15px 0px;
}

.connectionUsername {
  color: var(--35-text, #c0b9b7);
  font-size: 14px;
  font-weight: 700;

  margin-left: 6px;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

.caption {
  font-size: 14px;
  font-weight: 450;
}

.connectionScore {
  color: var(--3---pastel-brown, #928e8c);
  font-size: 16px;
  font-weight: 450;

  margin-left: auto;
}

.connectionScoreSpan {
  color: var(--5---Cream-white, #fffff2);
  font-size: 16px;
  font-weight: 450;
}

.logoDiv {
  display: flex;
  justify-content: center;
  align-items: center;

  padding-top: 36px;
}

.postButtonSection {
  margin-top: 50px;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 150px;
}

.interactionBarDiv {
  position: sticky;
  bottom: 0;
  left: 0;

  width: 100%;
}
