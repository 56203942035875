.main {
  width: 100%;
  display: flex;
  align-items: center;
}

.imgDiv {
  display: block;
  width: 52px;
  height: 52px;
  border-radius: 50%;
}

.imgDiv img {
  border-radius: 50%;
  border: 1px solid black;
}

.scoreDiv {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.scoreText {
  font-size: 16px;
  font-weight: 450;
  color: #fffff2;
}

.scoreWrapper {
  display: flex;
  padding-top: 6px;
  padding-bottom: 3px;
  margin-top: 2px;
  position: relative;
}

.selected {
  border-radius: 2px;
  height: 6px;
  width: 36px;
  margin-right: 4px;
  background-color: #ffffde;
}

.unselected {
  border-radius: 2px;
  height: 6px;
  width: 36px;
  margin-right: 4px;
  background-color: #ffffde;
  opacity: 0.2;
}

.scoreDescDiv {
  display: flex;
  align-self: flex-end;
  align-items: baseline;
  position: relative;
  margin-bottom: 8px;
  margin-left: 2px;
}

.scoreDescDiv h4 {
  color: #fffff2;
  text-align: right;
  font-size: 12px;
  font-weight: 700;
  line-height: 100%;
  opacity: 0.5;
  backdrop-filter: blur(5.436563491821289px);
}

.iconDiv {
  display: flex;
  flex-direction: column;
  margin-left: 4px;
  position: relative;
  top: -2px;
}

.arrow {
  width: 9px;
  height: 5px;
}

.neutral {
  width: 9px;
  height: 1.714px;
  border-radius: 2.143px;
  background: #fffff2;
}

.restrictIcon {
  position: absolute;
  width: 18px;
  height: 18px;
  left: 45%;
  top: 0;
}

.premiumIcon {
  position: absolute;
  width: auto;
  height: 18px;
  left: 40%;
  top: 0;
}

@media only screen and (max-width: 768px) {
  .main {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .imgDiv {
    display: block;
    width: 52px;
    height: 52px;
    border-radius: 50%;
  }

  .imgDiv img {
    border-radius: 50%;
    border: 1px solid black;
  }

  .scoreDiv {
    margin-left: 0;
  }

  .scoreDescDiv {
    margin-bottom: 9px;
  }

  .selected {
    width: 40px;
  }

  .unselected {
    width: 40px;
  }
}
