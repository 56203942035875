.main {
  display: flex;
  flex-direction: column;

  padding-bottom: 120px;

  color: #fffff2;
}

.top {
  margin-top: 38px;

  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;

  gap: 10px;

  width: 100%;
}

.iconDiv {
  width: 80px;
  height: 80px;

  display: flex;
  align-items: center;
  justify-content: center;

  border: #fffff2 4.5px solid;

  border-radius: 50%;

  padding: 15px;
}

.titleAndDescSection {
  margin-top: 50px;

  display: flex;
  flex-direction: column;
  justify-content: center;

  gap: 10px;

  padding: 0 20px;
}

.title {
  font-size: 36px;
  font-style: normal;
  font-weight: 450;
  line-height: 110%;
  letter-spacing: -0.72px;
}

.desc {
  color: var(--35-text, #c0b9b7);
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 130%;
}

.summarySection {
  margin-top: 50px;
}

.socialSection {
  margin-top: 70px;

  display: flex;
  flex-direction: column;

  gap: 7px;
}

.socialButtonsDiv {
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 10px;
}

.shareToText {
  color: var(--3---pastel-brown, #928e8c);
  text-align: center;
  font-size: 10px;
  font-weight: 450;
  letter-spacing: -0.371px;
}

.descriptionSection {
  margin-top: 70px;

  padding: 0 15px;

  display: flex;
  flex-direction: column;

  gap: 20px;

  margin-bottom: 70px;
}

.dimensionDetailsTitle {
  color: var(--5---Cream-white, #fffff2);
  font-size: 26px;
  font-weight: 450;
}

.dimensionDetailsDesc {
  color: var(--35-text, #c0b9b7);
  font-size: 16px;
  font-weight: 450;
  line-height: 140%;
}

.suggestedMessagesSection {
  display: flex;
  flex-direction: column;

  gap: 20px;

  padding: 0 15px;
}

.suggestedMessagesTitleDiv {
  display: flex;
  align-items: center;

  gap: 10px;
}

.suggestedMessagesTitle {
  font-size: 26px;
  font-weight: 450;
}

.suggestedMessagesWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  gap: 15px;
}

.suggestedMessageDiv {
  padding: 11px 15px;

  border-radius: 20px;
  border: 1px dashed var(--Hyperlink-blue, #2f6fd3);

  max-width: 80%;
}

.suggestedMessage {
  color: var(--4---Beige, #e6e1df);
  font-size: 15px;
  font-weight: 450;
  letter-spacing: -0.371px;
}

.spectrumsDiv {
  padding: 0 10.5px;
}

.twoByTwoDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.scoreAndDescDiv {
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0 15px;
}

.interractionBarDiv {
  position: absolute;
  bottom: 0;
  left: 0;

  width: 100%;

  z-index: 10000;
}
