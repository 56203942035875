.modalMain {
  padding: 15px 10px;
}

.feedbackBarMain {
  border-radius: 8px;
  background: linear-gradient(90deg, #19896e 0.74%, #229d3d 100.74%);

  padding: 15px;

  display: flex;
  flex-direction: column;

  cursor: pointer;
}

.barTitle {
  font-size: 16px;
  font-weight: 500;
}

.rewardsLevelParent {
  margin-top: 11px;
}

.barMidDiv {
  display: flex;
  align-items: center;
  gap: 8px;

  margin-top: 11px;
}

.barMidDivText {
  color: var(--5---Cream-white, #fffff2);
  font-size: 9px;
  font-weight: 500;
  letter-spacing: 0.99px;
}

.imageWrapper {
  display: flex;
  align-items: center;
  justify-content: space-around;

  gap: 16px;

  margin-top: 16px;
}

.askConnectionBUtton {
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 30px;
  padding: 11px 13px;

  border-radius: 9px;
  background: var(--5---Cream-white, #fffff2);

  border: 0;

  color: var(--0---carbon-brown, #181716);
  text-align: center;
  font-size: 14px;
  font-weight: 700;
}

.askSomeoneText {
  color: var(--5---Cream-white, #fffff2);
  font-size: 14px;
  font-weight: 700;

  align-self: stretch;

  margin-top: 30px;
}

.linkDiv {
  margin-top: 8px;
  width: 100%;

  position: relative;
}

.linkInput {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 6px 7px 6px 9.5px;

  border-radius: 7px;
  border: none;

  background: #00000094;
  background-blend-mode: multiply;

  min-height: 48px;
  width: 100%;

  color: var(--5---Cream-white, #fffff2);
  font-size: 14px;
  font-weight: 450;
}

.linkInput::placeholder {
  color: var(--5---Cream-white, #fffff2);
  font-size: 14px;
  font-weight: 450;
  opacity: 0.5;
}

.linkButton {
  padding: 5px 7px;

  border-radius: 4px;
  border: 1px solid var(--5---Cream-white, #fffff2);

  background-color: transparent;

  color: var(--5---Cream-white, #fffff2);
  text-align: center;
  font-size: 14px;
  font-weight: 450;

  position: absolute;

  top: 10px;
  right: 7px;

  display: flex;
  justify-content: center;
  align-items: center;

  min-width: 139px;
}

.linkWarning {
  color: var(--3---pastel-brown, #928e8c);
  text-align: center;
  font-size: 12px;
  font-weight: 450;
  mix-blend-mode: multiply;

  align-self: center;

  margin-top: 8px;
}

.modalTitle {
  font-size: 26px;
  font-weight: 450;
  letter-spacing: -0.52px;
  margin-top: 25px;
}

.levelHeading {
  color: var(--5---Cream-white, #fffff2);
  font-size: 20px;
  font-weight: 500;
}

.mid {
  display: flex;
  flex-direction: column;

  padding: 0 5px;
}

.levelHeaderWrapper {
  display: flex;
  align-items: center;

  gap: 10px;
  margin-top: 50px;
}

.levelSubDiv {
  display: flex;
  align-items: center;

  gap: 10px;

  margin-top: 10px;
}

.levelSubTitle {
  color: var(--35-text, #c0b9b7);
  font-size: 14px;
  font-weight: 450;
}

.levelWrapper {
  display: flex;
  align-items: center;

  gap: 10px;
  margin-top: 10px;

  overflow-x: auto;
}

@media only screen and (max-width: 768px) {
  .levelWrapper::-webkit-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
    display: none;
  }
}
