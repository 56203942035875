.main {
  display: flex;
  flex-direction: column;

  height: 100%;

  position: relative;

  padding: 0 20px;

  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.closeBtn {
  position: absolute;
  top: 56px;
  right: 30px;
}

.bottom {
  margin: 0 -20px;
  margin-top: auto;

  padding: 0 20px;

  padding-top: 57px;
  padding-bottom: 26px;

  display: flex;
  flex-direction: column;

  gap: 22px;

  background: linear-gradient(180deg, rgba(24, 23, 22, 0) 0%, #181716 100%);
}

.bottomIconContainersParent {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  gap: 20px;
}

.bottomContentDiv {
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 5px;
}

.likeAndCommentCountText {
  text-align: center;
  font-size: 12px;
  font-weight: 450;
}

.bottomImageAndCaptionDiv {
  display: flex;
  align-items: center;

  gap: 10px;
}

.bottomImageAndProfileDiv {
  display: flex;
  flex-direction: column;

  gap: 2px;
}

.usernameText {
  font-size: 15px;
  font-weight: 700;
}

.captionText {
  font-size: 13px;
  font-weight: 450;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* daily insights */

.dialyInsightsDiv {
  display: flex;
  flex-direction: column;

  gap: 35px;

  margin-top: 56px;
}

.dailyInsightsTopDiv {
  display: flex;
  align-items: center;

  gap: 10px;
}

.dailyInsightName {
  color: var(--3---pastel-brown, #928e8c);
  font-size: 11px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.21px;
}

.dailyInsightBody {
  color: var(--4---Beige, #e6e1df);
  font-size: 16px;
  font-weight: 450;
  line-height: 140%;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 16;
  overflow: hidden;
  text-overflow: ellipsis;
}

.beacuseOfText {
  color: var(--3---pastel-brown, #928e8c);
  font-size: 11px;
  font-weight: 500;
  line-height: 130%;
  letter-spacing: 1.21px;
}

.mappedScalesWrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  gap: 5px;
}

.scale {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 8px;

  gap: 5px;

  border-radius: 4px;
  border: 2px solid gray;

  background: var(--2---Chalk-Brown, #55504f);

  width: fit-content;
}

.scaleName {
  font-size: 12px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
}

/* daily stories */
.dailyStoryMainDiv {
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 210px;

  padding-top: 120px;
}

.storyTitle {
  border-radius: 4px;

  background: #fffff2;

  width: fit-content;

  color: var(--0---carbon-brown, #181716);
  text-align: center;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 1.21px;
}

.dailyStoryBodyMapperDiv {
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 6px;

  min-width: 88%;
  max-width: 88%;
}

.storyBody {
  padding: 2px 4px;

  border-radius: 5px;

  background: var(--5---Cream-white, #fffff2);

  color: var(--0---carbon-brown, #181716);
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  line-height: 140%;
  letter-spacing: -0.6px;

  width: fit-content;

  align-self: center;
}

/* quiz */

.periodicQuizDiv {
  padding: 30px 0;

  display: flex;
  flex-direction: column;

  gap: 20px;

  margin-top: auto;
}

.histogramWrapper {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  padding: 0 13px;

  min-height: 201px;
  max-height: 201px;

  position: relative;

  overflow-x: hidden;
}

.histogramBar {
  min-width: 14px;
  background: #ffffde;
  border-radius: 24px;
  max-height: 100%;
}

.histogramLabelWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 13px;

  margin-top: 10px;
}

.histogramLabel {
  color: var(--3---pastel-brown, #928e8c);
  text-align: right;
  font-size: 14px;
  font-weight: 450;
}

.averageScoreDiv {
  width: 2px;
  position: absolute;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  bottom: 0;

  padding: 0 13px;
}

.userScoreLine {
  width: 2px;
  height: 100%;
}

.userScoreCircleShare {
  border-radius: 50%;
  width: 52px;
  height: 52px;
  min-width: 52px;
  min-height: 52px;

  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
}

.userScoreCircleShare img {
  border-radius: 50%;
  border: 1px solid black;
}

.scoreTextBig {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.score {
  color: var(--3---pastel-brown, #928e8c);
  font-size: 20px;
  font-weight: 450;
  line-height: 130%;
  letter-spacing: -0.2px;
}

.scoreBig {
  color: var(--5---Cream-white, #fffff2);
  text-align: center;
  font-size: 90px;
  font-weight: 450;
  line-height: 130%;
  letter-spacing: -0.9px;
}

.scoreName {
  color: var(--5---Cream-white, #fffff2);
  text-align: center;
  font-size: 11px;
  font-weight: 700;
  line-height: 130%; /* 14.3px */
  letter-spacing: 0.33px;
}
