.parent {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  border: 0.5px solid #fffff257;
}

.parentModal {
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  width: 100%;
  padding-bottom: 5px;
}

.header {
  font-size: 20px;
  line-height: 25px;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: #fffff2;
  background: #181716;
  border: 1px solid #464542;
  padding: 2%;
}

.headerModal {
  padding: 5px 10px;
  background: var(--5---Cream-white, #fffff2);

  color: var(--0---carbon-brown, #181716);
  font-size: 22px;
  font-weight: 450;
  letter-spacing: -0.22px;
}

.row {
  padding: 2%;
  position: relative;
  margin: 2.5px 0;
}

.rowParent {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  line-height: 130%;
  color: #fffff2;
}

.unselected {
  opacity: 0.5;
  margin-bottom: 8px;
}

.selected {
  opacity: 1;
  margin-bottom: 8px;
}

.lineParent {
  display: flex;
  position: relative;
}

.lineRight {
  height: 13px;
  width: 1px;
  background-color: #fffff257;
}

.lineMiddle {
  width: 99%;
  border-bottom: 1px #fffff257 solid;
  margin-bottom: 6.5px;
}

.lineLeft {
  height: 13px;
  width: 1px;
  background-color: #fffff257;
}

.lineMid {
  height: 13px;
  width: 1px;
  background-color: #fffff257;
  position: absolute;
  left: 50%;
  bottom: 0;
}

.personDiv {
  position: absolute;
  bottom: 0;
}

@media only screen and (max-width: 768px) {
}
