.main {
  height: 83px;
  max-height: 83px;
  display: flex;
  justify-content: center;
  border-bottom: 3px solid #464542;
  width: 100%;
  position: relative;
}

.mainModal {
  height: 83px;
  max-height: 83px;
  display: flex;
  flex-direction: column;
  border-bottom: 3px solid #464542;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 100000;
  background-color: inherit;
}

.mainResults {
  height: 90px;
  max-height: 100px;
  width: 100%;

  display: flex;
  justify-content: center;

  position: relative;
}

.main h1 {
  font-family: Circularstd-Book;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #fffff2;
  opacity: 0.9;
  margin-top: 45px;
}

.mainResults h1 {
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;

  margin-top: 39px;
}

.title {
  font-family: Circularstd-Book;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #fffff2;
  opacity: 0.9;
  margin-top: 45px;
}

.subTitle {
  color: var(--5---Cream-white, #fffff2);
  text-align: center;
  font-size: 12px;
  font-weight: 450;
  opacity: 0.5;
  margin-top: 4px;
}

.modalTitle {
  font-family: Circularstd-Book;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #fffff2;
  opacity: 0.9;
  margin-top: 31px;
}

.subTitle {
  color: var(--5---Cream-white, #fffff2);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  opacity: 0.5;
  margin-top: 4px;
  margin-bottom: 10px;
}

.backDiv {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 15px;
  top: 30px;
  z-index: 12;
}

.backDivSmall {
  position: absolute;
  width: 18px;
  height: 18px;
  left: 15px;
  top: 30px;
  z-index: 12;
}

.backDivDown {
  position: absolute;
  width: 18px;
  height: 18px;
  left: 15px;
  top: 35px;
  transform: rotate(-90deg);
  z-index: 12;
}

.resultsSearchIconDiv {
  position: absolute;
  top: 25px;
  right: 15px;
}

.chatBackButtonDiv {
  width: 30px;
  height: 30px;

  position: absolute;
  left: 17px;
  top: 30px;
}

.chatMessageTitleDiv {
  display: flex;
  align-items: center;

  gap: 9px;
}

.infoDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;

  margin-left: 9px;
}

.namesDiv {
  display: flex;
  align-items: center;

  gap: 2px;
}

.name {
  color: var(--5---Cream-white, #fffff2);
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.25);
  font-size: 16px;
  font-weight: 450;
}

.username {
  opacity: 0.9;

  color: var(--3---pastel-brown, #928e8c);
  font-size: 16px;
  font-weight: 450;
}

.connectionType {
  color: var(--3---pastel-brown, #928e8c);
  font-size: 13px;
  font-weight: 450;
}

.iconDiv {
  border-radius: 50%;
  width: 44px;
  height: 44px;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: #464542;

  position: relative;
  position: absolute;
  top: 25px;
  left: 15px;
}

@media only screen and (max-width: 768px) {
  .main {
    height: 77px;
  }

  .main h1 {
    font-family: Circularstd-Book;
    font-weight: 450;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    color: #fffff2;
    opacity: 0.9;
    margin-top: 30px;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1208px) {
  .main {
    justify-content: flex-start;
  }

  .main h1 {
    margin-left: 20%;
  }
}
