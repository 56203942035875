.parent {
  display: flex;
  flex-direction: column;
  width: 345px;
}

.row {
  width: 345px;
  height: 80px;
  border: 1px solid #e6e1df;
  display: flex;
  align-items: center;
}

.wrapperCircle {
  display: inline-block;
}

.mainCircle {
  margin-left: 14px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  position: relative;
  background-color: rgba(0, 0, 0, 0);
  box-sizing: content-box !important;
}

.details {
  margin-left: 14px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.top {
  display: flex;
}

.top h3 {
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  color: #fffff2;
}

.mid {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.scoreWrapper {
  display: flex;
  padding-top: 6px;
  padding-bottom: 3px;
  margin-top: 2px;
  position: relative;
}

.selected {
  border-radius: 2px;
  height: 6px;
  width: 36px;
  margin-right: 4px;
  background-color: #ffffde;
}

.unselected {
  border-radius: 2px;
  height: 6px;
  width: 36px;
  margin-right: 4px;
  background-color: #ffffde;
  opacity: 0.2;
}

.scoreTextDiv {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.iconDiv {
  display: flex;
  flex-direction: column;
  margin-left: 4px;
  position: relative;
  top: -8px;
}

.arrow {
  width: 9px;
  height: 5px;
}

.neutral {
  width: 9px;
  height: 1.714px;
  border-radius: 2.143px;
  background: #fffff2;
}

.scoreText {
  color: #fffff2;
  text-align: right;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  opacity: 0.5;
  backdrop-filter: blur(5.436563491821289px);
}

.caption {
  margin-top: 7px;
  font-weight: 450;
  font-size: 12px;
  line-height: 15px;
  color: #fffff2;
  mix-blend-mode: normal;
  opacity: 0.5;
}

.patternDiv {
  padding-top: 10px;
}
