.main {
  height: 430px;
  width: 100%;

  border-radius: 15px;
  border: 1px solid var(--light-outline, #464542);

  padding: 25px 15px 20px 15px;

  display: flex;
  flex-direction: column;
  align-items: center;

  overflow-x: hidden;
}

.title {
  text-align: center;
  font-size: 26px;
  font-weight: 450;
  letter-spacing: -0.26px;
}

.imageDiv {
  margin-top: 40px;
}

.subtitle {
  text-align: center;
  font-size: 20px;
  font-weight: 450;
  letter-spacing: -0.4px;

  margin-top: 37px;
}

.desc {
  color: var(--35-text, #c0b9b7);
  text-align: center;
  font-size: 14px;
  font-weight: 450;

  margin-top: 5px;
}

.btnDiv {
  display: flex;
  align-items: center;

  gap: 5px;

  margin-top: 39px;
}

.searchUsersBtn {
  width: 163px;
  height: 44px;

  border-radius: 7px;
  border: 0;

  background: var(--2---Chalk-Brown, #55504f);
  backdrop-filter: blur(5.436563491821289px);

  color: rgba(255, 255, 255, 0.9);
  text-align: center;
  font-size: 16px;
  font-weight: 450;
}

.inviteBtn {
  width: 163px;
  height: 44px;

  border-radius: 7px;
  border: 0;

  background: var(--subtle-yellow, #ffffde);
  backdrop-filter: blur(5.436563491821289px);

  color: var(--0---carbon-brown, #181716);
  text-align: center;
  font-size: 16px;
  font-weight: 450;
}

.skip {
  cursor: pointer;

  margin-top: 32px;

  text-align: center;
  font-size: 16px;
  font-weight: 400;
  opacity: 0.5;
}
