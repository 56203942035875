.main {
  width: 100%;
  min-height: 101px;

  display: flex;
  align-items: center;

  padding-right: 20px;
  padding-left: 15px;

  gap: 10px;

  border-top: 1px solid var(--light-outline, #464542);
  background: var(--0---carbon-brown, #181716);
}

.mainStories {
  width: 100%;
  min-height: 101px;

  display: flex;
  align-items: center;

  padding-right: 20px;
  padding-left: 15px;

  gap: 10px;
}

.mainDimensionOutro {
  width: 100%;
  min-height: 101px;

  display: flex;
  align-items: center;

  border-top: 1px solid var(--light-outline, #464542);
  background: var(--0---carbon-brown, #181716);

  padding: 10px 20px;

  gap: 10px;
}

.buttonDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 6px;

  width: 50px;
}

.text {
  color: var(--4---Beige, #e6e1df);
  text-align: center;
  font-size: 10px;
  font-weight: 450;
  letter-spacing: -0.371px;
}

.shareDivParent {
  margin-left: auto;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  gap: 7px;

  padding-top: 12px;
}

.shareDiv {
  display: flex;
  align-items: center;

  gap: 10px;
}

.shareDivParentPeerStories {
  margin-left: auto;

  padding: 12px;

  border-radius: 12px;
  background: var(--5---Cream-white, #fffff2);

  display: flex;
  justify-content: center;
  align-items: center;
}

.shareToText {
  color: var(--3---pastel-brown, #928e8c);
  font-size: 10px;
  font-weight: 450;
  letter-spacing: -0.371px;
}

.savedToText {
  font-size: 12px;
  font-weight: 450;
}

.continueButton {
  border: 0;
  border-radius: 9px;

  width: 144px;

  padding: 11px 13px;

  background: var(--5---Cream-white, #fffff2);

  height: fit-content;

  color: var(--0---carbon-brown, #181716);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  margin-left: auto;
}

.commentDiv {
  position: relative;
}

.commentCount {
  position: absolute;
  top: -8px;
  right: -6px;

  color: var(--5---Cream-white, #fffff2);
  font-size: 10px;
  font-weight: 700;
  text-align: center;

  border-radius: 50%;
  background-color: #2cb0ff;

  padding: 3px;

  width: 15px;
  height: 15px;

  display: flex;
  justify-content: center;
  align-items: center;
}
