.main {
  width: 325px;
  height: 367px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.label {
  color: var(--5---Cream-white, #fffff2);
  text-align: center;
  -webkit-text-stroke-width: 1;
  -webkit-text-stroke-color: var(--0---carbon-brown, #181716);
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.labelLeft {
  color: var(--5---Cream-white, #fffff2);
  text-align: center;
  -webkit-text-stroke-width: 1;
  -webkit-text-stroke-color: var(--0---carbon-brown, #181716);
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  position: absolute;
  left: 0;
  top: 145.5px;
}

.labelRight {
  color: var(--5---Cream-white, #fffff2);
  text-align: center;
  -webkit-text-stroke-width: 1;
  -webkit-text-stroke-color: var(--0---carbon-brown, #181716);
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  position: absolute;
  right: 0;
  top: 145.5px;
}

.parent {
  width: 325px;
  height: 367px;

  position: relative;
  align-self: center;
  display: flex;
  flex-direction: column;
}

.midCircle {
  position: absolute;
  left: 108.5px;
  bottom: 119px;

  width: 109px;
  height: 109px;

  border-radius: 50%;
  border: 1px dashed #fffff2;

  opacity: 0.5;
}

.verticalLine {
  width: 1px;
  height: 325px;
  background: #fffff2;
  align-self: center;

  margin-top: 6px;
  margin-bottom: 6px;
}

.horizontalLine {
  height: 1px;
  width: 325px;
  background: #fffff2;
  align-self: center;

  position: absolute;
  top: 162.5px;
}

.quadrantLabelOne {
  color: var(--5---Cream-white, #fffff2);
  text-align: center;
  text-shadow: 0px 0px 8px #000;
  -webkit-text-stroke-width: 1;
  -webkit-text-stroke-color: var(--0---carbon-brown, #181716);
  font-size: 10px;
  font-weight: 450;

  width: 120px;

  position: absolute;
  top: 67px;
  left: 12px;
}

.quadrantLabelTwo {
  color: var(--5---Cream-white, #fffff2);
  text-align: center;
  text-shadow: 0px 0px 8px #000;
  -webkit-text-stroke-width: 1;
  -webkit-text-stroke-color: var(--0---carbon-brown, #181716);
  font-size: 10px;
  font-weight: 450;

  width: 120px;

  position: absolute;
  top: 67px;
  right: 12px;
}

.quadrantLabelThree {
  color: var(--5---Cream-white, #fffff2);
  text-align: center;
  text-shadow: 0px 0px 8px #000;
  -webkit-text-stroke-width: 1;
  -webkit-text-stroke-color: var(--0---carbon-brown, #181716);
  font-size: 10px;
  font-weight: 450;

  width: 120px;

  position: absolute;
  bottom: 73px;
  left: 12px;
}

.quadrantLabelFour {
  color: var(--5---Cream-white, #fffff2);
  text-align: center;
  text-shadow: 0px 0px 8px #000;
  -webkit-text-stroke-width: 1;
  -webkit-text-stroke-color: var(--0---carbon-brown, #181716);
  font-size: 10px;
  font-weight: 450;

  width: 120px;

  position: absolute;
  bottom: 73px;
  right: 12px;
}

.imageDiv {
  position: absolute;

  width: fit-content;
  height: fit-content;

  z-index: 9999;
}
