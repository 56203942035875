.main {
  position: relative;
  width: fit-content;
}
.mainCircle {
  width: 69px;
  height: 69px;
  border-radius: 50%;
  border: 5px solid rgba(255, 255, 222, 0.1);
}

.mainCircleSmall {
  width: 52px;
  height: 52px;
  border-radius: 50%;
  border: 5px solid rgba(255, 255, 222, 0.1);
}

.mainCircleLarge {
  width: 122.932px;
  height: 121.27px;
  border-radius: 50%;
  border: 5px solid rgba(255, 255, 222, 0.1);
}

.inner {
  position: absolute;
  top: -4px;
  left: -4px;
  transform: rotate(270deg);
}

.innerSmalll {
  position: absolute;
  top: -3px;
  left: -3px;
  transform: rotate(270deg);
}

.innerLarge {
  position: absolute;
  top: -3px;
  left: -3px;
  transform: rotate(270deg);
}

.score {
  color: #fffff2;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 19px;
  left: 0%;
  font-family: Circular Air;
  width: 100%;
}

.scoreSmall {
  color: #fffff2;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 16px;
  left: 0%;
  font-family: Circular Air;
  width: 100%;
}

.scoreLowSmall {
  color: #fffff2;
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 14px;
  left: 1px;
  font-family: Circular Air;
  width: 100%;
}

.scoreLow {
  color: #fffff2;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 19px;
  left: 29px;
  font-family: Circular Air;
}

.scoreunknown {
  color: #fffff2;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 11px;
  left: 21px;
  font-family: Circular Air;
}

.scoreunknownLarge {
  color: #fffff2;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 19px;
  font-family: Circular Air;
}

.scoreunknownDouble {
  color: #fffff2;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 11px;
  left: 14px;
  font-family: Circular Air;
}

.scoreunknownDoubleLarge {
  color: #fffff2;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  position: absolute;
  top: 20px;
  left: 24px;
  font-family: Circular Air;
}

.scoreLarge {
  color: #fffff2;
  text-align: center;
  font-family: Circular Air;
  font-size: 39.87px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;

  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 36px;
}

.iconDiv {
  width: 22px;
  height: 22px;

  position: absolute;
  top: 33px;
  left: 31px;

  z-index: 20;
}

.scoreWithIcon {
  color: #fffff2;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  font-family: Circular Air;

  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 17px;
}
