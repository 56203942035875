.main {
  width: 100%;
}

.privateMain {
  width: 100%;
}

.privateWrapper {
  border: 1px dashed rgba(255, 255, 242, 0.2);
  width: 100%;
  display: flex;
  align-items: center;
  padding: 15px 10px;
}

.wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px dashed rgba(255, 255, 242, 0.2);
  height: 70px;
  padding: 0 13px;
}

.iconDiv {
  width: 40px;
  height: 40px;
}

.connectionIconDiv {
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #fffff2;
  padding: 8px;
}

.text {
  color: var(--5---Cream-white, #fffff2);
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  margin-left: 13px;
}

.connectionText {
  color: var(--5---Cream-white, #fffff2);
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  margin-left: 13px;
}

.button {
  min-width: 79px;
  width: 79px;
  height: 40px;
  border-radius: 9px;
  background: var(--5---Cream-white, #fffff2);
  border: 0;
  color: var(--0---carbon-brown, #181716);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: auto;
}

.buttonPending {
  min-width: 79px;
  width: 79px;
  height: 40px;
  border-radius: 9px;
  background: var(--3---pastel-brown, #928e8c);
  border: 0;
  color: var(--2---Chalk-Brown, #55504f);
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: auto;
}

.privateText {
  color: var(--5---Cream-white, #fffff2);
  font-size: 14px;
  font-weight: 450;
  margin-left: 13px;
}

@media only screen and (max-width: 768px) {
  .main {
    padding: 0 10px;
  }

  .privateMain {
    padding: 0 10px;
  }

  .privateWrapper {
    border: 0;
  }
}
