.main {
  color: #fffff2;
  width: 100%;
}

.homeHeaderDiv {
  display: flex;
  flex-direction: column;
  gap: 11px;

  position: sticky;
  top: 0;

  background-color: rgb(24, 23, 22);

  z-index: 2;
}

.tabParent {
  width: 100%;

  display: flex;
  justify-content: space-around;
  align-items: center;

  position: relative;
}

.tabDiv {
  display: flex;
  justify-content: center;
  align-items: center;

  opacity: 0.3;

  height: 30px;

  flex: 1;

  border-bottom: 2px solid #fffff2;

  transition: all 0.5s;
}

.blueDot {
  width: 5px;
  height: 5px;

  border-radius: 50%;
  background-color: #2cb0ff;

  position: absolute;
  top: 5px;
  right: 15%;
}

.tabText {
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: 1.38px;
}

.wrapper {
  display: flex;
  flex-direction: column;

  padding: 0 10px;
  margin-bottom: 100px;
}

.topIconDiv {
  display: flex;
  align-items: center;
  gap: 5px;

  margin-top: 20px;
}

.rewardsDiv {
  margin-top: 10px;
}

.assessmentDiv {
  margin-top: 20px;
}

.dailyStoryDiv {
  margin-top: 35px;

  display: flex;
  flex-direction: column;

  gap: 10px;
}

.homeDailyTitle {
  color: var(--5---Cream-white, #fffff2);
  font-size: 26px;
  font-weight: 450;
  letter-spacing: -0.78px;

  margin-top: 35px;
}

.homeDailyDesc {
  color: var(--3---pastel-brown, #928e8c);
  font-size: 14px;
  font-weight: 450;

  margin-top: 10px;
}

.homeDailyDescLink {
  color: var(--vibrant-blue, #2cb0ff);
  font-size: 14px;
  font-weight: 450;
}
